import Mercy from "../assets/photos/zone_g/compressed/g1_main1a.jpg";

import NineDragon from "../assets/photos/zone_g/compressed/g2_main1a.jpg";

import Sakyamunia from "../assets/photos/zone_g/compressed/g3_main1a.jpg";
import Couple from "../assets/photos/zone_g/compressed/g3_main2a.jpg";

import XueEast from "../assets/photos/zone_g/compressed/g4_main1a.jpg";
import XueWest from "../assets/photos/zone_g/compressed/g4_main2a.jpg";
import JiangZiya1 from "../assets/photos/zone_g/compressed/g4_main3a.jpg";
import JiangZiya2 from "../assets/photos/zone_g/compressed/g4_main3b.jpg";
import JiangZiya3 from "../assets/photos/zone_g/compressed/g4_main3c.jpg";
import XueEastAdd1 from "../assets/photos/zone_g/compressed/g4_extra1a.jpg";
import XueEastAdd2 from "../assets/photos/zone_g/compressed/g4_extra1b.jpg";
import XueEastAdd3 from "../assets/photos/zone_g/compressed/g4_extra1c.jpg";
import XueEastAdd4 from "../assets/photos/zone_g/compressed/g4_extra1d.jpg";
import XueWestAdd1 from "../assets/photos/zone_g/compressed/g4_extra2a.jpg";
import XueWestAdd2 from "../assets/photos/zone_g/compressed/g4_extra2b.jpg";
import XueWestAdd3 from "../assets/photos/zone_g/compressed/g4_extra2c.jpg";
import XueWestAdd4 from "../assets/photos/zone_g/compressed/g4_extra2d.jpg";
import XueWestAdd5 from "../assets/photos/zone_g/compressed/g4_extra2e.jpg";
import XueWestAdd6 from "../assets/photos/zone_g/compressed/g4_extra2f.jpg";
import XueWestAdd7 from "../assets/photos/zone_g/compressed/g4_extra2g.jpg";
import JiangZiyaAdd1 from "../assets/photos/zone_g/compressed/g4_extra3a.jpg";
import JiangZiyaAdd2 from "../assets/photos/zone_g/compressed/g4_extra3b.jpg";
import JiangZiyaAdd3 from "../assets/photos/zone_g/compressed/g4_extra3c.jpg";
import JiangZiyaAdd4 from "../assets/photos/zone_g/compressed/g4_extra3d.jpg";
import JiangZiyaAdd5 from "../assets/photos/zone_g/compressed/g4_extra3e.jpg";
import JiangZiyaAdd6 from "../assets/photos/zone_g/compressed/g4_extra3f.jpg";
import JiangZiyaAdd7 from "../assets/photos/zone_g/compressed/g4_extra3g.jpg";
import JiangZiyaAdd8 from "../assets/photos/zone_g/compressed/g4_extra3h.jpg";
import JiangZiyaAdd9 from "../assets/photos/zone_g/compressed/g4_extra3i.jpg";
import JiangZiyaAdd10 from "../assets/photos/zone_g/compressed/g4_extra3j.jpg";
import JiangZiyaAdd11 from "../assets/photos/zone_g/compressed/g4_extra3k.jpg";
import JiangZiyaAdd12 from "../assets/photos/zone_g/compressed/g4_extra3l.jpg";
import JiangZiyaAdd13 from "../assets/photos/zone_g/compressed/g4_extra3m.jpg";
import JiangZiyaAdd14 from "../assets/photos/zone_g/compressed/g4_extra3n.jpg";
import JiangZiyaAdd15 from "../assets/photos/zone_g/compressed/g4_extra3o.jpg";
import JiangZiyaAdd16 from "../assets/photos/zone_g/compressed/g4_extra3p.jpg";

import AniamlWedding from "../assets/photos/zone_g/compressed/g5_main1a.jpg";

import VirtueVice1 from "../assets/photos/zone_g/compressed/g6_main1a.jpg";
import VirtueVice2 from "../assets/photos/zone_g/compressed/g6_main2a.jpg";
import VirtueVice3Pair1 from "../assets/photos/zone_g/compressed/g6_main3a.jpg";
import VirtueVice3Pair2 from "../assets/photos/zone_g/compressed/g6_main3b.jpg";

import TangBuddha from "../assets/photos/zone_g/compressed/g7_main1a.jpg";
import TangBuddhaAdd1 from "../assets/photos/zone_g/compressed/g7_extra1a.jpg";
import TangBuddhaAdd2 from "../assets/photos/zone_g/compressed/g7_extra1b.jpg";

import Piety from "../assets/photos/zone_g/compressed/g8_main1a.jpg";
import BaoGong from "../assets/photos/zone_g/compressed/g8_main2a.jpg";
import Arhats from "../assets/photos/zone_g/compressed/g8_main3a.jpg";
import Turtle from "../assets/photos/zone_g/compressed/g8_main4a.jpg";
import Cave from "../assets/photos/zone_g/compressed/g8_main5a.jpg";
import WinterCoat from "../assets/photos/zone_g/compressed/g8_main6a.jpg";
import HanYu from "../assets/photos/zone_g/compressed/g8_main7a.jpg";
import Loyalty from "../assets/photos/zone_g/compressed/g8_main8a.jpg";
import PietyAdd1 from "../assets/photos/zone_g/compressed/g8_extra1a.jpg";
import PietyAdd2 from "../assets/photos/zone_g/compressed/g8_extra1b.jpg";
import TurtleAdd1 from "../assets/photos/zone_g/compressed/g8_extra4a.jpg";
import LoyaltyAdd1 from "../assets/photos/zone_g/compressed/g8_extra8a.jpg";
import LoyaltyAdd2 from "../assets/photos/zone_g/compressed/g8_extra8b.jpg";

import Friendship from "../assets/photos/zone_g/compressed/g9_main1a.jpg";

import Villa from "../assets/photos/zone_g/compressed/g10_main1a.jpg";
import VillaAdd from "../assets/photos/zone_g/compressed/g10_extra1a.jpg";

import Gorillas from "../assets/photos/zone_g/compressed/g11_main1a.jpg";

import Happiness from "../assets/photos/zone_g/compressed/g12_main1a.jpg";

import Emu from "../assets/photos/zone_g/compressed/g13_main1a.jpg";
import Kiwi from "../assets/photos/zone_g/compressed/g13_main2a.jpg";
import Frog from "../assets/photos/zone_g/compressed/g13_main3a.jpg";

import { EntryMap } from "../content/entryMap";

const ZoneGMapping: EntryMap = {
  // Zone G Entries
  g1: [
    {
      images: [Mercy],
      title: "Goddess of Mercy",
      sections: [
        {
          text: `Built after Dragon World closed, this pond was an attempt to improve the general fengshui with more water. A massive Guan Yin statue to watch over the park certainly would have helped.`,
        },
        {
          text: `Worshipped by Taoists and Buddhists alike, Guan Yin 观音 the Goddess of Mercy embodies compassion and mercy, and her name refers to her ability to ‘perceive the cries of the world’. She is the female incarnation of the male Bodhisattva Avalokitesvara, and is often portrayed holding a green willow branch and a vase of pure water – the divine nectar of life.`,
        },
      ],
      zone: "g",
    },
  ],
  g2: [
    {
      images: [NineDragon],
      title: "Nine Dragon Wall",
      sections: [
        {
          text: `Built in the early 1950s, this magnificent wall is styled after the traditional Chinese Nine Dragon Screen popular in imperial gardens. The number 9 is the number of the emperor and only the highest officials are allowed to wear 9 dragons on their robes. Two of the most famous Nine Dragon Walls are the one erected in 1402 in Beihai Park and the one erected in 1771 in the Forbidden City. On those walls, as on this one, the dragons frolic between the clouds and the sea, chasing after pearls which represent enlightenment.`,
        },
        {
          text: `The couplet flanking the wall gives us a sense of the action. It describes how “when the dragons breathe towards the southern sky, the clouds will twist; and when the dragons breathe in the sea of stars, they will go dancing over a thousand miles.”`,
        },
      ],
      observation: [
        {
          text: `Look closely at the scales and fins of the dragons, but be careful when you touch them – each one is made of a painted shard of glass! This is a variation of the jian nian 剪黏 sculptural style popular in Southern Chinese temples, in which shards of broken porcelain or glass aare used to create complicated textures. This wall demonstrates the high level of skill of the artisans the Aws brought over, and the intricacy that went into creating the Tiger Balm Gardens.`,
        },
      ],
      zone: "g",
    },
  ],
  g3: [
    {
      images: [Sakyamunia],
      title: "Sakyamunia Buddha Preaching on a Lotus Podium",
      sections: [
        {
          text: `This tableau was constructed in the early 1950s and depicts Sakyamuni Buddha 如来神佛 sharing the dharma, flanked by two of the four Diamond Kings (四大金刚) – Dhriarastra (AKA Chi Guo Tian Wang 持国天王  AKA Mo Li Qing 魔礼青, the Guardian of the East) and Virupaksha (AKA  Guang Mu Tian Wang  广目天王 AKA  Mo Li Hai 魔礼海,  the Guardian of the West.)`,
        },
        {
          text: `Revered in both Taoist and Buddhist tradition, the Diamond Kings protect the world from evil spirits and are often seen as huge statues guarding temples.`,
        },
        {
          text: `In the background, we see two sons of the third Diamond King -  Nezha 哪吒 standing on a lotus and brother Muzha 木吒 riding a heavenly crane. The two brothers also appear in the battle nearby (G4).`,
        },
        {
          text: `Note the unexpected bat in the foreground! The bat is a Chinese symbol of happiness because its name ‘fu’ 蝠 both looks and sounds like ‘fu’ 幅 (happiness).  You’ll find bat symbols scattered around the park – on a walking stick at A5, as rafters at C12 and more.`,
        },
      ],
      zone: "g",
    },
    {
      images: [Couple],
      title: "Courting Couple",
      sections: [
        {
          text: `No official information is available about this tableau’s story, but it is been interpreted variously as either :`,
        },
        {
          text: `⦁	a matchmaker earning a young courting couple to formalize their affection before going too far; or`,
        },
        {
          text: `⦁	the young man’s mother alarmed that her son is being led astray by this modern young lady with ‘western’ tastes; or`,
        },
        {
          text: `⦁	traditional Chinese womanhood (in old-fashioned garb and with bound feet) trying to entice Chinese men back from the charms of trendy modern girls in Western fashion.`,
        },
      ],
      zone: "g",
    },
  ],
  g4: [
    {
      commonInfoText: [
        "This epic tableau was built in this spot in the early 1950s, disguising the kitchen building that once served the Villa. While appearing to be one huge battle, it actually depicts three different legendary battles! All three are loosely based on historical events but have gained mythical status through the addition of supernatural elements.",
        "Starting from the battle furthest to the right:",
      ],
      images: [XueEast],
      title: "General Xue Conquers the East 薛仁贵征东",
      sections: [
        {
          bold: `The painted title reads “Conquering the East : Ren Gui’s Campaign for Mo Tian Ling”. This particular battle has been popularized both in novels and operas.`,
        },
        {
          bold: `The Tang army had won Mo Tian Ling and General Xue had taken charge of it. Taking advantage of Xue’s temporary absence from the city, the general Kai Su Wen led a barbarian army in laying siege to it. Emperor Tang managed to send a brave warrior to break through the enemy ranks and fetch General Xue.  Xue sent troops to attack all four gates of the imperial city and was eventually victorious. This tableau shows some of the key combatants, along with the divine assistance they received.`,
          image: XueEastAdd1,
        },
        {
          text: `Facing off in the center foreground are the generals Kai Su Wen (left) and Xue Ren Gui (right).`,
        },
        {
          text: `Kai was a Korean general who joined the barbarians to invade the Tang, and was egoistic and simple-minded despite his ferocity.`,
        },
        {
          text: `Xue Ren Gui 薛仁贵 (614-683 CE) was one of the greatest generals of the Tang Dynasty – a military hero so effective in battle that he was rumored to be able to win a war with only three arrows – shot straight at the enemy leaders. He continued leading armies against rebels and barbarians well into his 70s, serving two Tang emperors.`,
        },
        {
          text: `Behind Generals Xue and Kai, a little up-slope, we see one of General Xue’s trusty sidekicks Zhou Qing 周青 fighting a rather fanciful barbarian.`,
          image: XueEastAdd2,
        },
        {
          text: `At the very top of the tableau stands Xuan Nu Niang Niang 玄女娘娘, a divine being gracing the battle with her presence. When General Xue previously visited her palace, she gave him five magical gifts – a book of knowledge, a bow, five arrows, a military coat that protected him from fatal wounds, and most significantly a White Tiger Whip. (Some legends associate Xue with the stellar god of the White Tiger and also suggest that he transformed into a white tiger at times.)`,
          image: XueEastAdd3,
        },
        {
          text: `In the battle, General Kai was backed by ten generals. Here we see three of them.  Xiong Hu 雄虎 is up the slope on the left, raising his curved sword high. Yu Hu 玉虎 (armed with two maces and a  moustache) and Fei Hu飞虎 (wielding a spear) stand right behind Kai.`,
          image: XueEastAdd4,
        },
      ],
      zone: "g",
    },
    {
      images: [XueWest],
      title: "General Xue Conquers the West 薛仁贵征西",
      sections: [
        {
          bold: `The battle taking place around the corner of the giant tableau is another General Xue victory. This painted title is “Tang Dynasty Ancient History :  Xue Rengui Conquers the West – Great Battle with Su Bao Tong”. It depicts the key battle in Xue’s conquest of the Western territory of China – the battle against Su Bao Tong 苏宝同.`,
        },
        {
          bold: `Su had declared war against the Tang Emperor Tai Zong to avenge his grandfather’s death. Xue and the Tang forces moved against Su, pursuing him to the city of Suo Yang Cheng 锁阳城, Su’s home base. When they entered, they found the city empty and themselves trapped and surrounded.`,
        },
        {
          text: `Here we see the fierce battle that ensued.`,
          image: XueWestAdd1,
        },
        {
          text: `Riding the black horse is Su Bao Tong himself, doing battle with Xue Ren Gui on the white horse. No moral ambiguity there.`,
        },
        {
          text: `The red-clad soldier following the black horse carries a banner with the Su family name on it.`,
          image: XueWestAdd2,
        },
        {
          text: `Right behind them on the stone ledge is a female warrior armed with two swords  – this is Dou Xian Tong, sister of the Dou Yi Hu, the bandit leader. She is here because her husband Xue Ding Shan happens to be Xue Ren Gui’s son! (Their courtship is exemplary.  A sixteen-year old Xian Tong saw Ding Shan in battle, fell in love and used her magic rope to capture him. She only released him when he promised to marry her.)`,
          image: XueWestAdd3,
        },
        {
          text: `Her husband Xue Ding Shan  stands to the right of her, under the tableau title.  When Xue Ren Gui is eventually badly wounded by Su Bao Tong, it is his son who will save the day.`,
          image: XueWestAdd4,
        },
        {
          text: `Ding Shan is looking uphill at a woman on a white horse. This is Su Jin Lian, sister of Su Bao Tong, armed with her magical gourd that could release five magpies to kill her enemies. In this battle, she will kill eight of Xue Ren Gui’s generals but will eventually be slain by Chen Jin Ding.`,
          image: XueWestAdd5,
        },
        {
          text: `Right behind Su Jin Lian, running into the fray, is the seventeen-year old Chen Jin Ding 陈金定. After she slays Su Jin Lian, a grateful Xue Ding Shan will take her as his second wife (with permission from first wife Dou Xian Tong and her magic rope, of course).`,
        },
        {
          text: `In front of her, losing her balance, is Mei Xue Ying 梅月英 – a swordswoman and magician fighting on Su Bao Tong’s side.`,
        },
        {
          text: `The man ready to catch her fall is Mu Jiao Da Xian 木脚大仙, who began life as a tortoise but after long Taoist training, took on human form. With his red pearl, he would wound Xue Ren Gui later in the battle.`,
          image: XueWestAdd6,
        },
        {
          text: `Near the top, above the white-horse-riding Su Jin Lian, is a bearded man with a long sword. This is Tie Ban Dao Ren 铁坂道人, (Iron Board Taoist), whose twelve iron boards could kill on command (but which he did not bring along to today’s battle, it seems.)`,
        },
        {
          text: `Flying towards him is Qin Huai Yu 秦怀玉, the son of Emperor Tang Tai Zong and sadly destined to be slain by Su Bao Tong.`,
          image: XueWestAdd7,
        },
        {
          text: `Backing Qin up from the rear, is Dou Yi Hu the brother of Dou Xian Tong and leader of the bandits. He fights with his trademark golden stick.`,
        },
        {
          text: `Coming after Dou Yi Hu with two swords is Fei Bo Chan Shi 飞钹禅师, a monk who served as Su Bao Tong’s military strategist, despite being only 1.5 meters tall.`,
        },
      ],
      zone: "g",
    },
    {
      images: [JiangZiya1, JiangZiya2, JiangZiya3],
      title: "Jiang Ziya at the Battle of the Yellow River Formation",
      sections: [
        {
          bold: `The longest stretch of this tableau is dedicated to the legendary Battle of the Yellow River Formation, the highlight of the saga    封神榜Feng Sheng Bang  -  “The Canonization of the Gods”.  The saga relates the Battle of Mu Ye, in which the evil tyrant  King Zhou 纣王 was overthrown by the wise and virtuous King Wu 武王 and his strategist Jiang Ziya, thus ending the Shang Dynasty (商朝, c. 1556 to 1046 BC) and ushering in the Zhou Dynasty (周朝, c. 1046–256 BC).  The novel contains 100 chapters and was first published in the 1550s. It combines historical accounts with mythology, with both armies calling upon supernatural beings to fight alongside them.  After King Zhou’s defeat, 365 deceased heroes from both sides were honored by being canonized and given heavenly ranks (hence the title).`,
        },
        {
          bold: `Aw Boon Haw may have chosen this particular battle to depict because it features a stunning array of mythical figures.  Titled “Chapter 54 : The Three Sisters arrange the Convoluted Yellow River Formation”, it shows the three sisters of Zhou Gong Ming (collectively known as San Gu 三姑) using magic against martial genius Jiang Ziya. Jiang had slain their brother (King Zhou’s chief warrior) with seven arrows, and to avenge his death, his three sisters created a trap of spells so powerful that even immortals were caught in it and became mere mortals.`,
        },
        {
          text: `There are so many figures in this tableau, and no records exist of who some of them are meant to be. However, some of the key characters are unmistakable. Let’s meet some of them!`,
          image: JiangZiya1,
        },
        {
          image: JiangZiyaAdd1,
        },
        {
          text: `The ultimate hero of this battle, Jiang Ziya, is rather nondescript in this tableau. (A much bigger statue of Jiang sits fishing at F8.) Here, he stands with sword raised in his right hand, his long white beard reminding us of his age.`,
          image: JiangZiyaAdd2,
        },
        {
          text: `Standing at the very top of this section with a crane is Nan Ji Xian Weng  南殛仙翁, a powerful Taoist master who helped overcome many of the plots in the Yellow River Formation.  He is also identified as Shou Xing 寿星 – the God of Longevity from the Fu Lu Shou trio.`,
          image: JiangZiyaAdd3,
        },
        {
          text: `On the ledge below him is Mu Zha 木吒, second son of Li Jing and elder brother of Ne Zha, with long pheasant feathers emerging from his headdress.`,
          image: JiangZiyaAdd4,
        },
        {
          text: `The trio in the lower corner are (left to right) Yun Zhong Zi 云中子, Yu Ding Zhen Ren 玉鼎真人 and Huang Long Zhen Ren 黄龙真人 – all Taoist masters with extraordinary abilities.`,
          image: JiangZiya2,
        },
        {
          image: JiangZiyaAdd5,
        },
        {
          text: `Riding a ferocious black beast is Wen Tai Shi , King Zhou’s strategist and the originator of the Yellow River Formation.  He has sent his banner flying into the air to declare the battle begun. However, the banner has vanished and only a flying stick remains.`,
          image: JiangZiyaAdd6,
        },
        {
          text: `Lying unconscious nearby is King Wu (in imperial yellow) with one of his attendants. He was  trapped by the magical Red Sand trap for 100 days.`,
          image: JiangZiyaAdd7,
        },
        {
          text: `Standing on a rock at mid-level is Li Jing, a general of the Shang Dynasty. He is also the father of Jin Zha, Mu Zha and the notorious Ne Zha. Eventually, Li Jing was canonized as one of the four Heavenly Kings (or Diamond Kings). Two of the other Diamond Kings are just round the corner on the left, at G3. Li Jing carries his trademark palm-sized pagoda that can enlarge to trap enemies and then shrink to imprison them. It was given to him as the only way to control his rebellious son Ne Zha.`,
          image: JiangZiyaAdd8,
        },
        {
          text: `High above are the two sages whose powers helped to break the magical traps of the Yellow River Formation and free Jiang and his warriors. On the right, seated in a winged chair, is Yuan Shi Tian Zun 元始天尊, the Taoist master of Jiang Ziya. Able to see the future, he advised Jiang to help King Wu to build the dynasty to come.  Eventually, he will use his magic cup to suck the Three Sisters into its fire and disintegrate them. On the left, riding a black rhino, is the founder of Taoism – Lao Zi 老子 himself!`,
          image: JiangZiya3,
        },
        {
          image: JiangZiyaAdd9,
        },
        {
          text: `Riding a tiger in the top left is Shen Gong Pao, a Taoist martial expert on King Zhou’s side.`,
        },
        {
          text: `The lady raising the spear aloft is Cai Yun Xian Zi 彩云仙子, a Taoist martial artist friend of the Three Sisters whose secret weapon was a pair of magic pearls that could blind her opponents.`,
          image: JiangZiyaAdd10,
        },
        {
          text: `Riding a kirin and with a third eye on his forehead is Hei Hu 黑虎, known for his red gourd that released eagles.`,
          image: JiangZiyaAdd11,
        },
        {
          text: `In the foreground, Jin Zha (eldest son of Li Jing, on the right) is facing off with Yun Xiao Niang Niang 云霄娘娘, the oldest of the Three Sisters.`,
          image: JiangZiyaAdd12,
        },
        {
          text: `Further up to the left, behind the bridge, stand the other two sisters Bi Xiao and Qiong Xiao – very matching in their martial gear.  Their high military rank is shown by the pennants they wear on their shoulders – a Chinese opera tradition that indicates the battalions led by the wearer. (The sisters are meant to be controlling a magical vase and a pair of scissors which they send flying into the air. You can still see the wires coming out of their palms.)`,
          image: JiangZiyaAdd13,
        },
        {
          text: `Up the steps behind Jin Zha 金吒  is his youngest brother Ne Zha, identifiable by the wheels of fire that he stands on. He has sent a golden ring high up into the air to counter the flying scissors of the Sisters.`,
          image: JiangZiyaAdd14,
        },
        {
          text: `Behind him is Yang Jian 杨戬, one of King Wu’s warriors. He has sent his trademark magical dog up in the air to fight the Sisters’ magic weapons.  He is also identified as Er Lang Shen 二郎神, the  greatest warrior god of heaven, distinguishable by his truth-seeking third eye in the middle of his forehead.`,
          image: JiangZiyaAdd15,
        },
        {
          text: `On the bridge, a female warrior is being attacked by a winged being. The latter is Lei Zhen Zi 雷震子, son of King Wen, King Wu’s father. One day, he ate a strange fruit that caused wings to sprout from his back and his features to grow avian, but he continued to fight valiantly for his faction.`,
          image: JiangZiyaAdd16,
        },
        {
          text: `High above the whole canopy, in a higher level of clouds, Dao De Zhen Jun (bearded) is fighting a Shang warrior. In his left hand, he holds what looks suspiciously like a jar of Tiger Balm.`,
        },
      ],
      zone: "g",
    },
  ],
  g5: [
    {
      images: [AniamlWedding],
      title: "Animal wedding",
      sections: [
        {
          text: `Built over the circular doorway leading to the former kitchen block of the Villa, this cheeky tableau from the 1950s shows a menagerie of animal-beings in a wedding procession. Musicians, gift-bearers and curious bystanders surround a palanquin carrying the white cat bride to her waiting gorilla groom.`,
        },
        {
          text: `The Hong Kong Gardens also once featured an animal wedding – but there, the bride was a rabbit and the groom was a pig.`,
        },
      ],
      observation: [
        {
          text: `Look out for the greedy cat who is about to steal a bite from the basket of wedding munchies!`,
        },
      ],
      zone: "g",
    },
  ],
  g6: [
    {
      commonInfoText: [
        "This wall of tableaux built in the early 1950s is one of the most stunning features of Haw Par Villa.",
        "The Aw brothers grew up in the tail end of the China’s last dynasty – the Qing. They saw how Western practices had infiltrated China and led to a decline in religious piety and Confucian values.  Boon Haw himself had been wild and irresponsible in his youth, until taking over as head of the family (after his father’s death) sobered him up. Aw Boon Haw firmly believed that art is a perfect tool for moral teaching, and hoped this wall would remind visitors of the paths of evil and the ways of the righteous.  It gave parents a perfect excuse to share moral lessons with their kids and thus pass on good Chinese values from generation to generation.",
      ],

      images: [VirtueVice1],
      title: " Virtues & Vices #1",
      sections: [
        {
          text: `These four panels warn us about how a dissolute life of pleasure-seeking can lead one into poverty, desperation and dependence on others.`,
        },
        {
          text: `Upper left panel : The inscription tells us that this the Shanghai World Great Dance Hall – clearly a hotspot for dancing girls and flirtatious behavior.  And in the room to the right, mahjong too. These activities lead to waste and irresponsibility, and eventually degeneration and ruin.`,
        },
        {
          text: `Upper right panel : The inscription identifies this as the Hong Kong Celebrity Song & Dance Garden.  Here we see more affluent folks wasting their time and money on wine and women – indulgences  that Aw Boon Haw considered unseemly.`,
        },
        {
          text: `Lower left panel :  Two gentlemen are giving alms to the poor, allowing them to buy food. While these men demonstrate the virtue of charity, Aw Boon Haw is warning us against reckless leisure that will ruin people and leave them dependent on the charity of others.`,
        },
        {
          text: `Lower right panel :  Two policemen have caught a pair of thieves who have stolen clothes and chickens . Clearly desperate for the bare necessities, these are men who have fallen on hard times because of unwise choices and irresponsible living. Now they have to resort to crime just to survive.`,
        },
      ],
      zone: "g",
    },
    {
      images: [VirtueVice2],
      title: "Virtues & Vices #2",
      sections: [
        {
          text: `The title overhead refers to families that accumulate good deeds. These four panels compare the virtue of communal enterprises that benefit the future with sordid activities like smuggling, gambling, opium-smoking and theft.`,
        },
        {
          text: `Upper left panel :  The whole community pitches in to help build a bridge that will benefit future generations, as well as the present. In the foreground, we see three generations represented – the boy fishing with the old man and the adult woman passing by.`,
        },
        {
          text: `Upper right panel : A businessman has invested money to build a boardwalk over the rough soil and is directing the workers in constructing it. Such an investment in public works will benefit future generations. Such thinking has always been part of the Aw family tradition, and in addition to this enlightening statue park, the family has built hundreds of schools, universities, hospitals, orphanages and retirement homes throughout Asia to benefit the public.`,
        },
        {
          text: `Lower left panel : These men are wasting their time on gambling. Clearly, some of the games have erupted into violence and a fistfight is underway.`,
        },
        {
          text: `Lower right panel :  The man in the extreme right corner was originally holding a suitcase labelled 鸦片 - he is a smuggler who has been caught by the police. Sadly, a real thief seems to have stolen his suitcase.`,
        },
        {
          text: `Behind the bridge, the woman riding pillion on the motorbike has had her handbag snatched by the thief escaping down the gully.`,
        },
        {
          text: `Above him, a young couple is heading towards a very modern-looking opium den, instead of spending time meaningfully at the mini Haw Par Villa next door!`,
        },
      ],
      zone: "g",
    },
    {
      images: [VirtueVice3Pair1, VirtueVice3Pair2],
      title: "Virtues & Vices #3",
      sections: [
        {
          text: `The verse painted overhead is titled “giving back to society”. It tells how wealthy men of today, as in the past, pass their wealth to their descendants. They can be good or bad; they can bring suffering or bliss – only time will tell.  This four-part tableau compares hard work and charity with dishonorable activities like borrowing money and loansharking.`,
        },
        {
          text: `Upper left panel : The bowl-balancing artiste, the musician and the dancing monkey are travelling performers, hard at work entertaining folks. This shows us that no profession is disgraceful as long as one earns a decent living without harming others.`,
        },
        {
          text: `Upper right panel : This tableau shows charity in the community. The wealthy old man is watching his assistant distribute his gift of rice to the poor. His grandson sits beside him, learning a valuable lesson.`,
        },
        {
          text: `Lower left panel : A farmer has borrowed money and is unable to pay. The tough debt-collectors have come to claim the family pig as payment and the farmer is helpless to prevent them.`,
        },
        {
          text: `Lower right panel : Some time later, still unable to pay up, the farmer comes home to find the debt-collectors have returned to take his wife as well, despite the pleas of the mother-in-law and child.`,
        },
      ],
      zone: "g",
    },
  ],
  g7: [
    {
      images: [TangBuddha],
      title: "Tang Dynasty Buddha 大殿佛",
      sections: [
        {
          text: `The largest figure on this wall, and also the deity to whom the wall was dedicated, is Ji Gong 济公 - known also as the Tang Dynasty Buddha, or Living Buddha. The plaque below him labels him “China Teochew’s Buddha of the Spirit Mountain”.`,
        },
        {
          text: `As a monk, he was notorious for blatantly eating meat and drinking wine. He was fond of magic tricks but always kind to the needy. Often depicted in tattered robes and waving a broken leaf-fan, the eccentric  Ji Gong has become a much-loved cultural icon, appearing in films and TV shows!`,
        },
        {
          text: `Born Li Xiu Yuan 李修缘 in 1130 CE, he was orphaned at age eighteen and entered a monastery. He was given the monastic name Dao Ji 道济.  His rule-breaking and irreverence got him kicked out of the monastery, and he spent the rest of his days wandering around China, saving lives and helping the weak with his magical abilities. He died in 1207 and soon after, both Taoists and Buddhists began to worship him as a Buddha.`,
          image: TangBuddhaAdd1,
        },
        {
          text: `In this tableau, he is surrounded by a host of other deities. In the upper right background, we see Sakyamuni Buddha, flanked by two female deities. The one of the left is the bodhisattva Samantabhadra (普贤 Pu Xian), shown here in feminine aspect rising her trademark white(ish) elephant. To complete a trio common in Mahayana Buddhism, the lady on the extreme right is most likely the feminine aspect of the bodhisattva Manjusri (文殊 Wen Shu), recognizable by Manjusri’s trademark lion mount. Samantabhadra is associated with action while Manjusri is associated with transcendent wisdom. A miniature avatar of Ji Gong can be seen bowing to this mighty trio, while connected to the main statue by a spiritual umbilical cord!`,
        },
        {
          text: `Below this trio, we see two of the four Diamond Kings (四大金刚) – namely Dhrtarastra the Guardian of the East (AKA 魔礼青 Mo Li Qing or 持国天王 Chi Guo Tian Wang, he who upholds the realm) and Virupaksa the Guardian of the West (AKA魔礼海 Mo Li Hai or 广目天王 Guang Mu Tian Wang, he who sees all).  This same pair can be seen guarding Sakyamuni Buddha at G3.`,
        },
        {
          text: `Lower down and nearest to Ji Gong stands a monk, with the Diamond Sutra at his feet.`,
          image: TangBuddhaAdd2,
        },
        {
          text: `In the upper left background, we see the Laughing Buddha (also at A10 and D5)  with 2 bodhisattvas standing on lotuses. In front of them, we see Ne Zha with a spear and his brother Mu Zha with a fly-whisk. These two are also seen guarding Sakyamuni Buddha at G3. Lower down and closest to Ji Gong stands a monk with a mu yu木鱼, the familiar Buddhist percussion instrument meditation used in chanting sutras. The Diamond Sutra also sits at his feet.`,
        },
      ],
      zone: "g",
    },
  ],
  g8: [
    {
      images: [Piety],
      title: "Religious Piety",
      sections: [
        {
          text: `Overseeing and approving the temple building next door is the Emperor Liang Wu Di 梁武帝 .  As an emperor, Liang (502-549 CE) was credited with advocating prosperity through hard work, as well as  spreading Buddhism throughout the land. Three times, he left the court to become a monk and his officials had to ‘buy’ him back with liberal donations to the sangha (monastic community). His reign was described by later writers as the golden age of Chinese Buddhism.  Eventually, he was captured by rebels after a coup and died under house arrest. The inscription above his grotto reads “Liang Wu Di : Asking for Past Lives”.`,
          image: PietyAdd1,
        },
        {
          text: `The soldier on the horse on the Seven Star Bridge 七星桥 is unaware of the perils that lie ahead (or in this case, lurk under) the path of life. Others can see the danger, but the soldier, despite his bravery and martial strength, remains oblivious. One of the bystanders is pointing up at Liang Wu Di, perhaps indicating that the enlightenment represented by Liang is the only way forward in life.`,
          image: PietyAdd2,
        },
        {
          text: `This scene celebrates the virtue of a community working together to build and maintain its places of worship.  The villagers are all chipping in to make repairs to the 三宝殿 Three Jewel Temple while others continue offering prayers. The couplet on the temple says “Seated on the red lotus without a smidge of dust; When faced with this house, the future is empty”, reminding us that only those who embrace emptiness like the Buddha can live free of worries and fears.`,
        },
        {
          observation: [
            {
              text: `For those who are curious,  the three buddhas in the temple represent the Three Jewels of Buddhism – Buddha, Dharma (the teachings) and Sangha (the monastic community).`,
            },
          ],
        },
        {
          text: `They also represent the three bodies of buddha (Trikaya), namely : Nirmāṇakāya his real-world manifested body, Dharmakāya his truth-body (which embodies enlightenment and is limitless); and Sambhogakāya his enjoyment-body  (which embodies the sharing of the Dharma with others). This trinity is often embodied by images of Sakyamuni, Vairocana and Manjusri, respectively.`,
        },
      ],
      zone: "g",
    },
    {
      images: [BaoGong],
      title: "Bao Gong meets Lady Li 包公会太后李",
      sections: [
        {
          text: `This story is called “Bao Gong meets Lady Li 包公会太后李”.  The Empress Dowager Li was virtuous, but when she gave birth to a son, it was stolen from her and replaced with a raccoon. The furious (and no doubt puzzled) Emperor had Li thrown in prison, but his second wife Liu plotted to have the prison set on fire to eliminate her rival forever.`,
        },
        {
          text: `Fortunately, loyal servants helped the Empress Dowager escape to the countryside.  There, she lived in poverty. A young man named Hai Shou took pity on her and became her adopted son so he could look after her.  He is the young man with patched clothing returning home from a day’s labor.  Despite his kindness, she was so sad about losing her son that she cried herself blind.`,
        },
        {
          text: `One day, while giving out rice to drought victims, the fiercely righteous Justice Bao meets this poor old woman. Here we see him recognizing her – he is depicted with a black face, as befits righteous persons in Chinese opera. Soon, he will arrange for her to be reunited with her son, who has now become Emperor.`,
        },
        {
          text: `This tale reminds us that virtue will prevail over the wickedness of ambition, as long as there are righteous people in the world.`,
        },
      ],
      zone: "g",
    },
    {
      images: [Arhats],
      title: "Eighteen Arhats 十八罗汉",
      sections: [
        {
          text: `High above this section are four words that remind us that “kindness and evil are chosen in an instant”.`,
        },
        {
          text: `This tableau is titled “Eighteen Arhats attain Nirvana”. The Sanskrit word ‘arhat’ means “perfected person’, referring to one who has gone far towards attaining Enlightenment but may not yet have reached Buddhahood. Arhats have been seen as protectors of the Buddhist faith, and are also believed to be awaiting the return of the future Buddha, Maitreya. In some ways, they are the Buddhist equivalent of Christian apostles.`,
        },
        {
          text: `The arhats only became known in China after the Sanskrit text Nandimitrāvadāna (Record on the Duration of the Law, spoken by the Great arhat Nadimitra) was translated  in 654 AD by none other than Xuan Zang himself (Tripitaka of Journey to the West fame). In Mandarin, they were called louhan  罗汉. It was only two centuries later that the arhats were first given faces. In 891 AD, the monk Guan Xiu貫休 painted a portrait of each of the eighteen, exactly as they appeared to him in a dream.  They were generally shown to be unkempt and eccentric, befitting persons who had left worldly concerns behind.`,
        },
        {
          text: `Each was depicted with a distinct characteristic or gesture, but in this 1950s tableau, they are somewhat off-duty so identification is tricky.  Only five of the eighteen are clearly identifiable here : Long Eyebrow (Asita), Taming Tiger (Pindola), Taming Dragon (Nantimitolo), Raised Bowl (Kanaka the Bharadvaja) and Open Heart (Gobaka). You should be able to spot the first four for yourself. The fifth is the one with Buddha’s head emerging from his chest.`,
        },
        {
          text: `In 1757, the Qianlong Emperor of the Qing Dynasty wrote a verse for each arhat and for Open Heart, he wrote :`,
        },
        {
          ctext: `Open the heart and there is Buddha,
          Each displaying his prowess.
          The two should not compete,
          For Buddha's power is boundless.
          `,
        },
        {
          text: `The other nine arhats are :, Deer Sitting, Happy, Raised Pagoda, Meditating, Oversea, Elephant Riding, Laughing Lion, Raised Hand, Thinking, Scratched Ear, Calico Bag, Plantain and Doorman`,
        },
      ],
      zone: "g",
    },
    {
      images: [Turtle],
      title: "The Grateful Turtle",
      sections: [
        {
          text: `This tableau reminds us that good deeds will be rewarded someday – quite possibly in unexpected ways. It also celebrates gratitude as a virtue.`,
        },
        {
          bold: `Wang Qing 王青 was a kindly man who took pity on a turtle he saw being carried to market.  In the upper panel, left of the Eighteen Arhats, we see Wang having bought the turtle and arranging for it to be released back into the sea.`,
        },
        {
          text: `Years later, Wang lost his wealth and was forced to emigrate. The ship Wang was travelling on sank in a storm. While the other passengers drowned or were eaten by fish, we see Wang (poor in dress but rich in relief) being  rescued by the grateful turtle. The turtle brought Wang to the palace of the Dragon King, who, impressed by Wang’s kindness, presented him with gifts. The turtle then carried Wang to his destination, where he met and married an heiress.`,
        },
        {
          observation: [
            {
              text: `See how the artisans have  forced perspective here by shrinking the figures drastically as they recede – thus achieving remarkable depth in a shallow tableau! Look out for the lifeboat with the sailor doing semaphore!`,
            },
          ],
          image: TurtleAdd1,
        },
      ],
      zone: "g",
    },
    {
      images: [Cave],
      title: "泰山冻雪",
      sections: [
        {
          text: `The upper tableau depicts  Peng Lai Cave 篷来洞 where the Eight Immortals make their home. Scattered watchwords from the Taoist tradition decorate the doorways.`,
        },
      ],
      zone: "g",
    },
    {
      images: [WinterCoat],
      title: " Making a Gift of a Winter Coat at Night 夜送寒衣",
      sections: [
        {
          text: `夜送寒衣 (“Making a Gift of a Winter Coat at Night”) depicts a well-known romance. Hong Fu Nu 红拂女, a well-known socialite of the prestigious Yang family, cast aside her status to follow the man she loved. This man was  Li Jing 李靖, a handsome scholar and military strategist.`,
        },
        {
          text: `Here we see the elegant lady arriving with her servant to give Li jing a winter coat as a token of her love.  Thereafter, they would both depart for the chilly Northern Territories where they would serve the Emperor loyally.`,
        },
      ],
      zone: "g",
    },
    {
      images: [HanYu],
      title: "Han Yu韩愈",
      sections: [
        {
          text: `The well-dressed gent in the black ministerial hat is Han Yu 韩愈, a Tang Dynasty scholar.  Orphaned at age three and raised by his sister-in-law, he studied hard and aced the Imperial examinations at age nineteen. He went on to teach and write treatises on Confucianism; while working hard to revive classical studies in the nation. He was recognized as the best of the Tang and Song philosophers.  He is depicted here as an example of the ideal classical Chinese gentleman.`,
        },
        {
          text: `Above Han Yu, up in the clouds, we see Han Yu’s grandnephew Han Xiang Zi 韩湘子, who became one of the Eight Immortals – the patron of musicians. After he became an immortal, he is said to have visited his uncle one day and made flowers bloom in the winter, then presented them to his uncle in an attempt to encourage him to become a Taoist.`,
        },
      ],
      zone: "g",
    },
    {
      images: [Loyalty],
      title: "Determination, Loyalty and Hard Work",
      sections: [
        {
          text: `This tableau tells the story of Qin Cheng, a shop-owner who was obsessed with gambling. One morning, his shop wasn’t even open because he had been out gambling all night – on the top left wall we see customers waiting outside the shuttered-up shop. On the top right wall we see Qin Cheng’s wife sending her son to go fetch his father.  On the sloping road at mid-level, we see the woeful result of this – the boy has been hit by a car. The frantic mother runs towards the fallen boy, while in the foreground, Qin Cheng himself (seated in the middle) is still gambling, oblivious to the tragedy he has brought upon his family.`,
          image: LoyaltyAdd1,
        },
        {
          text: `This tableau addresses the importance of embracing one’s education. Two boys - Jia and Yi (Mandarin for A & B) - were enrolled in the Haw Par Free School in Hong Kong (depicted on the upper wall). We see Yi being dragged to school by his mother – he never wanted to study.  Jia is presumably already in class, as he loved schooling.`,
        },
        {
          text: `Eventually, both grew up and got jobs – Jia becoming a bank clerk and Yi working in his father’s store. At mid-level, we see Yi holding a book upside down, unable to read. Not much help to his father, then.`,
        },
        {
          text: `In the left foreground, we see Yi being pursued by his father (in the necktie), who wants him to do the shop accounts; but Yi is incapable of math, and anyway, he and his clutch-purse would rather go out gallivanting.`,
        },
        {
          text: `Aw Boon Haw was a problem student in his youth, but grew up to highly value the importance of a good education. A tableau like this would have been his personal testimony to the local community.`,
          image: LoyaltyAdd2,
        },
        {
          text: ` In this scene, loggers are engaged in satisfying manual labor while below them,  even the creatures of the sea likewise enjoy some hard work. (Even the legless sea-snail-woman is encouraging them.)`,
        },
      ],
      zone: "g",
    },
  ],
  g9: [
    {
      images: [Friendship],
      title: "Friendship and Loyalty",
      sections: [
        {
          text: `This rather violent tableau from the 1950s tells two versions of a morality tale about loyalty.  Both begin with two friends on a picnic in the wilderness when they encounter a bear.`,
        },
        {
          text: `Version #1 : Boy A ran away, leaving his friend behind. The abandoned friend feigned death and the bear passed him over, pursuing the fleeing boy instead and devouring him.`,
        },
        {
          text: `Version B : Boy A climbed up a tree, leaving his friend on the ground. He saw the bear approach Boy B (who was playing dead), sniff at him with its snout close to B’s ear, then wander off. Climbing back down when the coast was clear, Boy A asked B what the bear said to him. Boy B replied : “The bear said to never trust a friend who betrays you in time of trouble.”`,
        },
      ],
      zone: "g",
    },
  ],
  g10: [
    {
      images: [Villa],
      title: "The Villa",
      sections: [
        {
          text: `In 1937, after two years of construction and costing US$1.95 million, the Haw Par Villa opened to much fanfare. The prevailing architectural trend at that time was Art Deco, but Aw Boon Haw wanted to be ahead of the times. He chose a design much more sleek and simple, then embellished it with futuristic touches.`,
        },
        {
          text: `During the Japanese Occupation, the Villa was used as a coastal observation post. Afterwards, locals associated it with the Japanese and vandalized the villa. In 1946, upset by the damage and grieving over his brother’s death two years earlier, Aw Boon Haw had the house torn down and henceforth focused fully on developing the Gardens.`,
        },
        {
          text: `The house consisted of a reception hall which faced the sea, a large central hall, a drawing room, a dining room, as well as dressing and bed rooms. Inner walls were of colored marble with mother-of-pearl inlays. Lights were installed inside the transom windows which could be mixed to send multi-colored rays out into the night.`,
          image: VillaAdd,
        },
        {
          text: `The house was all about circles - a central circle with six orbiting smaller circles, each with a semi-spherical dome.  Continuing the motif, the driveway was a circle, the steps are semi-circular, the lampposts had globe lights and throughout the Gardens, the boundary walls are of a unique balanced-ball design. To the Chinese, the circle is a symbol of family unity, and the widespread use of circles here celebrates the strength of family and more specifically, the bond between Boon Haw and Boon Par’s families.`,
        },
      ],
      zone: "g",
    },
  ],
  g11: [
    {
      images: [Gorillas],
      title: "Gorillas",
      sections: [
        {
          text: `Added to the Gardens in the 1960s, these gorillas used to be much further down the slope but were moved up here in the Dragon World days to keep the Australian animals company.  They were once watched over by a tiger and leopard which have now been moved to flank the northern villa steps.`,
        },
        {
          text: `When the villa was still standing, this lawn was dotted with animal sculptures such as deer and storks, so having these animals up here does bring things full circle.`,
        },
      ],
      zone: "g",
    },
  ],
  g12: [
    {
      images: [Happiness],
      title: "The Happiness Boys",
      sections: [
        {
          text: `Dubbed ‘The Happiness Boys’ and built in the early 1950s, the Chinese name for this unusual pedestal display is 鼓乐升来, meaning “the music of the drums rises”.`,
        },
      ],
      zone: "g",
    },
  ],
  g13: [
    {
      images: [Emu],
      title: "Kangaroos / Koalas / Emu",
      sections: [
        {
          text: `These animals were added in the 1950s to constitute the Australian Corner further down the hill, giving the visiting locals a chance to see the unique fauna of Australia without leaving our tropical shores.  The original tree that the koalas clung to used to be over 3.5metres high!`,
        },
        {
          text: `The native Australian black swans that accompanied them have since been relegated to a spot near the pond (C10).`,
        },
      ],
      zone: "g",
    },
    {
      images: [Kiwi],
      title: "Kiwis",
      sections: [
        {
          text: `Created in 1965, these two flightless birds once pecked at the feet of a giant Maori tiki that stood 4.5metres high in the New Zealand exhibit.`,
        },
      ],
      zone: "g",
    },
    {
      images: [Frog],
      title: "Ostrich with Tortoise & Frog Riding",
      sections: [
        {
          text: `In the early 1950s, artisan Tan Seng Hwa  created this whimsical display near the entrance of the Gardens. A toad and a turtle are racing ostriches – the toad wearing a jockey cap and the turtle wearing a nightcap.  Such a display would certainly have warned visitors that they were in for a whimsical journey!`,
        },
      ],
      zone: "g",
    },
  ],
};

export default ZoneGMapping;
