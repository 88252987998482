import { EntryMap } from "../content/entryMap";

// Zone A Images
import Tiger from "../assets/photos/zone_a/Tiger.jpg";
import ObservationTiger from "../assets/photos/zone_a/ObservationTiger.jpg";

import PythonRam from "../assets/photos/zone_a/PythonRam.jpg";
import Plaque from "../assets/photos/zone_a/Plaque.jpg";

import GateLeopard from "../assets/photos/zone_a/GateLeopard.jpg";
import GateTiger from "../assets/photos/zone_a/GateTiger.jpg";
import GatePhotograph from "../assets/photos/zone_a/GatePhotograph.jpg";

import MiniHouse from "../assets/photos/zone_a/MiniHouse.png";

import OldMan from "../assets/photos/zone_a/OldMan.png";
import ObservationOldMan from "../assets/photos/zone_a/ObservationOldMan.jpg";
import TigerBalmGardenArch from "../assets/photos/zone_a/TigerBalmGardenArch.jpg";
import TigerBalmGardenPhotograph from "../assets/photos/zone_a/TigerBalmGardenPhotograph.jpg";
import HPV4 from "../assets/photos/zone_a/HPV4.jpg";

import Thai from "../assets/photos/zone_a/Thai.jpg";
import KohSunYanDragon from "../assets/photos/zone_a/KohSunYanDragon.jpg";

import Sakyamuni from "../assets/photos/zone_a/Sakyamuni.jpg";
import HPV2 from "../assets/photos/zone_a/HPV2.jpg";
import PagodaWell from "../assets/photos/zone_a/PagodaWell.jpg";

import Memorial from "../assets/photos/zone_a/Memorial.jpg";

import ThreeTigers from "../assets/photos/zone_a/ThreeTigers.jpg";
import ThreeTigersPhoto from "../assets/photos/zone_a/ThreeTigersPhoto.jpg";

import SuWu from "../assets/photos/zone_a/SuWu.jpg";
import SuWuPhoto from "../assets/photos/zone_a/SuWuPhoto.jpg";

import BuDai from "../assets/photos/zone_a/BuDai.jpg";

import WhiteSnake from "../assets/photos/zone_a/WhiteSnake.jpg";
import WhiteSnakePhoto1 from "../assets/photos/zone_a/WhiteSnakePhoto1.jpg";
import WhiteSnakePhoto2 from "../assets/photos/zone_a/WhiteSnakePhoto2.jpg";
import WhiteSnakePhoto3 from "../assets/photos/zone_a/WhiteSnakePhoto3.jpg";
import WhiteSnakePhoto4 from "../assets/photos/zone_a/WhiteSnakePhoto4.jpg";

import Phoenix from "../assets/photos/zone_a/Phoenix.jpg";
import Stupa from "../assets/photos/zone_a/Stupa.jpg";
import Pandas from "../assets/photos/zone_a/Pandas.jpg";

import Sakyamuni2 from "../assets/photos/zone_a/Sakyamuni2.jpg";
import Sakyamuni2Extra from "../assets/photos/zone_a/Sakyamuni2Extra.jpg";
import Sakyamuni2Owl from "../assets/photos/zone_a/Sakyamuni2Owl.jpg";

import LinZeXu from "../assets/photos/zone_a/LinZeXu.jpg";

const ZoneAMapping: EntryMap = {
  // Zone A Entries
  a1: [
    {
      images: [Tiger, PythonRam],
      title: "Tiger, Python & Ram",
      sections: [
        {
          bold: `For decades, this iconic ram-python-tiger trio has greeted gasping visitors to the Tiger Balm Gardens. It was built by Fujian craftsman Guo Yun Shan 郭云山 in the 1950s. It demonstrates the Chinese idiom inscribed under the ram : “螳螂捕蝉, 黄雀在后” (“While the mantis is pursuing the cicada, the oriole is right behind”), reminding us that while we are busy being a predator, we ourselves might become prey.`,
          text: `Aw Boon Haw replaced the idiom’s insects with a vicious snake poised to attack a clueless ram. In a clever marketing twist, the oriole at the top of the food chain has been replaced with the invincible tiger, representing the family brand Tiger Balm (and Aw Boon Haw himself)!`,
        },
        {
          text: `In Chinese culture, the tiger is regarded as the king of the wild beasts – representing fierceness, prowess and protection from evil spirits. Tiger organs have always been prized for their properties of healing and enhancing virility. Boon Haw found this association irresistible - both as a personal namesake and as a corporate identity.`,
        },
        {
          text: `That said, this tiger was far more impressive in the past because the snake it was stalking was nearly 10 metres long! Sometimes, size does matter.`,
          image: ObservationTiger,
        },
      ],
      observation: [
        {
          text: `Can you see the trio of little birds perched in a hollow of a tree branch in the foreground? The Gardens are full of charming little details like this, so keep an eye out!`,
        },
      ],
      zone: "a",
    },
  ],
  a2: [
    {
      images: [Plaque],
      title: "Masonry Plaque",
      sections: [
        {
          bold: `To the left of the main entrance stands a mock pavilion with a marble plaque displaying the names of the two brothers whose affection for each other (and for their community) lies at the heart of Haw Par Villa.胡 Hu is their family name.文虎 Boon Haw is the older brother,文豹 Boon Par the younger. 虎 means tiger and 豹 means leopard. The pairing is what gives us “Haw Par” - hence the tiger and leopard eyeing the visitor from either side of this plaque.`,
          text: `The couplet painted on the tiger side urges: “Be assiduous and have stamina for labor; when drinking water, remember its source”.  This encapsulates the spirit of the Aw brothers, combining hard work with a deep sense of heritage.`,
        },
        {
          text: `On the rock nearby is painted a single word ‘寿 (shou)’, meaning longevity, one of the most profound desires of the traditional Chinese.`,
        },
      ],
      zone: "a",
    },
  ],
  a3: [
    {
      images: [GateLeopard, GateTiger],
      title: "Wrought Iron Gate with Tiger and Leopard ",
      sections: [
        {
          bold: `This wrought-iron gate stands in the place of the original Haw Par Villa entrance, which was not a gate at all but an open archway, welcoming visitors at all times. The Chinese words on the new gate name the hilltop villa - Hu Bao Bie Shu 虎豹别墅 (Tiger-Leopard Villa) - and echo the circular design of the old one.`,
          text: `The tiger is the symbol of the billionaire creator of the Gardens, Aw Boon Haw (胡文虎 Hu Wen Hu). His given name means 'gentle and cultured (文) tiger (虎)'. The leopard represents his younger brother Aw Boon Par (胡文豹 Hu Wen Bao)  - 'gentle and cultured leopard (豹)'.`,
        },
        {
          text: `You will see many tiger-leopard pairs throughout the Gardens, often guarding significant thresholds.`,
          image: GateLeopard,
        },
      ],
      zone: "a",
    },
  ],
  a4: [
    {
      images: [MiniHouse],
      title: "Miniature House and Five-Tiered Pagoda",
      sections: [
        {
          bold: `Beyond the main gates, on the right, stands a miniature house in the Fujian style (home province of the craftsman Guo Yun Shan 郭云山). Aw Boon Haw and his craftsman liked to play with scale – you will find many miniatures throughout the gardens – sometimes to add perspective in a tableau, other times simply to celebrate the Chinese love for landscapes in glorious 3D.`,
        },
        {
          bold: `Guarding the house is an elegant tiger-leopard pair. The mini pagoda in the forecourt is topped with a stupa – a Buddhist structure traditionally used to for meditation and to house holy relics (monk’s ashes and suchlike).`,
          text: `The egg-shaped dome of a stupa evokes the Cosmic Egg from which the Universe was born. Atop the dome is a finial representing the cutting from the Bodhi tree, which connects Heaven and Earth like a spiritual lightning rod - it signifies the spreading of the faith.`,
        },
        {
          text: `The banner above the house’s main door reads “blossoms and riches”, wishing the owners prosperity in all things. The couplet on either side tells us that “these doors and walls are full of tradition/heritage” and that “the house also is full of Confucian aspect”.`,
        },
      ],
      observation: [
        {
          text: `Traditionally, Chinese front doors are decorated with a red banner above (bearing an idiom) and red banners on either side bearing a couplet. Called对联 dui lian - literally ‘opposite but linked’ – these poems are carefully crafted to mirror each other in structure, cadence and meaning. These couplets are read from right to left, in the traditional Chinese way.  Look out for these on the doorway of every house and temple in these Gardens, however big or small!`,
        },
      ],
      zone: "a",
    },
  ],
  a5: [
    {
      images: [OldMan],
      title: "Old Man",
      sections: [
        {
          bold: `This anonymous old man is believed to represent the Chinese saying “指点迷津 (zi dian mi jin)” meaning “to give good advice to those who are lost”. He advertises the moral guidance available in the Gardens - if visitors study the statues and humbly internalize their messages, they may avoid straying off the righteous path.`,
          text: "",
        },
      ],
      observation: [
        {
          text: `In his left hand, the old man carries a staff carved with a bat’s head.
          The bat is a Chinese symbol of good luck, because its name 蝠 fu both looks and sounds like 幅 fu, meaning happiness.
          Look out for more lucky bats lurking around the Gardens.`,
          image: ObservationOldMan,
        },
      ],
      zone: "a",
    },
    {
      images: [TigerBalmGardenArch, TigerBalmGardenPhotograph],
      title: "Tiger Balm Garden Arch",
      sections: [
        {
          bold: `This iconic arch has welcomed visitors since the Villa was built in 1937.`,
        },
        {
          bold: `This iconic arch has welcomed visitors since the Villa was built in 1937. Called 牌坊 pai fang or 牌楼 pai lou, this type of free-standing archways is used in China to mark the border of a new precinct or neighborhood. They can even be found marking the entrance to the Chinatowns all over the world. You will find them marking various paths in these Gardens too - each one is unique and well-worth admiring.`,
          text: `The panel below the topmost roof used to read “万金油花园 (Wan Jin You Hua Yuan)” or “Ten Thousand Gold Gardens”, recording the former brand name of Tiger Balm. It was later remodeled to match the name on the wrought iron gate nearby.`,
        },
        {
          bold: `Beside this gate and all the way up the driveway, the iconic wall of balanced red and white balls forms the boundary of the estate. These were part of the art deco design that made Haw Par Villa one of the most stylish residences of its day.`,
          text: `Tucked under the gate’s central roof is a burning pearl – one of the symbolic Eight Treasures in Taoist belief. The pearl represents perfection and enlightenment, and the flames give it magical power.`,
          image: HPV4,
        },
        {
          text: `On either side of the pearl is a representation of the classic Chinese 斗拱   dougong or “cap and block” brackets. These wooden brackets have supported Chinese buildings for over 2500 years, and were popular in traditional Japanese and Korean architecture too. Perfectly interlocking yet using no nails, glue or other fixative, they transfer the weight of horizontal beams above to the vertical pillars beneath. This ingenious weight-bearing system offered a flexibility that helped many ancient buildings withstand earthquakes. After the Song dynasty (960-1127 CE), the dougong also became a popular decorative feature, as we see here.`,
        },
        {
          text: `As you enter the Gardens, you will see the tiger in the center panel in glorious mid-leap, echoing the logo of the world-famous Tiger Balm Brand.  As you depart, however, you will see the tiger standing proudly on all fours. Perhaps this is a cheeky acknowledgement of how tired the visitor might be?`,
        },
        {
          text: `Lower down and flanking this logo is a tiger-leopard pair at rest, executed in bas relief.`,
        },
        {
          text: `In gold letters on either side of the central arch, you’ll find a Chinese couplet or 对联 dui lian. This poetic tradition emerged during the Five Dynasties period in China (907-959 CE) and is still a popular adornment for gates, doorways and Chinese New Year decorations. Coming up with the ideal couplet is an intellectually-challenging exercise – they must be profound yet concise, achieving a strict match in number of characters, syntax, meaning and tone.`,
        },
        {
          text: `This particular couplet gives a preview of the Haw Par experience, telling us: “ten thousand rivers flow back here, with the silver waves of the sea within our view; enjoying the golden goblet together, the green mountains before us smoothen our brow.”`,
        },
        {
          text: `The feet of the supporting pillars are carved with gourds (葫芦 hu lu) – a symbol of protection against evil and disease (because its first character “hu” sounds like the word 护 ‘to protect’). It is alsoa magical object of healing carried by Tie Guai Li, one of the Eight Immortals. (See C1)`,
        },
      ],
      observation: [
        {
          text: `This is the most elaborate archway in the Gardens. Spend a moment admiring the richly-detailed bas relief still-life depictions of flowers and vases that adorn the panels flanking the big cats.`,
        },
      ],
      zone: "a",
    },
  ],
  a6: [
    {
      images: [Thai],
      title: "Dancing Thai Figure",
      sections: [
        {
          bold: `Formerly the centerpiece of a Thai exhibit built in the 1960s, this dancer holds a pose from khon dance, the classical Thai court dance. She used to stand before a Thai palace and lake painted on a low wall, and was part of a series of international tableaux, including Spanish, Japanese, Indonesian, New Zealand and Australian exhibits.`,
        },
      ],
      observation: [
        {
          text: `Observe the use of small mirrors set into the cement in the headdress, belt and dangling front panel. This technique is used often throughout the Gardens to give the statues a bit of bling.`,
        },
      ],
      zone: "a",
    },
    {
      images: [KohSunYanDragon],
      title: "Koh Sun Yan Dragon",
      sections: [
        {
          bold: `This small but intricate dragon was crafted in 1989 for the reopening of the Gardens as Dragon World.  It was made by Koh Sun Yan, a craftsman who had previously worked under Aw Cheng Chye (Aw Boon Haw’s nephew) in the late 1950s. He spent a month creating this small dragon, and even added wiring inside so that the eyes could light up!`,
          text: `It is a prime example of the Southern Chinese sculpting technique known as剪黏 jian nian (literally “cut–paste”) used by the earlier generation of sculptors working under Aw Boon Haw. This style uses  hundreds of pieces of cut glass to give the dragon such intricate and realistic scales. Other splendid examples are the phoenix at F1 and the Nine Dragon screen at G2.`,
        },
      ],
      observation: [
        {
          text: `Observe the use of small mirrors set into the cement in the headdress, belt and dangling front panel. This technique is used often throughout the Gardens to give the statues a bit of bling.`,
        },
      ],
      zone: "a",
    },
  ],
  a7: [
    {
      images: [Sakyamuni],
      title: "Sakyamuni seated under a pavilion",
      sections: [
        {
          bold: `This statue of Sakyamuni Buddha (如来佛祖 Ru Lai Fo Zu) is believed to have been brought here from Burma before WWII. He is shown seated and displaying the Bhumisparsha mudra - the ‘calling the earth to witness’ mudra, with the left hand in meditation while the right hand touches the earth.`,
        },
        {
          bold: `The mudra’s origin story : When Buddha-to-be Siddharta Gautama sat under the Bodhi tree meditating, the demon Mara tried to dislodge him by scaring him with armies of monsters. Failing to make him budge, Mara declared that the seat of enlightenment belonged to him because his spiritual accomplishments were superior.  All of the monstrous soldiers cried out to bear witness for their leader. “And who will speak for you?”, Mara asked Siddartha. Siddhartha touched the ground with his right hand and the earth itself roared “I bear you witness”. Mara vanished, defeated.`,
          text: `The single character painted below the name plate is ‘缘 yuan’, denoting the Chinese concept of fate.`,
        },
        {
          text: `Perhaps it refers to the way you are fated to be standing in the Gardens today, learning.`,
        },
        {
          text: `The pavilion housing the Buddha is 2-tiered, with quatrefoil pillars (four leafed, like the clover). This shape became popular in China during the late Spring and Autumn period   (770-476 BCE) and may have been introduced from the West.`,
          image: HPV2,
        },
        {
          text: `Linking the pillars to the underside of the roof beams are some unusual 8-bit designs. Facing outwards, a vividly contrasting bracket design takes over, carved into elaborate curls of foliage. This curling vegetation is also echoed in the pavilion at A14. It’s a startling contrast of manmade and natural - the Aws often urged the artisans to try different styles, and encouraged bold (and even clashing) combinations like this!`,
        },
      ],
      zone: "a",
    },
    {
      images: [PagodaWell],
      title: "Pagoda / Well",
      sections: [
        {
          bold: `PagodaWell`,
          text: `The paintings on the pagoda roof panels once depicted assorted tigers in nature, but they were later replaced by this trio of paintings alternating with a trio of short poems. Clockwise from the panel nearest the boundary wall :  `,
        },
        {
          text: `⦁	a portrait of 12th century monk and maverick folk hero Ji Gong, with his trademark tattered robes, fan and mischievous expression;`,
        },
        {
          text: `⦁	“(his) Prestige spreads to the four seas, (his) righteousness runs through the whole land”`,
        },
        {
          text: `⦁	an official with his tiger (arguably Guan Gong  關公, the Martial God of Wealth)`,
        },
        {
          text: `⦁	“green mountains and clear water - the scenery is good; people are long-lived, the years abundant and good deeds aplenty”`,
        },
        {
          text: `⦁	an eagle;`,
        },
        {
          text: `⦁	“(his) bravery is champion among a hundred beasts, (his) magnificence heroic manner manifests over the five mountains”.`,
        },
        {
          observation: [
            {
              text: `The spirals that curl up from the 4 corners of the roof look identical, but look carefully - they are actually different from each other, and feature tiny lucky bats clinging on to the swirls! See A5 for bat symbolism. Also, observe how this roof curves upwards at the corners like many traditional Chinese roofs? They’re called flying eaves and served two important purposes. Practically, they allowed smoke (from incense, lamps cooking, etc.) to escape easily. Spiritually, these curves would confound evil spirits – in Chinese belief, spirits can only travel in straight lines and just can’t handle curves.`,
            },
          ],
        },
        {
          text: `Also, observe how this roof curves upwards at the corners like many traditional Chinese roofs? They’re called flying eaves and served two important purposes. Practically, they allowed smoke (from incense, lamps cooking, etc.) to escape easily. Spiritually, these curves would confound evil spirits – in Chinese belief, spirits can only travel in straight lines and just can’t handle curves.`,
        },
      ],
      zone: "a",
    },
  ],
  a8: [
    {
      images: [Memorial],
      title: "Aw Hoe Memorial",
      sections: [
        {
          bold: `This stupa-like structure stands in memory of Aw Boon Haw’s adopted son Aw Hoe (1919-1951). Boon Haw had 4 wives, a not-uncommon practice among wealthy Chinese men. His first two wives did not bear him any children, but they adopted 3 sons and a daughter. Aw Hoe was one of them.`,
        },
        {
          bold: `Aw Hoe was a newspaperman and had just taken over two papers - the Tiger Standard and the Sin Chew Jit Poh. He used a small plane to broaden the circulation of his papers, but died when it it crashed in Thailand, killing everyone on board.`,
          text: `His early death, at a time when the Gardens were being expanded, made a memorial to him a meaningful addition. Befitting his status as a junior, his memorial is the shortest of the four family memorials in the Gardens and stands lowest on the hill.`,
        },
      ],
      zone: "a",
    },
  ],
  a9: [
    {
      images: [ThreeTigers],
      title: "Three Tigers",
      sections: [
        {
          bold: `This family of cartoon tigers was added in the 1960s to update Tiger Balm's marketing image, adding some rare smiling faces to the Gardens. They were previously located along the main driveway, perched on high pedestals to welcome visitors.`,
          image: ThreeTigersPhoto,
        },
      ],
      zone: "a",
    },
  ],
  a10: [
    {
      images: [SuWu],
      title: "Su Wu Tending Sheep 苏武牧羊",
      sections: [
        {
          bold: `Su Wu (140-60 CE) was a diplomat of the Han dynasty (206 BCE - 220 CE) who was sent as an envoy to the pre-Mongolian Xiongnu people. When Han-Xiongnu relations turned bad, the Xiongnu attempted to court his allegiance. Su Wu refused and was imprisoned, tortured and eventually exiled to Lake Baikal in Siberia, where he tended sheep for many years. He never once wavered in his loyalty to the Han, and is often cited as a paragon of loyalty to one's country despite terrible odds. This tableau is called 'Su Wu Tending Sheep' (苏武牧羊) and depicts his fierce loyalty in its most tranquil form.`,
          text: `This statue of Su Wu originally had a sculpted shack to live in, and the sheep were lounging on the rocky slope and munching on a fake tree. Frolicking around Su Wu, there was also an assortment of foxes, birds, rabbits, tortoises and even a solitary pangolin, giving him a strong Dr. Doolittle vibe. Some of these creatures are still here beside him on the grass.`,
          image: SuWuPhoto,
        },
      ],
      zone: "a",
    },
  ],
  a11: [
    {
      images: [BuDai],
      title: "Bu Dai, the Laughing Buddha (or Maitreya Bodhisattva弥勒佛)",
      sections: [
        {
          bold: `The Laughing Buddha is always poor but always content - that's why he sits in such a humble shelter instead of an elaborate pavilion like the other Buddhas in the Gardens. This eccentric monk from the Later Liang Dynasty was given the Buddhist name 契此 Qie Ci, but he was commonly known as 布袋 Bu Dai ('cloth bag'), referring to the cloth sack in which he carries his meagre belongings (seen here in his left hand). Bu Dai can also be found standing at D3.`,
          text: `Always jolly and benevolent, he is a popular Chinese figure in businesses and restaurants because he represents contentment (and the reward for making your customers content - prosperity). Here, the contentment extends to the very happy devotee on his right.`,
        },
        {
          text: `The inscription under the figure reads 皆大欢喜 (“Everyone is happy”) but when this statue was first built in the 1960s, it read “弥勒佛” (Maitreya Bodhisattva), referring to his role in a popular Buddhist prophecy.`,
        },
        {
          text: `Bu Dai is popularly regarded as an incarnation of Maitreya, the future Buddha (though not all Buddhists will agree.) Maitreya is described as a Messianic bodhisattva who has yet to appear on earth. He will come someday, when the teachings of Gautama Buddha have been neglected. His destiny is to become the fifth Buddha of this current kalpa (Buddhist aeon) and restore the Dharma to future generations.`,
        },
      ],
      zone: "a",
    },
  ],
  a12: [
    {
      images: [WhiteSnake],
      title: "Legend of the White Snake 白蛇传",
      sections: [
        {
          bold: `Previously on White Snake…`,
        },
        {
          bold: `After Bai Suzhen (AKA Madam White Snake) fails to rescue her beloved Xu Xian from the villainous turtle-turned-monk Fa Hai, Xu manages to escape on his own. The couple reunites in Hangzhou where their son Xu Mengjiao is born. The long arm of celestial law catches up with them, however, and the gods punish Bai for all the innocent lives lost during the aforementioned Deluge – she is imprisoned in Lei Feng Pagoda 雷峰塔 near the West Lake.`,
        },
        {
          bold: `In this episode…`,
        },
        {
          bold: `Learning of his mother’s imprisonment, Mengjiao has rushed to Lei Feng Pagoda, dressed in the red robes of an Imperial Examinations top scholar, wearing the hat of a court official. Kneeling before the altar, surrounded by his servants, flag-bearers and musicians, he offers up fervent prayers that reach the heavens. Touched by his filial piety, the celestial gods destroy the pagoda and release Madam White Snake.`,
          image: WhiteSnakePhoto1,
        },
        {
          text: `The title of this tableau (beside the broken pagoda) is “Xu Mengjiao Worships at the Pagoda”. The banner carried by the attendant on the left reads : “By Imperial order, honour your mother”;  and the one on the right reads : “Score first place in the Imperial Examination” – expressing the two top items on the every Chinese son’s to-do list.`,
        },
        {
          text: `Step back and look at the roof of the pavilion - atop the rising clouds stands Madam White Snake, is in the very act of ascending to Heaven.  She holds a magic whisk – a divine implement in both Taoist and Buddhist belief. Beneath her are inscribed the words “白蛇升天”– “White Snake ascends to the heavens”. Observe how ingenuously the fabric from her trailing skirt becomes the corners of the pagoda roof!`,
          image: WhiteSnakePhoto2,
        },
        {
          text: `This tableau was crafted by artisan Chen Xing He in the 1950s and is a sequel to the Deluge of the Golden Temple at C4.  In the original legend, it is Madam White Snake’s loyal friend and companion Xiaoqing (AKA Green Snake) who frees the White Snake from the pagoda. Aw Boon Haw chose instead to highlight the power of filial piety by commissioning this version instead.`,
        },
        {
          text: `To the left of the pagoda is a much later addition to the White Snake saga. (Note how the sculpting of the faces is vastly different from the style used by the craftsmen under Aw Boon Haw’s supervision.) Here, the monk Fa Hai is trying to offer Xu Xian a talisman, telling him that he needs protection from his wife’s evil form.`,
          image: WhiteSnakePhoto3,
        },
        {
          text: `Further to the left, we see Xu Xian’s first fateful encounter with the snake sisters on an abridged Broken Bridge 斷橋, where many of the legend’s key moments take place.`,
          image: WhiteSnakePhoto4,
        },
      ],
      observation: [
        {
          text: `Step back and look at the top of the pavilion - atop a rising mound of clouds stands Madam White Snake. She is holding a magic whisk - a divine implement in both Taoist and Buddhist belief – and is in the very act of ascending to Heaven.`,
        },
        {
          text: `Beneath her are inscribed the words “白蛇升天” - “White Snake ascends to the heavens”.`,
        },
        {
          text: `Observe how ingenuously the fabric from her trailing skirt becomes the corners of the pagoda roof!`,
        },
      ],
      zone: "a",
    },
  ],
  a13: [
    {
      images: [Pandas],
      title: "Pandas",
      sections: [
        {
          bold: `Two pandas are climbing the rocks while their offspring peep out from a small cave. Near them, two unidentified rodents might be heading towards the stupa. These creatures were crafted in the early 1950s.`,
        },
      ],
      zone: "a",
    },
    {
      images: [Stupa],
      title: "Stupa",
      sections: [
        {
          bold: `Atop a towering faux rock formation stands an elaborate stupa. Stupas are Buddhist structures built to house holy relics and commonly used as places for meditation. Like this one, stupas can often be found in high-up, out-of-the-way spots, offering the devotee a more isolated and hopefully transcendental experience.`,
          text: `Each level of a stupa represents a step up the path to Enlightenment, while the egg-shapes evokes the Cosmic Egg that hatched the Universe.`,
        },
      ],
      zone: "a",
    },

    {
      images: [Phoenix],
      title: "Phoenix",
      sections: [
        {
          bold: `
      High up on the rock behind the snake sisters perches a phoenix, sculpted in the early 1950s. Observe the intricate detail of the feathers, each an individual piece of cut glass attached and painted.  This Southern Chinese style of sculpting - called 剪黏 jian nian (“cut-paste”) is used throughout the Gardens.
      You can see an even grander phoenix at F1; and the Nine Dragon Screen at G2 exhibits this style excellently.`,
        },
      ],
      zone: "a",
    },
  ],
  a14: [
    {
      images: [Sakyamuni2],
      title: "Sakyamuni seated under pavilion",
      sections: [
        {
          bold: `This pavilion nestled among the rocks is unusual for its oval shape and sunken base. Built in the early 50s, its finely filigreed roof is suspended by intricate curling vines, which in turn are supported by concrete tree trunks. The deliberate rawness of the trunks creates a startling contrast with the ingenuity of the roof.`,
          image: Sakyamuni2Extra,
        },
        {
          bold: `Underneath it sits Sakyamuni Buddha (如来佛祖 Ru Lai Fo Zu) displaying the ‘calling the earth to witness’ mudra. See A7 for the mudra’s meaning.`,
        },
      ],
      zone: "a",
    },
    {
      images: [Sakyamuni2Owl],
      title: "Owls",
      sections: [
        {
          bold: `Watching over the Buddha with wide eyes are three large Owls, crafted in the early 50s and previously located elsewhere in the Gardens.`,
        },
      ],
      zone: "a",
    },
  ],
  a15: [
    {
      images: [LinZeXu],
      title: "Lin Ze Xu",
      sections: [
        {
          bold: `This early 1950s statue depicts the Chinese national hero Lin Ze Xu  林则徐 (1785-1850 CE). Revered as a brilliant scholar, a scrupulous politician and a patriot of high moral standing, he is remembered for his fervent anti-drug campaigning and for leading his country against the British invasion of 1839-1842.`,
        },
        {
          bold: `Patriotism was important to the Aws, and they conveyed this through the heroes they chose to depict in the Gardens. Another nearby patriot who was impeached and exiled is Su Wu at A10.`,
        },
        {
          bold: `The poem painted behind Lim reads : “Seated atop a mountain facing the sea, you can see far & wide. Lin Ze Xu’s model behaviour will live on.”`,
          text: `Born in Fujian, Lin secured the top degree in the Imperial Examination and rose rapidly to become  Governor-General of Hunan and Hubei Provinces.  In 1839, he was sent to Guangdong Province to suppress the illegal import of opium. He wrote an open letter to Queen Victoria to urge her to halt the opium trade, arguing that in exchange for valuable commodities such as tea, porcelain, spices and silk, Britain was only sending China “poison” in return. Unfortunately, shipping merchants attacked the Emperor’s fleet and that letter never reached Queen Victoria.`,
        },
        {
          text: `At home, Lin Ze Xu arrested over 1,700 Chinese opium dealers, confiscated 70,000 opium pipes and forced merchants to surrender nearly 1.2 million kilograms of opium, which he destroyed. In retaliation, British forces invaded China and the First Opium War began.`,
        },
        {
          text: `Lin made extensive preparations for the war and warned the Governors of the provinces further north about imminent attacks. However, his advice was ignored. China’s weak military was defeated and the resulting 1842 Treaty of Nanjing cost China 21 million silver dollars and the island of Hong Kong.`,
        },
        {
          text: `The Emperor had previously endorsed Lin’s policies but now blamed Lin for the war – Lin was demoted and exiled.  From 1845, Lin was reinstated and given far-flung governor-general posts until he was given a prestigious title in 1849. He died the year after.`,
        },
        {
          text: `Today, Lin remains an influential figure, both in China and globally. Films have been made about his life, and statues of him stand at New York City’s Chinatown as well as London’s Madame Tussaud’s.`,
        },
        {
          text: `In recognition of his work, the United Nations declared 26 June the International Day against Drug Abuse and Illicit Trafficking.  26 June was the day that 500 workers under Lin’s command finished destroying the opium he had confiscated 23 days earlier.`,
        },
        {
          text: `This statue was removed and put into storage in the 80s, but has since been restored and given this prominent location along the main driveway`,
        },
      ],
      zone: "a",
    },
  ],
};

export default ZoneAMapping;
