import FloatingActionButton from "../components/FloatingActionButton";

import ZoneAMapWithIcons from "../assets/maps/ZoneAMapWithIcons.jpg";

import HotSpot from "../components/HotSpot";

export default function ZoneA() {
  return (
    <div style={{ position: "relative" }}>
      <img src={ZoneAMapWithIcons} width="100%" />
      <HotSpot left={74} top={80} path="/entry/a1" backgroundColor="green" />
      <HotSpot left={61} top={91} path="/entry/a2" backgroundColor="green" />
      <HotSpot left={54} top={80} path="/entry/a3" backgroundColor="green" />
      <HotSpot left={56} top={66} path="/entry/a4" backgroundColor="green" />
      <HotSpot left={33} top={57} path="/entry/a5" backgroundColor="green" />
      <HotSpot left={44} top={50.5} path="/entry/a6" backgroundColor="green" />
      <HotSpot
        left={42.25}
        top={42.6}
        path="/entry/a7"
        backgroundColor="green"
      />
      <HotSpot left={70} top={57.5} path="/entry/a8" backgroundColor="green" />
      <HotSpot left={82} top={45} path="/entry/a9" backgroundColor="green" />
      <HotSpot left={61} top={36} path="/entry/a10" backgroundColor="green" />
      <HotSpot left={45} top={28} path="/entry/a11" backgroundColor="green" />
      <HotSpot left={28} top={19} path="/entry/a12" backgroundColor="green" />
      <HotSpot left={25} top={10} path="/entry/a13" backgroundColor="green" />
      <HotSpot left={34} top={12} path="/entry/a14" backgroundColor="green" />
      <HotSpot left={25} top={4} path="/entry/a15" backgroundColor="green" />
      <FloatingActionButton path="/map" />
    </div>
  );
}
