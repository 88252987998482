import React from "react";
import FloatingActionButton from "../components/FloatingActionButton";

import HotSpot from "../components/HotSpot";

export default function AreaMapGreen() {
  return (
    <div>
      <HotSpot
        left={40}
        top={24}
        path="/entry/3"
        size={30}
        backgroundColor="green"
      />
      <HotSpot
        left={80}
        top={30}
        path="/entry/3"
        size={30}
        backgroundColor="green"
      />
      <HotSpot
        left={20}
        top={50}
        path="/entry/3"
        size={30}
        backgroundColor="green"
      />
      <HotSpot
        left={30}
        top={62}
        path="/entry/3"
        size={30}
        backgroundColor="green"
      />
      <FloatingActionButton path="/map" />
    </div>
  );
}
