import React from "react";
import FloatingActionButton from "../components/FloatingActionButton";

import HotSpot from "../components/HotSpot";

export default function AreaMapRed() {
  return (
    <div>
      <HotSpot
        left={43}
        top={38}
        path="/entry/1"
        size={30}
        backgroundColor="red"
      />
      <HotSpot
        left={65}
        top={22}
        path="/entry/1"
        size={30}
        backgroundColor="red"
      />
      <HotSpot
        left={20}
        top={50}
        path="/entry/1"
        size={30}
        backgroundColor="red"
      />
      <FloatingActionButton path="/map" />
    </div>
  );
}
