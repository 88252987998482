import FloatingActionButton from "../components/FloatingActionButton";

import ZoneGMapWithIcons from "../assets/maps/ZoneGMapWithIcons.jpg";

import HotSpot from "../components/HotSpot";

export default function ZoneG() {
  return (
    <div style={{ position: "relative" }}>
      <img src={ZoneGMapWithIcons} width="100%" />
      <HotSpot left={40} top={18} path="/entry/g1" backgroundColor="green" />
      <HotSpot left={85} top={22.5} path="/entry/g2" backgroundColor="green" />
      <HotSpot left={73} top={38.5} path="/entry/g3" backgroundColor="green" />
      <HotSpot left={62} top={48} path="/entry/g4" backgroundColor="green" />
      <HotSpot left={78} top={47} path="/entry/g5" backgroundColor="green" />
      <HotSpot left={78.5} top={53} path="/entry/g6" backgroundColor="green" />
      <HotSpot left={67} top={69} path="/entry/g7" backgroundColor="green" />
      <HotSpot left={69} top={74.5} path="/entry/g8" backgroundColor="green" />
      <HotSpot left={67} top={83} path="/entry/g9" backgroundColor="green" />
      <HotSpot left={37} top={63.5} path="/entry/g10" backgroundColor="green" />
      <HotSpot left={26} top={43} path="/entry/g11" backgroundColor="green" />
      <HotSpot left={12} top={56} path="/entry/g12" backgroundColor="green" />
      <HotSpot
        left={4.5}
        top={75.5}
        path="/entry/g13"
        backgroundColor="green"
      />
      <FloatingActionButton path="/map" />
    </div>
  );
}
