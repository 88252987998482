import _ from "lodash";
import styled from "@emotion/styled";
import { Divider, Typography, Box } from "@mui/material";
import { useParams } from "react-router-dom";

import { entryMap, StatueContent } from "../content/entryMap";
import Quiz from "./Quiz";
import ObservationButton from "./buttons/ObservationButton";

import { QuizContent, quizMap } from "../content/quizMap";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import EntryBackground from "../assets/illustrations/EntryBackground.jpg";

interface StatueContentDisplayProps {
  statueContent: StatueContent;
  singleImage: boolean;
}

export default function StatueContentDisplay(props: StatueContentDisplayProps) {
  const statueContent = props.statueContent;
  const singleImage = props.singleImage;

  const ImageDisplay = (props: any) => {
    return <img style={{ width: "100%" }} src={props.image} />;
  };

  const params = useParams();
  const entryId = _.get(params, "entryId", "404");

  const responsive = {
    mobile: {
      breakpoint: { max: 2000, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div
      style={{
        position: "relative",
        background: `url(${EntryBackground})`,
        paddingBottom: "16px",
        minHeight: "100%",
      }}
    >
      {statueContent.images.length > 1 ? (
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          keyBoardControl={true}
          containerClass="carousel-container"
        >
          {statueContent.images.map((image) => {
            return <ImageDisplay image={image} />;
          })}
        </Carousel>
      ) : (
        <ImageDisplay image={statueContent.images[0]} />
      )}

      <Box pl={2} pr={2}>
        <Typography variant="h5" align="left">
          <Box
            mb={1}
            mt={1}
            sx={{
              fontWeight: "bold",
              textTransform: "uppercase",
              fontFamily: "Oswald",
            }}
          >
            {singleImage && `${entryId}:`} {statueContent.title}
          </Box>
        </Typography>
      </Box>

      {statueContent.sections.map((section) => (
        <>
          <Box pl={2} pr={2}>
            {section.bold && (
              <Typography align="left">
                <Box
                  mb={1}
                  mt={1}
                  sx={{
                    fontWeight: "600",
                  }}
                >
                  {section.bold}
                </Box>
              </Typography>
            )}
            {section.cbold && (
              <Typography align="center">
                <Box
                  mb={1}
                  mt={1}
                  sx={{
                    fontWeight: "600",
                  }}
                >
                  {section.cbold}
                </Box>
              </Typography>
            )}
            {section.text && (
              <>
                <Box mt={3} mb={3}></Box>
                <Typography align="left">{section.text}</Typography>
              </>
            )}
            {section.ctext && (
              <>
                <Box mt={3} mb={3}></Box>
                <Typography align="center">{section.ctext}</Typography>
              </>
            )}
          </Box>
          <Box pl={2} pr={2}>
            {section.observation && (
              <ObservationButton observation={section.observation} />
            )}
          </Box>
          <Box mt={2} mb={2}>
            {section.image && <ImageDisplay image={section.image} />}
          </Box>
        </>
      ))}
      <Box pl={2} pr={2}>
        {statueContent.observation && (
          <ObservationButton observation={statueContent.observation} />
        )}
      </Box>
    </div>
  );
}
