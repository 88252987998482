import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import "./App.css";

import { useAppSelector } from "./redux/app/hooks";
import { selectAuth } from "./redux/features/auth/authSlice";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import Home from "./pages/Home";
import Login from "./pages/public/Login";
import Register from "./pages/public/Register";
import MainMap from "./pages/MainMap";
import EntryPage from "./components/EntryPage";
import AreaMapRed from "./pages/AreaMapRed";
import AreaMapGreen from "./pages/AreaMapGreen";
import AreaMapBlue from "./pages/AreaMapBlue";

import ZoneA from "./pages/ZoneA";
import ZoneB from "./pages/ZoneB";
import ZoneC from "./pages/ZoneC";
import ZoneD from "./pages/ZoneD";
import ZoneE from "./pages/ZoneE";
import ZoneF from "./pages/ZoneF";
import ZoneG from "./pages/ZoneG";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  const auth = useAppSelector(selectAuth);
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* <Route element={<PublicRoute auth={auth} />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Route> */}
          <Route element={<PrivateRoute auth={auth} />}>
            {/* Reintroduce after setting up Next.js */}
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<MainMap />} />
            <Route path="/zone/a" element={<ZoneA />} />
            <Route path="/zone/b" element={<ZoneB />} />
            <Route path="/zone/c" element={<ZoneC />} />
            <Route path="/zone/d" element={<ZoneD />} />
            <Route path="/zone/e" element={<ZoneE />} />
            <Route path="/zone/f" element={<ZoneF />} />
            <Route path="/zone/g" element={<ZoneG />} />
            <Route path="/area/red" element={<AreaMapRed />} />
            <Route path="/area/green" element={<AreaMapGreen />} />
            <Route path="/area/blue" element={<AreaMapBlue />} />
            <Route path="/entry/:entryId" element={<EntryPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
