import { EntryMap } from "../content/entryMap";

// Zone B images

import Garage1 from "../assets/photos/zone_b/compressed/b1_main1a.jpg";
import Garage2 from "../assets/photos/zone_b/compressed/b1_main1b.jpg";
import GarageAdd1 from "../assets/photos/zone_b/compressed/b1_extra1a.jpg";
import GarageAdd2 from "../assets/photos/zone_b/compressed/b1_extra1b.jpg";
import GarageAdd3 from "../assets/photos/zone_b/compressed/b1_extra1c.jpg";

import LawEnvoy from "../assets/photos/zone_b/compressed/b2_main1a.jpg";
import Bodhisattva from "../assets/photos/zone_b/compressed/b2_main2a.jpg";
import TigerShrine from "../assets/photos/zone_b/compressed/b2_main3a.jpg";
import BodhisattvaAdd from "../assets/photos/zone_b/compressed/b2_extra2a.jpg";
import TigerShrineAdd from "../assets/photos/zone_b/compressed/b2_extra3a.jpg";

import TenHellCourts from "../assets/photos/zone_b/compressed/b3_main1a.jpg";
import HellGate from "../assets/photos/zone_b/compressed/b3_main2a.jpg";
import FirstCourt from "../assets/photos/zone_b/compressed/b3_main3a.jpg";
import SecNineCourt from "../assets/photos/zone_b/compressed/b3_main4a.jpg";
import FourthCourt from "../assets/photos/zone_b/compressed/b3_main5a.jpg";
import FifthCourt from "../assets/photos/zone_b/compressed/b3_main6a.jpg";
import TenthCourt from "../assets/photos/zone_b/compressed/b3_main7a.jpg";
import SamsaraSix from "../assets/photos/zone_b/compressed/b3_main8a.jpg";
import TenHellCourtsAdd from "../assets/photos/zone_b/compressed/b3_extra1a.jpg";
import FirstCourtAdd from "../assets/photos/zone_b/compressed/b3_extra3a.jpg";
import TenthCourtAdd from "../assets/photos/zone_b/compressed/b3_extra7a.jpg";

import CricketFight from "../assets/photos/zone_b/compressed/b4_main1a.jpg";

import FilialPiety from "../assets/photos/zone_b/compressed/b5_main1a.jpg";
import FilialPietyAdd1 from "../assets/photos/zone_b/compressed/b5_extra1a.jpg";
import FilialPietyAdd2 from "../assets/photos/zone_b/compressed/b5_extra1b.jpg";
import FilialPietyAdd3 from "../assets/photos/zone_b/compressed/b5_extra1c.jpg";

import OxenDuel from "../assets/photos/zone_b/compressed/b6_main1a.jpg";
import FarmerWife from "../assets/photos/zone_b/compressed/b6_main2a.jpg";
import FarmerWifeAdd from "../assets/photos/zone_b/compressed/b6_extra2a.jpg";

import Village from "../assets/photos/zone_b/compressed/b7_main1a.jpg";

import RockForm from "../assets/photos/zone_b/compressed/b8_main1a.jpg";
import RockFormAdd1 from "../assets/photos/zone_b/compressed/b8_extra1a.jpg";
import RockFormAdd2 from "../assets/photos/zone_b/compressed/b8_extra1b.jpg";
import RockFormAdd3 from "../assets/photos/zone_b/compressed/b8_extra1c.jpg";
import RockFormAdd4 from "../assets/photos/zone_b/compressed/b8_extra1d.jpg";
import RockFormAdd5 from "../assets/photos/zone_b/compressed/b8_extra1e.jpg";
import RockFormAdd6 from "../assets/photos/zone_b/compressed/b8_extra1f.jpg";
import RockFormAdd7 from "../assets/photos/zone_b/compressed/b8_extra1g.jpg";
import RockFormAdd8 from "../assets/photos/zone_b/compressed/b8_extra1h.jpg";
import RockFormAdd9 from "../assets/photos/zone_b/compressed/b8_extra1i.jpg";
import RockFormAdd10 from "../assets/photos/zone_b/compressed/b8_extra1j.jpg";
import RockFormAdd11 from "../assets/photos/zone_b/compressed/b8_extra1k.jpg";
import RockFormAdd12 from "../assets/photos/zone_b/compressed/b8_extra1l.jpg";
import RockFormAdd13 from "../assets/photos/zone_b/compressed/b8_extra1m.jpg";
import RockFormAdd14 from "../assets/photos/zone_b/compressed/b8_extra1n.jpg";
import RockFormAdd15 from "../assets/photos/zone_b/compressed/b8_extra1o.jpg";
import RockFormAdd16 from "../assets/photos/zone_b/compressed/b8_extra1p.jpg";
import RockFormAdd17 from "../assets/photos/zone_b/compressed/b8_extra1q.jpg";
import RockFormAdd18 from "../assets/photos/zone_b/compressed/b8_extra1r.jpg";
import RockFormAdd19 from "../assets/photos/zone_b/compressed/b8_extra1s.jpg";

import RatsRabbits from "../assets/photos/zone_b/compressed/b9_main1a.jpg";
import WhiteSnakePav from "../assets/photos/zone_b/compressed/b9_main2a.jpg";

export const ZoneBMapping: EntryMap = {
  // Zone B Entries
  b1: [
    {
      images: [Garage1, Garage2],
      title: "Garage",
      sections: [
        {
          text: `This structure was the original garage of the Villa and housed the notorious Tiger Car in which Aw Boon Haw often drove around Singapore in the 1930s, drawing curious crowds and selling Tiger Balm products at the same time. There were two incarnations of the Tiger Car – a German NSU and a Humber. Both featured a sculpted tiger head on the front (from whose mouth radiator steam would billow forth), red glowing eyes and a horn that sounded like a roar.`,
          image: GarageAdd1,
        },
        {
          text: `Apparently, Boon Haw only made one road trip to Malaysia in the Tiger Car – it frightened the villagers so badly that he never drove it out of Singapore again.`,
        },
        {
          text: `A recreation of this legendary vehicle can be seen in the Culture Courtyard, opposite the Eight Immortals tableau (C2).`,
          image: GarageAdd2,
        },
        {
          text: `Today, the garage also serves as the gateway  to the Ten Courts of Hell. As you pass through, an inscription on the left hand side (among decapitated heads) reads “The sea of bitterness has no horizon; turn back - the shore is there”, warning us to change our ways and live morally, because the alternative is unending suffering. An apt warning for the path to Hell.`,
          image: GarageAdd3,
        },
      ],
      observation: [
        {
          text: `Few people notice the elaborate roof structure atop this garage building, and the statue of Sakyamuni Buddha (释迦佛祖) seated before a traditional Chinese circular window.  Step back for a better view. For a level view, climb up the stairs to the Pond (C7) and look over the railings to see the Buddha more clearly.`,
        },
      ],
      zone: "b",
    },
  ],
  b2: [
    {
      images: [LawEnvoy],
      title: "Law-Defending Envoy 护法使者 & Ghost of Impermanence 无常鬼",
      sections: [
        {
          text: `This green-faced being is Hu Fa Shi Zhe 护法使者 (Law-Defending Envoy) aka Hu Fa Da Jiang Jun  护法大将军(Law-Defending General). He is a Hell official with power over ghosts and devils, and is tasked with the upholding of Buddhist laws. With weapons like his, it’s no wonder he makes a good enforcer.`,
        },
        {
          text: `Seated next to him is Wu Chang Gui 无常鬼 , the Ghost of Impermanence. Here, he represents the infamous Hell duo Black & White Impermanence – two Underworld guards tasked with escorting spirits to Hell. On his tall court hat are the words 一见大吉 (One glance brings great fortune), highlighting this deity’s popularity as a fortune-bringer. On his fan are the words 善恶有 报 (Good and Evil each have their repercussions)`,
        },
      ],
      zone: "b",
    },
    {
      images: [Bodhisattva],
      title: "Ksitigarbha Bodhisattva 地藏王",
      sections: [
        {
          text: `In the next alcove sits Ksitigarbha Bodhisattva  (Di Zang Wang 地藏王). His full name is  Bodhisattva King Earth-Matrix of the Great Vow (Da Yuan Di Zang Pu Sa大願地藏菩薩). The great vow refers to his pledge to take responsibility for the religious instruction of all beings in the 6 worlds, and his vow is shown on the red banner on the right hand wall.  It reads “As long as Hell is not yet empty (of souls), I vow not to achieve Buddhahood”. In honor of this vow, he is revered as the patron Bodhisattva of all beings in Hell. He is usually depicted riding his steed, the di ting 谛听 – a mythical beast with a tiger’s head, dog’s ears, dragon’s body, rhino’s horn and unicorn’s hooves. Here, however, he seems to be riding a large cat more akin to the Blue Tiger ridden by the Manjusri Bodhisattva.`,
        },
        {
          text: `Flanking him are his youthful attendants. The raised spear one of them wields once carried a banner (now missing) that read “Underworld Chief Ksitigarbha Bodhisattva”.`,
        },
        {
          text: `On Ksitigarbha’s right is another less-green depiction of Hu Fa Shi Zhe 护法使者 while the white-bearded old man on his left is the God of Earth (Tu Di Shen土地神), worshipped by most Chinese as the deity in charge of the ground we live upon. His robe is embroidered with the word “寿” shou (longevity), presumably since the earth is old. It is fitting that Ksitigarbha is depicted seated between the enduring Earth and the laws of Hell.`,
          image: BodhisattvaAdd,
        },
      ],
      zone: "b",
    },
    {
      images: [TigerShrine],
      title: "Tiger Shrine",
      sections: [
        {
          text: `On the opposite side of the path between the rocks, in a little cave,  you will find an old tiger statue wearing a cape. This is a real shrine, used to honor Lord Tiger  (Hu Ye 虎爷 ), a popular guardian spirit often seen guarding temples. He is also formally known as Tiger Spirit (Hu Shen虎神), the Tiger General  (Hu Jiang Jun 虎將軍) and in Taoist rituals, The General of the Lower Altar (Xia Tan Jiang Jun下壇將軍).`,
          image: TigerShrineAdd,
        },
      ],
      observation: [
        {
          text: `On a rock between this tableaux and the gates of Hell, an inscription reads :`,
        },
        {
          text: ``,
          ctext: `"Goodness has its rewards,
          Evil has its retribution.
          Don’t think there will be none –
          It just isn’t time yet.”`,
        },
      ],
      zone: "b",
    },
  ],
  b3: [
    {
      images: [TenHellCourts],
      title: "Ten Courts of Hell",
      sections: [
        {
          bold: `This is without a doubt the most notorious display in the Tiger Balm Gardens, and the one most indelibly burned into the memories of impressionable children. Originally, Haw Par’s Hell was a series of rock alcoves with sunlight filtering through iron bars and a flock of disinterested pelicans perched on top. It was located right next to a pavilion containing Sakyamuni Buddha, near a pond. (The pavilion and pond are still there at C11, and some black swans mark Hell’s previous location at C10.)`,
        },
        {
          bold: `During the Dragon World theme park days, the tableaux were relocated and rearranged in a cave with a waterway, turning it into a tunnel boat-ride. A giant dragon encased the structure, with visitors entering through its open mouth and emerging – well, you know where. Since the closure of the theme park, the dragon has been removed but its grey innards remain as a casing for the darkly atmospheric Ten Courts of Hell.`,
          image: TenHellCourtsAdd,
        },
        {
          bold: `Inside, the tableaux depict the Taoist vision of the Underworld (地狱) incorporated into the Buddhist belief in reincarnation. Upon death, each soul must be judged for its merits and assigned a higher or lower rebirth. Hell serves as a place in which souls can work off some of their bad karma and save themselves from too low a rebirth. For example, doing time in Hell can upgrade a soul from a lowly earthworm to a decent quadruped, allowing the soul to skip many cycles in the quest for ‘higher’ rebirths.`,
          text: `Like Heaven, the Chinese Hell is well-organized and strictly bureaucratic. The first court is judicial, the last is administrative, and the eight in between serve to punish specific misdeeds. Each court is presided over by a Yama King who acts as judge. His verdicts are carried out by motley misshapen attendants – slightly uglier than your usual civil servant.`,
        },
        {
          text: `Up in the mortal world, Chinese families perform countless rituals to expedite their ancestors’ souls’ journeys through Hell and earn them a shortcut to rebirth (or at least a more pleasant journey, with hell money to spend and paper luxuries to enjoy.)`,
        },
      ],
      zone: "b",
    },
    {
      images: [HellGate],
      title: "Hell Gate",
      sections: [
        {
          text: `The sign above the doors, reading right to left, is the traditional Taoist name of the hell-gate : 鬼门关 – “ghost border-gate”. This is the gate that opens during the lunar seventh month to let the spirits out for the Hungry Ghost Month.`,
        },
        {
          text: `Above this, reading left to right, is the name 十殿阎罗 - “Ten Courts of Yama”, referring to King Yama, who judges all the dead and runs the Underworld.`,
        },
        {
          text: `At the gate of Hell stand two bouncers – they are Ox-Head (Niu Tou 牛头) and Horse Face (Ma Mian马面) - the guardians of Hell who escort the soul through the gates (though their scope of work sometimes seems to overlap that of Black & White Impermanence – see B2.) These statues were added during Dragon World reconstruction – note how the sculptural style is similar to that used for action figurines!`,
        },
      ],
      zone: "b",
    },
    {
      images: [FirstCourt],
      title: "First Court",
      sections: [
        {
          text: `Upon entering Hell, souls are sorted according to their past deeds. Virtuous souls who deserve reward rather than punishment are offered a choice of two bridges – the Gold or the Silver. The Gold Bridge leads to the Pure Land presided over by Amitabha Buddha, where the soul can attain nirvana – utter release from samsara, the cycle of birth and rebirth. The Silver Bridge leads to the Taoist Heaven ruled by the Jade Emperor, where the soul will be reborn as a god and live a divine life (not eternally, but for thousands of years, which was the early Chinese notion of ‘immortality’.)`,
          image: FirstCourtAdd,
        },
        {
          text: `Those not eligible for either bridge were brought to the Mirror of Retribution  (Nie Jing Tai孽镜台) – shown in the next chamber. Here, they are forced to confront their misdeeds before receiving judgment. The inscription originally painted beside the mirror read : "This woman was wealthy while alive, but she was extremely miserly and never gave a cent to anyone. Who could tell that, upon her death, seeing herself in the Mirror of Hell, she saw that she had nothing."`,
        },
      ],
      observation: [
        {
          text: `The Tiger Balm Gardens in Hong Kong featured a version of Hell that was simply a long wall of shallow bas reliefs. It was only here in Singapore that Boon Haw allowed his hellish fantasies to take form in glorious 3D. Enjoy!`,
        },
      ],
      zone: "b",
    },
    {
      images: [SecNineCourt],
      title: "Second to Ninth Court",
      sections: [
        {
          text: `The purviews and punishments of Courts Two to Nine are well-catalogued in the tableaux, so take your time to explore!  Each punishment was originally accompanied by strongly-worded admonishments painted in red to give visitors the maximum moral impact, just the way Aw Boon Haw would have liked it. These stern reminders have since been removed, so it’s up to the visitor to derive his or her own lessons.`,
        },
        {
          text: `There are a few Hell landmarks that are worth extra mention...`,
        },
      ],
      zone: "b",
    },
    {
      images: [FourthCourt],
      title: "Wang Si Cheng  枉死城 – Fourth Court",
      sections: [
        {
          bold: `On the right side of the Fourth Court stands a fortified city with some sad souls standing at the parapet. This is Wang Si Cheng 枉死城    -  City of Wronged Deaths. This walled city has two possible purposes. The Dragon World theme park description explains that it is a place where people who were wrongfully driven to their death by others (ahead of their rightful time) are held in limbo until the correct lifespan is reached, before being released into the cycle of rebirth. While they wait, they watch the punishment being meted out to those who wronged them.`,
        },
        {
          bold: `However, previous inscriptions that were painted on the wall behind this structure (possibly dating back to the 50s when this Hell was first built) describe a very different function -  a prison in which are incarcerated the souls of those who committed suicide. The blood-red writing warned that souls who did not have the courage to persevere in their human life did not deserve to be granted another life – so they were condemned to remain here for tens of thousands of years with no chance to reincarnate.`,
        },
        {
          text: `This latter belief has led to many Chinese stories of ghosts of suicides trying to get unsuspecting humans to replace them by luring them to an identical death, thus hopefully freeing the soul for reincarnation.`,
        },
      ],
      zone: "b",
    },
    {
      images: [FifthCourt],
      title: "Wang Xiang Tai  望乡台 – Fifth Court",
      sections: [
        {
          text: `High above the Fifth Court, we see more sad souls standing on a balcony, looking into the distance. This platform is the Wang Xiang Tai 望乡台 – Viewing Home Platform. From here, souls are able to see the homes they left behind and witness how their loved ones are suffering as a result of their misdeeds.`,
        },
      ],
      observation: [
        {
          text: `As you walk through Hell, keep your eyes peeled for quirky details, such as the giant wok of boiling oil under the bridge, and the dismembered body parts hanging in the tree overhead.`,
        },
      ],
      zone: "b",
    },
    {
      images: [TenthCourt],
      title: "Meng Po’s Pavilion - Tenth Court",
      sections: [
        {
          text: `The punishments over, the soul arrives in the Tenth Court, where King Zhuan Lun 转轮王 (literally ‘King of the Turning Wheel’) assigns one’s path of rebirth. In the tableau, the attendant’s document states that the kneeling man will be reborn as a black goat, and the woman as a white rabbit. Their previous life must have been full of wrongdoing to exclude them from the human path!`,
          image: TenthCourtAdd,
        },
        {
          text: `With the path set, the soul proceeds to meet the kindly old lady Meng Po 孟婆 at her pavilion, where she offers one a cup of her Tea of Forgetting. Drinking this will erase one’s memories of past lives, allowing fresh rebirth without the baggage.`,
        },
      ],
      zone: "b",
    },
    {
      images: [SamsaraSix],
      title: "Samsara : The Six Paths of Reincarnation",
      sections: [
        {
          text: `The last thing you’ll see in Haw Par’s hell is the depiction of the Six Paths of Rebirth available to the soul who has completed his or her time in hell.  Traditionally, the paths are :`,
        },
        {
          text: `⦁	Rebirth as pretas (hungry ghosts) characterized by great craving and eternal starvation`,
        },
        {
          text: `⦁	Rebirth as animals characterized by stupidity and servitude`,
        },
        {
          text: `⦁	Rebirth as asuras (demigods consumed by anger, jealousy, and constant war)`,
        },
        {
          text: `⦁	Rebirth as a human, with enlightenment within our grasp but blinded by desires`,
        },
        {
          text: `⦁	Rebirth as devas (heavenly beings filled with pleasure, who can live for millennia)`,
        },
        {
          text: `⦁	Rebirth as a hell-being, wracked by torture and characterized by aggression`,
        },
        {
          text: `The original 1950s version showed seven paths, but this was reduced to six in the Dragon World re-sculpting.`,
        },
      ],
      zone: "b",
    },
  ],
  b4: [
    {
      images: [CricketFight],
      title: "Pair of Crickets Fighting",
      sections: [
        {
          text: `Cricket fighting began as a sport for Chinese emperors in the Tang dynasty (618–907 AD), but it soon became popular throughout China. Prized fighting crickets were even given funerals.`,
        },
        {
          text: `In this tableau built in the late 1940s, the spectators (wittily portrayed as animals) seem to be violently obsessed with the match – goading the crickets with rifles and pointing pistols. Perhaps Boon Haw was hinting at the pastime as an unhealthy social obsession. In the Southern Song dynasty (1127-1279 AD), the chancellor Jia Si Dao賈似道 was obsessed with the sport and many claim his obsession contributed to the downfall of the dynasty. Boon Haw may have been right – two decades after this tableau was built, the Cultural Revolution (1966-1976) condemned the sport as a bourgeois pastime and banned it.`,
        },
        {
          text: `Today, the sport has been revived by generations keen on recapturing traditional Chinese pastimes (and gambling). There are regional and national matches throughout China. Shanghai alone boasts over a dozen cricket markets and as recently as 2010, over 400 million yuan (US $63 million) was spent on crickets.`,
        },
      ],
      observation: [
        {
          text: `While the Gardens are full of animals both real and mythical, Aw Boon Haw also seemed to enjoy using anthropomorphized animals to depict human foibles. Look out for more such man-imals appearing in socially satirical tableaux.`,
        },
      ],
      zone: "b",
    },
  ],
  b5: [
    {
      images: [FilialPiety],
      title: "Two Paragons of Filial Piety - Wang Xiang and Lady Tang",
      sections: [
        {
          bold: `This pair of tableaux from the early 1950s illustrates two tales from “The Twenty-Four Paragons of Filial Piety” (二十四孝) - a classic text by the Yuan dynasty (1260-1368) scholar Guo Ju Jing 郭居敬 compiling 24 exemplary tales of Confucian filial piety. A major aspect of Chinese culture, true filial piety requires that one’s elders be respected over the self and over one’s own progeny.`,
          image: FilialPietyAdd1,
        },
        {
          bold: `In the first tale 乳姑不怠 (Never Tiring to Breastfeed Auntie), Lady Tang ensures that her aged and toothless mother-in-law Grand Dame Zhang Sun is well nourished by breastfeeding her daily. Her own baby lies neglected as she performs her filial duty before her maternal one. Subsequently, Madam Zhang Sun would summon all her family members to her deathbed and make them promise to treat Madam Tang as kindly as she deserves.`,
          image: FilialPietyAdd2,
        },
        {
          image: FilialPietyAdd3,
        },
        {
          bold: `In the second tale 卧冰求鲤 (Lying on Ice to Obtain Carp), Wang Xiang王祥 is a filial son who goes to the lake to seek carp to satisfy his stepmother’s craving.  In midwinter, the lake is frozen over. In frustration and desperation, he strips and lays upon the ice, letting his body heat and tears crack the ice. Two carp leap out from the crack and he brings them home.  The stepmother, who had ill-treated the lad up till this point, also had her heart melted by his filial gesture and treasured him henceforth. In this tableau, Heavenly Secretarial Minister Tai Bai Jin Xing 太白金星 is seen endorsing the boy’s actions.`,
          text: `As a hint to the tableaux’ background, the book “The Complete 24 Pieties (of old)” (古时二十四孝全卷) rests on the bedside table in Madam Tang’s room. The craftsman seems to have also cross-referenced other tales from the book – the little boy bearing the mandarin orange might refer to the 6-year old Lu Ji who stole oranges for his mother in 怀橘遗亲 (Hiding Mandarin Oranges for One’s Kin).`,
        },
      ],
      observation: [
        {
          text: `Lady Tang and her mother-in-law are undoubtedly the most misunderstood and misinterpreted statues in the Gardens.  Seen out of context, the tableau certainly suggests some kinky Oriental ritual.`,
        },
      ],
      zone: "b",
    },
  ],
  b6: [
    {
      images: [OxenDuel],
      title: "Dueling Oxen",
      sections: [
        {
          text: `This pair of oxen are engaged in a violent duel. This simple but powerful pair was crafted in the early 1950s by Guo Yun Shan and his skill is apparent in the tensed musculature and folds of skin. They once stood along the main driveway near the Garden’s entrance.`,
        },
      ],
      zone: "b",
    },
    {
      images: [FarmerWife],
      title: "Farmer’s Wife",
      sections: [
        {
          text: `One small element missing from this tableau has rendered it puzzling to many visitors for years. The peasant woman has clearly fallen down, and the farmer and child are coming to her aid. Is it simply a reminder to be a Good Samaritan and lend a helping hand? Yes and no. The farmer’s outstretched left hand used to hold a bottle of Tiger Balm, making this tableau a 3D advertisement for the product! Appropriately, this tableau used to stand near the entrance to the Gardens, and a painted quatrain in the background (now gone) used to explain how the medicine cured the woman of her injuries.`,
          image: FarmerWifeAdd,
        },
      ],
      zone: "b",
    },
  ],
  b7: [
    {
      images: [Village],
      title: "Village",
      sections: [
        {
          bold: `On the left, we see a diorama depicting a thriving village, demonstrating how villagers are working harmoniously to cultivate the land, watched over by benign deities and happy elders. The title of the diorama is beside the old lady’s house : “农家乐” (“Happy Farmers”).`,
        },
        {
          ctext: `The couplet on the pillars of the small temple (added in the 90s) reminds us that :`,
        },
        {
          ctext: `“The prosperous yet virtuous -  thousands of families give him offerings; The fair and impartial – tens of thousands of families respect him.”`,
        },
      ],
      observation: [
        {
          text: `Observe the high level of detail – from the mini-idol and joss sticks in the shrine to the thatched roof and windows of the old woman’s house.`,
        },
      ],
      zone: "b",
    },
  ],
  b8: [
    {
      images: [RockForm],
      title: "Rock formation with 18 tableaux",
      sections: [
        {
          text: `This cylindrical structure previously housed the changing rooms of the swimming pool and was  located a few terraces up, next to the memorial to the Aw brother’s parents (E3). Aw Boon Haw gave the craftsmen a free hand to decorate this structure with a mix of tableaux – literary, legendary or simply zoological. The whole structure was moved down here during Dragon World days but the boat-ride made close-up inspection of the tableaus impossible. Now you can get up close and personal, as Aw intended.`,
          image: RockFormAdd1,
        },
        {
          text: `Starting with the scenes facing the fork in the path and travelling anti-clockwise round the structure, the tableaux are as follows :`,
          image: RockFormAdd2,
        },
        {
          text: `1. (top)  Two of the Eight Immortals – bare-bellied Zhong Li Quan 钟离权 and grumpy Li Tie Guai 李铁拐 are resting in front of the entrance to their heavenly hang-out Peng Lai Cave 篷来洞 (observe the swirling clouds around the archway). Li is digging his ear while waiting for his magical gourd to boil. Zhong’s magic fan used to hang from his left hand but has since gone missing.`,
        },
        {
          text: `2. (bottom) 毒蛇洞 (Venomous Snake Cave) where two lizards and a snake are hanging out.`,
          image: RockFormAdd3,
        },
        {
          image: RockFormAdd4,
        },
        {
          text: `3. (top) A teaser for the Madam White snake legend, showing the Madam at the Golden Mountain Monastery ( 金山寺 ), dueling with an attendant while the monk Fa Hai looks on.`,
        },
        {
          text: `4. (bottom) The monkey here was once attempting to manipulate the ox with a rope tied to a ring in its nose. The rope is gone but the lesson remains – the inscription in the background tells us that “gorillas are well-versed in matters of farming”.`,
          image: RockFormAdd5,
        },
        {
          image: RockFormAdd6,
        },
        {
          text: `5. (top) This tableau’s content is now unclear, but one of the red pillars used to bear the inscription三娘挑经 (San Niang Picks Scriptures), referring to a story from the Teochew opera repertoire.  The long pole carried over the female figure’s shoulder once had a scroll and some books dangling from it, which suits the title.`,
        },
        {
          text: `6. (bottom) Monkeys and rats battling. Context unknown. The monkey on the bridge used to be pulling the tail of a rat trying to escape into the river. (Observe the mating rats in the upper right corner!)`,
          image: RockFormAdd7,
        },
        {
          image: RockFormAdd8,
        },
        {
          text: `7. (top) This tableau is entitled “Liu Shu Chun’s Second Fight” (柳树春二打擂台) and depicts legendary martial arts expert Yang Qing (fully clothed) defeating the bearded Liu.  Based on a scene in the novel 月唐演义 (Yue Tang Yan Yi), the original antagonist depicted in the novel was Li Hu Shen, but Aw Boon Haw did not relish sharing the Hu name with the losing side, and the character was renamed.`,
        },
        {
          text: `8. (bottom) A boy is trying to catch a fish but is confronted by a snake while a frog watches. A possible interpretation is that even something as weak as a fish has its protectors. Or simply that cold-blooded things stick together.`,
          image: RockFormAdd9,
        },
        {
          image: RockFormAdd10,
        },
        {
          text: `9. (top) Short man in Qing dynasty garb approaching a young lady. Context unclear.`,
        },
        {
          text: `10. (bottom) Hippopotamus and walrus next to waterfall.`,
          image: RockFormAdd11,
        },
        {
          image: RockFormAdd12,
        },
        {
          text: `11. (top) This tableau is titled 贵妃醉酒 – Gui Fei is Drunk. Yang Gui Fei is one of the Four Beauties of Ancient China and the most beloved consort of the Tang dynasty Emperor Xuanzong. It is recorded that whenever the Emperor dutifully spent nights with his first wife the Empress, she would grow lonely and get drunk, causing trouble in the palace. This tableau shows a tipsy Yang in the imperial garden with an empty goblet and an anxious eunuch nearby. Could he perhaps be offering her Tiger Balm?`,
        },
        {
          text: `12. (bottom) The two animals depicted here are somewhat mysterious. One appears to be an anteater–aardvark hybrid, while the one crouched behind the rocks seems like a long-eared hippopotamus.`,
          image: RockFormAdd13,
        },
        {
          image: RockFormAdd14,
        },
        {
          text: `13. (top)  Sakyamuni Buddha seated on a lotus, flanked by unidentified stylized mythical beasts. Observe the detail on the painted room behind the Buddha and the fig leaves carved overhead.`,
        },
        {
          text: `14. (bottom) Pair of large rabbits and a pig in front of a house. Context unclear.`,
          image: RockFormAdd15,
        },
        {
          image: RockFormAdd16,
        },
        {
          text: `15. (top) A woman is tending to the man’s injured foot with a needle – perhaps to remove a splinter. He is either wincing or winking at us.`,
        },
        {
          text: `The idiom on the rock (right) explains the tableau : “Virtuous daughter-in-law respects her father-in-law”.  The framed idiom by the door speaks of “being a helpful person who enjoys doing good deeds”.`,
        },
        {
          text: `Apart from this moral imperative, this tableau also reflects the Chinese love for nature. The inscription above the doorway says “Hidden Garden”, and the couplet reads :  “Living reclusively in the forest, enjoying tranquil prosperity; trees in the garden bloom for you.”`,
        },
        {
          text: `16. (bottom) Apparently, the elephant seen here was an objet d’art that Aw Boon Haw brought from Burma. It survived the war, and he had it incorporated into the tableau. It faces a lion sculpture that represents Singapore. The sign over the archway reads 狮子城– the Lion City.  Perhaps this tableau commemorates the Aw family’s journey from Rangoon to Singapore.`,
          image: RockFormAdd17,
        },
        {
          image: RockFormAdd18,
        },
        {
          text: `17. (top) A young courting couple is being spied upon by a gleeful boy and a disapproving older couple. The man is about to throw a stone at the couple while his wife points them out to us. The tableau’s title  洋梅记 might give us a clue to its original context.`,
        },
        {
          text: `18. (bottom) Pair of water birds.`,
          image: RockFormAdd19,
        },
      ],
      zone: "b",
    },
  ],
  b9: [
    {
      images: [RatsRabbits],
      title: "Battle of the Rats & Rabbits",
      sections: [
        {
          text: `This tableau depicts a violent battle between white rabbits and black rats. The battle broke out over a black rat who was welcomed into the rabbit community but seduced the wife of a rabbit and eloped with her. It was apparently intended by Aw Boon Haw as an illustration of the dangers of letting outsiders get too entrenched in one’s community. How the guinea pigs got involved in not clear.`,
        },
        {
          text: `The rabbit on a throne at the very top wields a fan declaring him king (王). The guinea pig just below the throne once held a book in his left hand with the title 圣音 (‘holy voice’ or ‘holy sound’) and was positioned right in front of the king, suggesting  a religious context that is lost to us.`,
        },
        {
          text: `This tableau was constructed in the 1950s and used to stand at the top of the hill near the Villa’s north entrance.`,
        },
      ],
      observation: [
        {
          text: `Look out for the horrific casualties depicted, as well as the witty touches like the Red Cross armbands. Some of the groups on the lower right side seem to be cuddling rather than fighting. Perhaps there is love amid war.`,
        },
      ],
      zone: "b",
    },
    {
      images: [WhiteSnakePav],
      title: "White Snake pavilion",
      sections: [
        {
          text: `To the right of this mountainous battlefield is a small pavilion with two maidens advertising the Legend of the White Snake (白蛇专). The cruder style of the sculpting reveals that this was a much later addition – quite possibly during the Dragon World days. A teaser for the tableaux at A12 and C8 perhaps.`,
        },
      ],
      zone: "b",
    },
  ],
};

export default ZoneBMapping;
