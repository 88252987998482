import FloatingActionButton from "../components/FloatingActionButton";

import ZoneEMapWithIcons from "../assets/maps/ZoneEMapWithIcons.jpg";

import HotSpot from "../components/HotSpot";

export default function ZoneE() {
  return (
    <div style={{ position: "relative" }}>
      <img src={ZoneEMapWithIcons} width="100%" />
      <HotSpot left={57} top={28} path="/entry/e1" backgroundColor="green" />
      <HotSpot left={68} top={48.5} path="/entry/e2" backgroundColor="green" />
      <HotSpot left={18} top={33} path="/entry/e3" backgroundColor="green" />
      <HotSpot left={12} top={20} path="/entry/e4" backgroundColor="green" />
      <HotSpot left={26} top={20} path="/entry/e5" backgroundColor="green" />
      <HotSpot
        left={93.5}
        top={54.5}
        path="/entry/e6"
        backgroundColor="green"
      />
      <FloatingActionButton path="/map" />
    </div>
  );
}
