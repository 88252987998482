import ResponsiveDrawer from "./components/ResponsiveDrawer";
import { Navigate, Outlet } from "react-router-dom";
import { AuthState } from "./redux/features/auth/authSlice";

interface Props {
  auth: AuthState;
}

export default function PrivateRoute({ auth }: Props) {
  const isAuthenticated = auth.value.length > 0;

  return (
    <ResponsiveDrawer>
      <Outlet />
    </ResponsiveDrawer>
  );

  // return isAuthenticated ? (
  //   <ResponsiveDrawer>
  //     <Outlet />
  //   </ResponsiveDrawer>
  // ) : (
  //   <Navigate to="/login" />
  // );
}
