import FloatingActionButton from "../components/FloatingActionButton";

import ZoneCWithIcons from "../assets/maps/ZoneCWithIcons.jpg";

import HotSpot from "../components/HotSpot";

export default function ZoneC() {
  return (
    <div style={{ position: "relative" }}>
      <img src={ZoneCWithIcons} width="100%" />
      <HotSpot left={8} top={5.5} path="/entry/c1" backgroundColor="green" />
      <HotSpot left={27.5} top={24} path="/entry/c2" backgroundColor="green" />
      <HotSpot left={54} top={9.5} path="/entry/c3" backgroundColor="green" />
      <HotSpot left={53} top={15.5} path="/entry/c4" backgroundColor="green" />
      <HotSpot left={45} top={29} path="/entry/c5" backgroundColor="green" />
      <HotSpot
        left={24.5}
        top={37.6}
        path="/entry/c6"
        backgroundColor="green"
      />
      <HotSpot
        left={30.5}
        top={34.5}
        path="/entry/c7"
        backgroundColor="green"
      />
      <HotSpot left={31.5} top={44} path="/entry/c8" backgroundColor="green" />
      <HotSpot left={28.5} top={54} path="/entry/c9" backgroundColor="green" />
      <HotSpot left={16} top={54} path="/entry/c10" backgroundColor="green" />
      <HotSpot
        left={41.3}
        top={67.5}
        path="/entry/c11"
        backgroundColor="green"
      />
      <HotSpot left={56} top={80.5} path="/entry/c12" backgroundColor="green" />
      <HotSpot left={85} top={83.3} path="/entry/c13" backgroundColor="green" />
      <HotSpot left={74} top={63} path="/entry/c14" backgroundColor="green" />
      <HotSpot left={59} top={51} path="/entry/c15" backgroundColor="green" />
      <FloatingActionButton path="/map" />
    </div>
  );
}
