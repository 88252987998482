import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

import buttonCancel from "../assets/icons/buttonCancel.png";

interface Path {
  path: string;
}

export default function FloatingActionButton(props: Path) {
  const { path } = props;

  let goto: string;
  // Remove after Home Page is back
  if (path === "/map") {
    goto = "/";
  } else {
    goto = path;
  }

  return (
    <Box sx={{ position: "fixed", right: "0px", top: "30px" }}>
      <Link to={`${goto}`}>
        <img src={buttonCancel} width="50px" />
      </Link>
    </Box>
  );
}
