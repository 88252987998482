import FloatingActionButton from "../components/FloatingActionButton";

import ZoneDMapWithIcons from "../assets/maps/ZoneDMapWithIcons.jpg";

import HotSpot from "../components/HotSpot";

export default function ZoneD() {
  return (
    <div style={{ position: "relative" }}>
      <img src={ZoneDMapWithIcons} width="100%" />
      <HotSpot left={11} top={54} path="/entry/d1" backgroundColor="green" />
      <HotSpot left={18} top={60} path="/entry/d2" backgroundColor="green" />
      <HotSpot left={19} top={46} path="/entry/d3" backgroundColor="green" />
      <HotSpot left={33} top={47} path="/entry/d4" backgroundColor="green" />
      <HotSpot left={43} top={46} path="/entry/d5" backgroundColor="green" />
      <HotSpot left={70} top={45} path="/entry/d6" backgroundColor="green" />
      <HotSpot
        left={79.5}
        top={51.5}
        path="/entry/d7"
        backgroundColor="green"
      />
      <HotSpot left={71} top={62.5} path="/entry/d8" backgroundColor="green" />
      <HotSpot left={51} top={70} path="/entry/d9" backgroundColor="green" />
      <HotSpot left={56} top={56} path="/entry/d10" backgroundColor="green" />
      <FloatingActionButton path="/map" />
    </div>
  );
}
