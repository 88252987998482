import ZoneButton from "../components/ZoneButton";

import OverallMap from "../assets/maps/OverallMap.jpg";

import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import buttonCancel from "../assets/icons/buttonCancel.png";

export default function MainMap() {
  return (
    <div
      style={{
        position: "absolute",
        background: "#C4BEB0",
        minHeight: "100vh",
      }}
    >
      <Box
        style={{
          zIndex: 1,
          background: "#c4beb0",
          width: "100%",
          padding: "8px 8px",
          top: "70px",
        }}
      >
        <Typography variant="h5">
          <Box
            sx={{
              fontWeight: "bold",
              fontFamily: "Oswald",
              letterSpacing: "0.5px",
            }}
          >
            HAW PAR VILLA MAP
          </Box>
        </Typography>
        <Typography>a #hawparviva project</Typography>
      </Box>
      <div style={{ position: "relative", height: "100%" }}>
        <img src={OverallMap} width="100%" />
        <ZoneButton
          left={8}
          top={82}
          path="/zone/a"
          color="#218056"
          text="Zone A"
        />
        <ZoneButton
          left={70}
          top={68}
          path="/zone/b"
          color="#ad2d2d"
          text="Zone B"
        />
        <ZoneButton
          left={51}
          top={53}
          path="/zone/c"
          color="#d4cd59"
          text="Zone C"
        />
        <ZoneButton
          left={2}
          top={28}
          path="/zone/d"
          color="#218056"
          text="Zone D"
        />
        <ZoneButton
          left={18}
          top={8}
          path="/zone/F"
          color="#d4cd59"
          text="Zone F"
        />
        <ZoneButton
          left={75}
          top={14}
          path="/zone/g"
          color="#d459b9"
          text="Zone G"
        />
        <ZoneButton
          left={74}
          top={46}
          path="/zone/e"
          color="#59bbd4"
          text="Zone E"
        />
      </div>
      <Box sx={{ position: "fixed", right: "0px", top: "30px" }}>
        <a href="https://hawparviva.com">
          <img src={buttonCancel} width="50px" />
        </a>
      </Box>
    </div>
  );
}
