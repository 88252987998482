import _ from "lodash";
import { useParams } from "react-router-dom";

import { entryMap, Entry } from "../content/entryMap";

import ZoneAMapping from "../content/ZoneAMapping";
import ZoneBMapping from "../content/ZoneBMapping";
import ZoneCMapping from "../content/ZoneCMapping";
import ZoneDMapping from "../content/ZoneDMapping";
import ZoneEMapping from "../content/ZoneEMapping";
import ZoneFMapping from "../content/ZoneFMapping";
import ZoneGMapping from "../content/ZoneGMapping";

import { Button, Box } from "@mui/material";

import StatueContentDisplay from "./StatueContentDisplay";
import FloatingActionButton from "./FloatingActionButton";

import EntryBackground from "../assets/illustrations/EntryBackground.jpg";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function EntryPage() {
  const params = useParams();
  const entryId = _.get(params, "entryId", "404");

  const entryZone = entryId[0];
  let entry: Entry;
  if (entryZone === "a") {
    entry = ZoneAMapping[entryId];
  } else if (entryZone === "b") {
    entry = ZoneBMapping[entryId];
  } else if (entryZone === "c") {
    entry = ZoneCMapping[entryId];
  } else if (entryZone === "d") {
    entry = ZoneDMapping[entryId];
  } else if (entryZone === "e") {
    entry = ZoneEMapping[entryId];
  } else if (entryZone === "f") {
    entry = ZoneFMapping[entryId];
  } else if (entryZone === "g") {
    entry = ZoneGMapping[entryId];
  } else {
    entry = entryMap[entryId];
  }

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <Box
      style={{
        position: "relative",
        background: `url(${EntryBackground})`,
        paddingBottom: "16px",
        minHeight: "100%",
      }}
    >
      {entry[0].commonInfoText && (
        <Box p={2}>
          {/* <Typography variant="h5">
            <Box
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                fontFamily: "Oswald",
              }}
            >
              Common Info
            </Box>
          </Typography> */}
          {entry[0].commonInfoText.map((text) => (
            <Box mt={1}>
              <Typography>{text}</Typography>
            </Box>
          ))}
        </Box>
      )}
      {entry.length > 1 ? (
        entry.map((statueContent) => (
          <Accordion
            style={{
              position: "relative",
              background: `url(${EntryBackground})`,
              padding: "16px 0px",
              minHeight: "100%",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{
                flexDirection: "row-reverse",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" align="left">
                  <Box
                    mb={1}
                    mt={1}
                    ml={1}
                    sx={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      fontFamily: "Oswald",
                    }}
                  >
                    {`${entryId}: ${statueContent.title}`}
                  </Box>
                </Typography>
                <img
                  style={{
                    height: "75px",
                    width: "auto",
                    borderRadius: "2000px",
                  }}
                  src={statueContent.images[0]}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <StatueContentDisplay
                singleImage={false}
                statueContent={statueContent}
              />
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <StatueContentDisplay singleImage={true} statueContent={entry[0]} />
      )}
      <Button sx={{ marginTop: "8px" }} onClick={scrollToTop}>
        Back to top
      </Button>
      <FloatingActionButton path={`/zone/${entry[0].zone}`} />
    </Box>
  );
}
