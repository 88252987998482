import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

import db from "../../../firebase/firestore";

export interface AnsweredQuizzesState {
  [quizId: string]: boolean;
}

// db.collection("answeredQuestions").

const initialState: AnsweredQuizzesState = {};

export const answeredQuizzesSlice = createSlice({
  name: "answeredQuizzes",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    answerQuiz: (state, action: PayloadAction<string>) => {
      state[action.payload] = true;
    },
    clearAnsweredQuizzes: () => initialState,
  },
});

export const { answerQuiz, clearAnsweredQuizzes } =
  answeredQuizzesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAnsweredQuizzes = (state: RootState) =>
  state.answeredQuizzes;

export default answeredQuizzesSlice.reducer;
