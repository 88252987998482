import { useState } from "react";
import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ObservationOpen from "../../assets/icons/ObservationOpen.png";
import ObservationClose from "../../assets/icons/ObservationClose.png";

interface ObservationButtonProps {
  observation: {
    text: string;
    ctext?: string;
    image?: string;
  }[];
}

export default function ObservationButton(props: ObservationButtonProps) {
  const observationArray = props.observation;

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "16px",
      }}
    >
      <Accordion onClick={toggleOpen}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <img
            style={{ marginRight: "16px", height: "25px" }}
            src={open ? ObservationOpen : ObservationClose}
          />
          <Typography>
            <Box
              sx={{
                fontWeight: "600",
              }}
            >
              Haw Par Observation!
            </Box>
          </Typography>
        </AccordionSummary>
        {observationArray.map((observe) => (
          <AccordionDetails>
            {observe.image && (
              <img
                style={{ width: "100%", marginBottom: "16px" }}
                src={observe.image}
              />
            )}
            <Typography align="left">{observe.text}</Typography>
            <Typography align="center">{observe.ctext}</Typography>
          </AccordionDetails>
        ))}
      </Accordion>
    </div>
  );
}
