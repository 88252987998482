import styled from "@emotion/styled";
import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

interface ZoneButtonProps {
  left: number;
  top: number;
  path: string;
  color: string;
  text: string;
}

export default function ZoneButton(props: ZoneButtonProps) {
  const { left, top, path, color, text } = props;
  return (
    <Link to={path}>
      <Typography
        variant="h6"
        style={{
          position: "absolute",
          left: `${left}%`,
          top: `${top}%`,
          cursor: "pointer",
          color,
        }}
      >
        <Box
          sx={{
            fontWeight: "bold",
            textTransform: "uppercase",
            fontFamily: "Oswald",
            padding: "8px 12px",
            backgroundColor: "#00000099",
            borderRadius: "10px",
          }}
        >
          {text}
        </Box>
      </Typography>
    </Link>
  );
}
