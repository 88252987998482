import Tripitaka from "../assets/photos/zone_e/compressed/e1_main2a.jpg";
import Pigsy from "../assets/photos/zone_e/compressed/e1_main3a.jpg";
import Kumoko from "../assets/photos/zone_e/compressed/e1_main4a.jpg";
import MonkeyBattle from "../assets/photos/zone_e/compressed/e1_main5a.jpg";
import Sandy from "../assets/photos/zone_e/compressed/e1_main6a.jpg";
import TripitakaHorse from "../assets/photos/zone_e/compressed/e1_main7a.jpg";
import KumokoTrap from "../assets/photos/zone_e/compressed/e1_main9a.jpg";
import SowYoung from "../assets/photos/zone_e/compressed/e1_main10a.jpg";
import KumokoAdd from "../assets/photos/zone_e/compressed/e1_extra4a.jpg";
import CypressAdd1 from "../assets/photos/zone_e/compressed/e1_extra8a.jpg";
import CypressAdd2 from "../assets/photos/zone_e/compressed/e1_extra8b.jpg";
import CypressAdd3 from "../assets/photos/zone_e/compressed/e1_extra8c.jpg";

import Monastery from "../assets/photos/zone_e/compressed/e2_main1a.jpg";
import MonasteryAdd from "../assets/photos/zone_e/compressed/e2_extra1a.jpg";

import AwChuKim from "../assets/photos/zone_e/compressed/e3_main1a.jpg";
import TigerPair from "../assets/photos/zone_e/compressed/e3_main2a.jpg";
import LeopardPair from "../assets/photos/zone_e/compressed/e3_main2b.jpg";

import Pavilion from "../assets/photos/zone_e/compressed/e4_main1a.jpg";

import WuSongKill from "../assets/photos/zone_e/compressed/e5_main1a.jpg";

import SongZi from "../assets/photos/zone_e/e6_main1a.jpg";

import { EntryMap } from "../content/entryMap";

const ZoneEMapping: EntryMap = {
  // Zone E Entries
  e1: [
    {
      images: [],
      title: "Journey to the West",
      sections: [
        {
          text: `Probably the best known of China’s Four Great Classical Novels, Journey to the West 西游记 is loosely based on the actual travels of the Tang Dynasty Buddhist monk Xuan Zang 玄奘 (602–664 AD). He undertook a seventeen-year overland journey to India to bring Buddhist scriptures back to China and have them translated properly from Sanskrit into Chinese, thus deepening China’s understanding of Buddhist philosophy.`,
        },
        {
          text: `Published around 1592 and attributed to Wu Cheng En, Journey to the West is made up of 100 chapters, many of which tell of encounters between a fellowship of squabbling travelers on a mission, and the malevolent forces attempting to stop them. Think Lord of the Rings in ancient Chinese.`,
        },
        {
          text: `Aw Boon Haw’s first attempt at telling this story was in the Hong Kong Gardens. (Sadly, that version was demolished in 1971.) Here in Singapore, he set out to improve on the idea. After WWII, with the Villa demolished and the whole site given dedicated to entertaining and enlightening the public, Aw commissioned his artisans Tan See Hua and Kwok Chun San to collaborate on a massive tableau that captures 3 of the 81 tribulations Tripitaka overcame on his travels. Like traditional Chinese writing (and Japanese manga), this tableau is read from right to left.`,
        },
      ],
      zone: "e",
    },
    {
      images: [Tripitaka],
      title: "Tripitaka and his horse",
      sections: [
        {
          text: `We begin by meeting our protagonist – Xuan Zang, on his way to the Western Regions (ie. India) to obtain scriptures on the Goddess of Mercy’s orders. The words painted on the tree beside the horse read : “the Monk Tang, in obedience of Imperial order, goes West to obtain the true scriptures to bring salvation to the masses.”`,
        },
        {
          text: `He is often called Tang Sanzang 唐三藏 because he lived in the Tang Dynasty 唐朝 and he was seeking the San Zang Jing 三藏经 (Three Collections of Scriptures). In Sanskrit, the collection was called the Tripitaka, giving us Xuan Zang’s other common name.  At the end of this arduous journey, the devout Tripitaka will be rewarded by transformation into the ‘Buddha of Precocious Merit’.`,
        },
        {
          text: `Here we see him riding a white horse who is actually the third son of a Dragon King, transformed into a horse by the Goddess of Mercy to help Tripitaka on his quest. Why? Some versions say because he was unfilial, others because ate the original white horse. Eventually, he will be rewarded for serving Tripitaka by becoming one of the Eight senior Heavenly Dragons.`,
        },
      ],
      zone: "e",
    },
    {
      images: [Pigsy],
      title: "Pigsy",
      sections: [
        {
          text: `On the bridge ahead of the monk is Pigsy. He was previously a heavenly marshall named Tian Peng Yuan Shuai 天蓬元帅 (The Marshall of the Sky’s Canopy) who was punished for sexually harassing Chang‘E the Goddess of the Moon. He was exiled from heaven and sent to earth to live as a man-eating pig monster, until the Goddess of Mercy offered him a chance at redemption through discipleship to Tripitaka. She gave him a new name - Zhu Wu Neng  豬悟能, which means ‘pig aware of his abilities’. Later, Tripitaka gave him the nickname Zhu Ba Jie 豬八戒 (‘pig of eight abstinences’) to remind him of his goal  - to conquer all his baser instincts such as laziness, gluttony and lustfulness.`,
        },
        {
          text: `Pigsy is only half as skilled as Monkey, boasting only 36 Magical Transformations to Monkey’s 72. At the end of the Journey, Pigsy will be given the title of ‘Cleanser of the Altars’, so he can forever help himself to leftover offerings from every Buddhist altar in the world!`,
        },
        {
          text: `Here, he is cavorting playfully, carrying his iconic weapon - the Nine-Toothed Rake 九齿钉耙, which is his only souvenir from his glory days.`,
        },
      ],
      zone: "e",
    },
    {
      images: [Kumoko],
      title: "The Spider Women",
      sections: [
        {
          text: `Like Madam White Snake, these luscious beauties are spiders who, through Taoist magic, have learned how to take human form. There are seven of them in the story. Like many of the monsters in Journey, they want to eat Tripitaka’s flesh because it is supposed to bring immortality to the consumer.`,
        },
        {
          text: `At this point in the Journey, Tripitaka has just sent Monkey away for having unnecessarily (in his opinion) slain the White-Bone Demon in their last adventure. Without Monkey’s savvy, Tripitaka and the other two disciples foolishly ask for food from the women. The women capture the monk and his companions, and after storing their captives away, five of the Spider Women decide to celebrate the success of their scheme with a skinny-dip!`,
        },
        {
          text: `Soon, the prodigal Monkey will return and transform into an eagle to steal their clothes, giving him enough time to rescue his master and fellow disciples.`,
          image: KumokoAdd,
        },
      ],
      observation: [
        {
          text: `These bathing beauties were initially nude, but Aw Boon Haw was warned that the nudity might scandalize the locals, so he requested for bikinis to be painted on in the 1950s. In the 90s, further censorship intervened and the bikinis became these modest halter tops and hotpants – much more discrete but maybe not quite traditional Chinese!`,
        },
      ],
      zone: "e",
    },
    {
      images: [MonkeyBattle],
      title: "Monkey battles the Red Child",
      sections: [
        {
          text: `In the foreground, under the canopy, is a tableau entitled “Monkey fighting the Red Child 红孩儿”.  As the inscription beside him states, Red Child is the son of the Ox Demon King 牛魔王 and the Steel Fan Princess 铁扇公主, a couple who were very keen to capture and consume Tripitaka’s flesh. While Red Child may look like a kid, he has actually spent three hundred years cultivating his control of fire. This tableau depicts the showdown  (with assorted demons joining the fray.)`,
        },
        {
          text: `Unfortunately, Monkey will not win this battle so easily – he will eventually need the Goddess of Mercy’s intervention. She finally subdues the child and turns him into her disciple.`,
        },
        {
          observation: [
            {
              text: `There’s an anomaly here that has puzzled visitors for decades. The tableau is entitled “Sun Wukong battles the Red Child” but the boy is depicted standing on flying wheels, which is the trademark of Ne Zha, the popular boy-god. While Ne Zha and Monkey  also clashed (back when the Monkey first rebelled against the Jade Emperor), the two of them had become friends by the time the Journey began. In fact, Ne Zha came to Monkey’s assistance and helped him defeat the Ox Demon King, the Red Child’s father. So it is unclear which boy Aw Boon Haw originally intended to depict.`,
            },
            {
              text: `Apart from inscriptions that narrate the tales and identify the characters, there are also occasional messages to the visitor – from the craftsmen!  Under the canopy, to the left of the tableau title, an inscription reads  “Warning :  this is public property. Please cooperate, do not enter. I’m sure you are not such a selfish person!”`,
            },
          ],
        },
      ],
      zone: "e",
    },
    {
      images: [Sandy],
      title: "Sandy",
      sections: [
        {
          text: `To the left, we are back in the Spider story. Sandy or Sha Seng 沙僧 (‘Monk Sand’) was originally the heavenly general Juan Lian Da Jiang卷帘大将 (‘Curtain Lifting General’), with his heavenly weapon – the Crescent Spade (月牙铲). He broke a crystal dish at a Peach Banquet and was punished by the Jade Emperor with 800 strokes of the rod and exiled to life as a man-eating Sand Demon in the desert of Liu Sha He 流沙河 (‘Flowing Sand River’). As a demon, he was responsible for the death of many pilgrims, including nine monks who were earlier on the same mission as Tripitaka. He ate them, sucked the marrow from their bones and wore their skulls as a necklace.`,
        },
        {
          text: `The Goddess of Mercy gave him a chance to redeem himself by recruiting him as Tripitaka’s disciple, converting him and giving him the name Sha Wu Jing 沙悟凈  - ‘sand aware of purity’.  At Journey’s end, the loyal Sandy will become the ‘Golden-bodied Arhat’.  (An arhat is a kind of Buddhist saint, one level down from a bodhisattva.)`,
        },
        {
          text: `Here, he is being subdued by two of the Spider Women. The text painted on the rock wall reads like a comic-book speech bubble - “The spider demonesses tell Sandy : If you don’t obey my wishes, I will definitely send you to the afterlife!”. (It is not clear who the old lady is – there was no older Spider Woman. Perhaps she is the old lady in the next adventure – the Magic Cypress Grove – and the artisans did a crossover!)`,
        },
      ],
      zone: "e",
    },
    {
      images: [TripitakaHorse],
      title: "Tripitaka’s White Horse",
      sections: [
        {
          text: `Further left, we see the white horse left untended with their luggage scattered on the ground, while Tripitaka and the disciples are trapped in the clutches of the Spider Women.  The title on the book in the foreground labels it The Complete Diamond Sutra 金刚经全卷, one of the best known sutras of Mahayana Buddhism and one of the texts translated by Xuan Zang in 648 AD.`,
        },
        {
          text: `On the rock behind the horse, the inscription tells us : “Tripitaka and his disciples encounter  disaster at Silk Spinning Cave. Master & two disciples vanish, leaving behind their luggage untended. A frantic Monkey searched for them everywhere …”  Sadly, the rest of the inscription is indecipherable.`,
        },
      ],
      zone: "e",
    },
    {
      images: [],
      title: "The Magic Cypress Grove",
      sections: [
        {
          text: `In this ditch, we see Pigsy tangled up in a different adventure.  One day, the pilgrims came upon the house of a widow with three lovely daughters. The widow was determined to marry her daughters and herself off to these four pilgrims, and enticed them accordingly.  On a ledge above the Red Child canopy, we can see the three daughters flaunting their cha-cha-charms. Tripitaka, Monkey and Sandy blocked their senses to these charms and chose to sleep in the open, but Pigsy succumbed to his baser instincts and entered their home.`,
          image: CypressAdd1,
        },
        {
          text: `Inside, the widow blindfolded and teased him, but instead of receiving a night of pleasure, Pigsy found himself tied to a cypress tree. The disciples woke the next day to find the house vanished, and Pigsy all trussed up.  In the story, a slip of paper found dangling from the same tree explained that the ‘widow’ was actually the pious Old Woman of Mount Li and the dancing ‘daughters’ were actually the bodhisattvas Manjusri, Samantabhadra and Avalokitesvara (the Goddess of Mercy herself) in disguise, trying to test the virtue of the pilgrims. Obviously, Pigsy failed.`,
          image: CypressAdd2,
        },
        {
          text: `The tree once bore an inscription that said : “A smug Pigsy, forgetting propriety in his glee, made a big error – tragedy comes out of a moment of great joy, causing regret.”`,
          image: CypressAdd3,
        },
        {
          text: `Standing high on a rock, we see the prodigal Monkey in the Spider Woman tale, returning from Tripitaka’s temporary banishment and frantically searching for his kidnapped master and comrades.`,
        },
        {
          text: `Tripitaka’s first disciple, Monkey is the sharpest, most flamboyant and most rebellious of the team.  Although Tripitaka released Monkey from his 500-year imprisonment under Five Element Mountain (perhaps represented by the large rock has been split in two like a melon), Monkey was still stubborn and defiant. At least until the Goddess of Mercy gave Tripitaka a disciplinary device - a golden headband that would tighten upon command and give Monkey a terrible pain if he disobeyed.`,
        },
        {
          text: `After the Journey, Monkey will be rewarded for his heroic and loyal service by becoming ‘Buddha Victorious in Strife’.`,
        },
      ],
      zone: "e",
    },
    {
      images: [KumokoTrap],
      title: "Spider Women Trap Tripitaka",
      sections: [
        {
          text: `The last tableau takes place in the Spider Women’s abode – the aptly-named Pan Si Dong 盘丝洞 (‘Silk-Coiling Cave’). Tripitaka is being seduced by two of the Spider Women while a third disdainfully throws away books labelled “original Buddhist sutras from the Western Heavens”. To the left, in a grotto overhead, another luscious beauty lies in bed reading.  Over Tripitaka’s shoulder, a speech bubble once said “don’t go on the road to the Western heaven… why not stay here and keep us women company ?”`,
        },
        {
          text: `In the novel, the Spider Women merely kidnapped Tripitaka for food, without attempting seduction – but Aw Boon Haw most likely chose to spice up the tableau for the crowds by borrowing the sexual overtones from another adventure – that of the Scorpion Demoness of Venom Mountain! This matches the choice to depict the skinny-dipping scene! Undoubtedly, Aw was out to titillate and tease the crowd. In fact, there was also an inscription on the rock beside the seduction scene that read : “If the Western Paradise was in Haw Par, perverts would have fled from here!”`,
        },
        {
          observation: [
            {
              text: `The Four Great Classical Novels of the Chinese world are (in chronological order):`,
            },
            {
              text: `•	Water Margin (水浒传), written by Shi Nai An in the 14th century`,
            },
            {
              text: `•	Romance of the Three Kingdoms (三国演义), written by Luo Guan Zhong in the 14th century`,
            },
            {
              text: `•	Journey to the West (西游记), written by Wu Cheng En in the 16th century`,
            },
            {
              text: `•	Dream of the Red Chamber (红楼梦), written by Cao Xueqin in the 18th century`,
            },
          ],
        },
        {
          text: `Three of the four are featured in Haw Par! (Water Margin at D4 and E5, Romance of the Three Kingdoms at F11, and Journey to the West here!`,
        },
        {
          text: `Dating from the Ming and Qing dynasties, these four texts have spread across East Asia – inspiring retellings in China, Japan, Korea and Vietnam; through novels, operas, dances, films and even games.`,
        },
        {
          text: `Prior to these four, novelists were lowly regarded in China, but the vast popularity of these works legitimized fiction as an artistic pursuit and as popular culture!`,
        },
      ],
      zone: "e",
    },
    {
      images: [SowYoung],
      title: "Sow and her young",
      sections: [
        {
          text: `Through the archway and up the stairs to the left, we see a sow suckling her young. To the Chinese, the pig represents prosperity and fecundity. Dating from the early 1950s, this mother pig has certainly proven this to be true.`,
        },
      ],
      zone: "e",
    },
  ],
  e2: [
    {
      images: [Monastery],
      title: "The Burning of Red Lotus Monastery  火烧红莲寺",
      sections: [
        {
          text: `The title of this tableau, painted on a stalactite, is “The Burning of Red Lotus Monastery  火烧红莲寺” and the caption reads : “A heroic person expels the tyrant and brings peace to the good!”`,
        },
        {
          text: `This story was first told in a newspaper serial Tales of the Strange Travelling Warrior (江湖奇侠传) and was eventually made into one of the longest Chinese films ever – a 27 hour long black and white epic that was released in 19 feature-length parts between 1928 and 1931. Sadly, no copies remain.`,
        },
        {
          text: `The story : The Red Lotus Monastery is run by Zhi Yuan 知圆, a degenerate monk. Zhi and his fellow monks have kidnapped women and kept them in the temple for their pleasure.  They even captured chief official Bu Wen Zheng 卜文正 and trapped him under a large bell, intending to suffocate him to death.`,
        },
        {
          text: `A vigilante team of five martial artists - Gan Lian Zhu甘连珠, Liu Chi 柳遲 and Hong Gu 红姑, her son Chen Ji Zhi  陈继志 and leader Lu Xiao Qing 陆小青- burnt the monastery to the ground, slew Zhi Yuan and rescued the women and Bu.`,
        },
        {
          text: `The battle taking place in the front (lower left) features one of the immoral monks Chang de Qing 常德庆, whose magical gourd is spewing flames which are being doused by water gushing forth from the head of the boy Chen Ji Zhi陈继志. Chang’s magical staff is deflecting the flying dagger thrown sideways by his mother Hong Gu 红姑.  (Just ignore the wires for the full effect of Taoist kung fu magic.)`,
        },
        {
          text: `Front and centre, another immoral monk Zhi Ke Seng 知客僧 is being double-teamed by Lu Xiao Qing陆小青 on the left and Liu Chi柳遲 with double knives on the right.`,
        },
        {
          text: `The Taoist nun standing alone at top left is believed to be Shen Qi Xia, Gan Lian Zhu’s martial arts teacher. The inscription overhead to the right explains that “the Taoist nun descends from the mountain/monastery to prevent the final situation”.`,
        },
        {
          text: `At the very top of the tableau, we see chief official Bu being rescued from the bell.  The banner above the shrine reads : “Buddha’s light shines on all” and the couplet tells how “the Western green willow is emerald for a thousand years; the Southern sea’s white lotus is fresh for ten thousand years.”`,
        },
        {
          text: `On the middle level on the extreme right, Gan Lian Zhu has flung a knife through the evil monk Zhi Yuan’s head. In the dark cavern behind them (called the 藏美室 – the Room of Hidden Beauty), the captive women are joyously calling out. The caption on the wall reveals their cry: “The black monk Yuan is struck dead by a sword! I have hope of returning home at last!”`,
          image: MonasteryAdd,
        },
      ],
      zone: "e",
    },
  ],
  e3: [
    {
      images: [AwChuKim],
      title: "Mr & Mrs Aw Chu Kim memorial",
      sections: [
        {
          text: `Aw Chu Kim was born in the humble tea-growing village of Zhongchuan in Yongding County in China’s Southeast Fujian Province. Escaping the poverty and starvation that plagued the farmers at home, Aw sought a better life in Southeast Asia.`,
        },
        {
          text: `His first port of call was Singapore, then he moved to Penang where he started working as a Chinese herbalist (his father’s trade). He wound up in Rangoon, Burma, where he met and married local Chinese girl Lee Kim Pek. With his uncle’s help, he started a small herbal shop Eng Aun Tong (永安堂, meaning ‘Hall of Everlasting Peace’). A generous man, Aw would often treat patients who could not pay. He refused to earn more than he needed for his family’s livelihood. He gained much respect in his community for his virtues.`,
        },
        {
          text: `Aw Senior and his wife had three sons, the eldest of whom – Aw Boon Leng - died young. If he had lived, there might have been a dragon to accompany the tiger and leopard in these Gardens.`,
        },
        {
          text: `Aw Senior died in 1908, leaving his other two sons Aw Boon Haw and Aw Boon Par to take over the business and support the family. Soon, the business blossomed into the Tiger Balm brand we know today.`,
        },
        {
          text: `This memorial was built after WWII as a new memorial to Aw Boon Haw’s parents, while their previous memorial (D10) became his own. This monument was freshly dedicated by the Aw family in 1955. The pair of guardian lions are believed to be cast in bronze but have since been painted silver.  A similar pair of silver lions also used to guard the front door of the Aw mansion in Hong Kong.`,
        },
      ],
      zone: "e",
    },
    {
      images: [TigerPair, LeopardPair],
      title: "Tiger & Leopard",
      sections: [
        {
          text: `At the top of the stairs leading down to the pond, we find another tiger and leopard pair, installed in the late 1940s – the loyal brothers keeping watch over their parents’ memorial.`,
        },
      ],
      zone: "e",
    },
  ],
  e4: [
    {
      images: [Pavilion],
      title: "Pavilion",
      sections: [
        {
          text: `A part of the Gardens from the very beginning, this pavilion boasts one of the most discrete designs among all of Haw Par’s eclectic pavilions. The six semicircular eaves of the roof echo the shape of the Villa atop of the hill. Previously, a concrete skirting around the pavilion’s base echoed the roof shape – you can still see the outline of this.`,
        },
      ],
      zone: "e",
    },
  ],
  e5: [
    {
      images: [WuSongKill],
      title: "Wu Song Killing His Sister-in-Law",
      sections: [
        {
          text: `The sign labels this tableau “Wu Song Killing Sister-in-Law”. It depicts one of the more violent tales of fraternal piety in the Chinese culture. This is part of the origin story of the tiger-slaying hero Wu Song 武松 from ‘Water Margin’水浒传, one of China’s Four Great Classical Novels. After his moment of glory with the tiger (see D4 nearby), Wu Song encounters his elder brother Wu Dalang 武大郎 and his beautiful new wife Pan Jinlian 潘金莲. Unfaithful to her short and ugly husband, Pan makes passes at her hunky hero of a brother-in-law, but her advances are firmly rejected. To put some distance between him and trouble, Wu Song accepts an assignment from the magistrate to transport gold to another city. Two months later, he returns to find that his brother has died, purportedly from illness.`,
        },
        {
          text: `Unconvinced, Wu Song investigates and uncovers Pan’s affair with influential merchant Ximen Qing 西門慶. He learns that Wu Dalang had walked in on the adulterous pair in bed, and they had beaten him, poisoned him and then smothered him. Outraged, Wu Song presents his case at the county court, but the magistrate, bribed by Ximen Qing, dismisses the case.`,
        },
        {
          text: `Taking matters into his own hands, Wu Song confronts Pan and forces a full confession from her at knifepoint, before ritually decapitating and disemboweling her.`,
        },
        {
          text: `Behind the wrathful Wu Song in this tableau stands Ximen Qing, who will be next to die at Wu Song’s avenging hands.`,
        },
        {
          text: `For some reason, this version keeps Wu Dalang alive – he is seen staggering out of the house, nose a-bleeding from the poison, pointing accusingly at the guilty pair.`,
        },
        {
          text: `With his mission accomplished, Wu Song will surrender himself at the county court. Acknowledging his righteous intentions, the court will spare his life but exile him to a prison camp. Eventually, he will escape and join the band of outlaws at the heart of the Water Margin saga.`,
        },
        {
          text: `Above the door, the banners wishes everyone a peaceful and safe entry and exit, while the couplet tells how “the lucky star shines from high on this peaceful home; auspicious light often visits a family who has accumulated goodness.” A woefully ironic statement over a home poisoned by Pan Jinlian.`,
        },
      ],
      zone: "e",
    },
  ],
  e6: [
    {
      images: [SongZi],
      title: "Song Zi Guan Yin shrine",
      sections: [
        {
          text: `At the top of the steps, a part of the rock wall has been painted with a shrine to the Song Zi Guan Yin 送子观音 - the Child-Giving Goddess of Mercy. Although not a part of the original Gardens, this corner soon became a popular shrine for the childless – offerings and joss sticks would be placed on the flat rock and prayers offered. The painted Goddess carries a baby, and the phrase on the lotus petals she sits on reads 早生贵子 - ‘for early birth of a precious child’.`,
        },
        {
          text: `On the rock altar, the enlarged word 求 (‘plead’) is part of the phrase 有求必应, which practically means ‘ask and ye shall receive’.`,
        },
        {
          text: `While Aw Boon Haw was more interested in depicting Buddhas, later custodians of the Gardens have provided a feminine counterbalance by adding several images of Guan Yin (see C12 and G1).  However, Guan Yin herself began as male – the Bodhisattva Avalokitesvara. His name meant ‘beholder of the cries of the world’, and after the gender switch, the deity was named 观世音 Guan Shi Yin, which has the same meaning.`,
        },
      ],
      zone: "e",
    },
  ],
};

export default ZoneEMapping;
