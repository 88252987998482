import Crocodiles from "../assets/photos/zone_c/compressed/c1_main1a.jpg";

import Immortals from "../assets/photos/zone_c/compressed/c2_main1a.jpg";
import TigerRocks from "../assets/photos/zone_c/compressed/c2_main2a.jpg";
import DragonPair from "../assets/photos/zone_c/compressed/c2_main3a.jpg";
import ImmortalsAdd1 from "../assets/photos/zone_c/compressed/c2_extra1a.jpg";
import ImmortalsAdd2 from "../assets/photos/zone_c/compressed/c2_extra1b.jpg";
import ImmortalsAdd3 from "../assets/photos/zone_c/compressed/c2_extra1c.jpg";
import ImmortalsAdd4 from "../assets/photos/zone_c/compressed/c2_extra1d.jpg";
import ImmortalsAdd5 from "../assets/photos/zone_c/compressed/c2_extra1e.jpg";
import ImmortalsAdd6 from "../assets/photos/zone_c/compressed/c2_extra1f.jpg";
import ImmortalsAdd7 from "../assets/photos/zone_c/compressed/c2_extra1g.jpg";
import ImmortalsAdd8 from "../assets/photos/zone_c/compressed/c2_extra1h.jpg";
import ImmortalsAdd9 from "../assets/photos/zone_c/compressed/c2_extra1i.jpg";
import ImmortalsAdd10 from "../assets/photos/zone_c/compressed/c2_extra1j.jpg";
import ImmortalsAdd11 from "../assets/photos/zone_c/compressed/c2_extra1k.jpg";

import Toads from "../assets/photos/zone_c/compressed/c3_main1a.jpg";

import PavilionC4 from "../assets/photos/zone_c/compressed/c4_main1a.jpg";

import PavilionC5 from "../assets/photos/zone_c/compressed/c5_main1a.jpg";

import SumoWarriors from "../assets/photos/zone_c/compressed/c6_main1a.jpg";
import Birds from "../assets/photos/zone_c/compressed/c6_main2a.jpg";

import SeaLions from "../assets/photos/zone_c/compressed/c7_main1a.jpg";
import Cavern from "../assets/photos/zone_c/compressed/c7_main2a.jpg";

import Garuda from "../assets/photos/zone_c/compressed/c8_main1a.jpg";
import Monastery from "../assets/photos/zone_c/compressed/c8_main2a.jpg";
import MonasteryAdd from "../assets/photos/zone_c/compressed/c8_extra2a.jpg";

import Liberty from "../assets/photos/zone_c/compressed/c9_main1a.jpg";

import BlackSwans from "../assets/photos/zone_c/compressed/c10_main1a.jpg";
import FlowerMaiden from "../assets/photos/zone_c/compressed/c10_main2a.jpg";

import Pond from "../assets/photos/zone_c/compressed/c11_main1a.jpg";
import PavilionC11 from "../assets/photos/zone_c/compressed/c11_main2a.jpg";
import PondAdd1 from "../assets/photos/zone_c/compressed/c11_extra1a.jpg";
import PondAdd2 from "../assets/photos/zone_c/compressed/c11_extra1b.jpg";
import PavilionC11Add from "../assets/photos/zone_c/compressed/c11_extra2a.jpg";

import Buddha from "../assets/photos/zone_c/compressed/c12_main1a.jpg";
import Mercy from "../assets/photos/zone_c/compressed/c12_main2a.jpg";

import TigerPair from "../assets/photos/zone_c/compressed/c13_main1a.jpg";
import LeopardPair from "../assets/photos/zone_c/compressed/c13_main1b.jpg";

import Archway from "../assets/photos/zone_c/compressed/c14_main1a.jpg";
import Storks from "../assets/photos/zone_c/compressed/c14_main2a.jpg";

import FuLuShou from "../assets/photos/zone_c/compressed/c15_main1a.jpg";
import StoneLions from "../assets/photos/zone_c/compressed/c15_main2a.jpg";
import ToadFrog from "../assets/photos/zone_c/compressed/c15_main3a.jpg";
import FuLuShouAdd from "../assets/photos/zone_c/compressed/c15_extra1a.jpg";
import StoneLionsAdd from "../assets/photos/zone_c/compressed/c15_extra2a.jpg";

import { EntryMap } from "../content/entryMap";

const ZoneCMapping: EntryMap = {
  // Zone C Entries
  c1: [
    {
      images: [Crocodiles],
      title: "Crocodiles",
      sections: [
        {
          text: `These two huge crocodiles originated in the big pond nearby (see C11), where they frolicked with other exotic water creatures among sculpted cement waves. When the pond was filled in 1989 for Dragon World, the creatures were extracted.`,
        },
      ],
      zone: "c",
    },
  ],
  c2: [
    {
      images: [Immortals],
      title: "Eight Immortals invading Neptune's Palace",
      sections: [
        {
          text: `This tableau, built by Kwok Choon Sua 郭春山 in the late 1940s, depicts one of the greatest and most fantastical battles in Chinese mythology, and is a favorite with young visitors. It shows the fabled Eight Immortals battling the Eastern Dragon King and his forces. The Eight Immortals are some of the most popular Taoist gods.`,
        },
        {
          text: `The story of them crossing the sea originates from an anonymous Ming Dynasty story and gives us the Chinese proverb 八仙过海， 各显神通   (“The Eight Immortals cross the sea; each reveals his/her divine power") for describe situations in which each person in a team can display a unique skill.`,
        },
        {
          text: `The Eight Immortals have many shrines dedicated to them and often adorn ceremonial altar cloths – being a useful design to fill a long horizontal strip with.  They continue to be popular in Chinese art and film, and they’ve even been reimagined as mutant protectors of China in an issue of the X-Men (Vol 2 #158, August 2004)!`,
        },
        {
          text: `The back story (one of many versions) : After attending the birthday celebration of the Goddess of the Western Paradise,  the Eight Immortals were crossing the Eastern Sea to return home to the Isles of Paradise, each using their magic emblem. On the way, Lan Cai He (藍采和) accidentally dropped his  – the White Flower Jade Basket (白花玉蓝). The Eastern Dragon King’s mother had heard that eating the petals of Lan Cai He’s flowers would make her wrinkles disappear, so she had the basket brought to her. When Lan asked for his basket back, he was taken prisoner.`,
        },
        {
          text: `Infuriated, the other Immortals launched a rescue mission. They stormed the Dragon King’s palace and a furious undersea battle ensued. Thousands of sea creatures - including squid, crabs, scallops, tortoises, and fish - pitted their lives against the immortals. (The true “marines”.)`,
        },
        {
          text: `The Jade Emperor of the Heavens sent his emissary Xi Wang Mu (西王母) to investigate. After hearing both sides of the story, she judged that both sides had erred. She called for a truce and the release of Lan.`,
        },
        {
          text: `Let’s meet the Eight Immortals and see how they are doing in the battle (starting from the left) :`,
          image: ImmortalsAdd1,
        },
        {
          text: `Lan Cai He 蓝彩和 is the most enigmatic of the Eight. Lan is alternately depicted as a boy or a girl, though some writings suggest he/she was a hermaphrodite. He/she carries a flower basket and sometimes, bamboo castanets.  He/she is the patron deity of florists.`,
        },
        {
          text: `Being the root of this conflict and not at all disadvantaged by weaponlessness -  he/she is doing double duty by fighting both an ink-spewing squid-man and a sword-wielding clam-woman.`,
          image: ImmortalsAdd2,
        },
        {
          text: `Han Zhong Li (钟离权) AKA Zhong Li Quan 鐘离權 was a Zhou Dynasty military general with many victories under his belt, and now he is the patron deity of soldiers as well as the chief of the Eight Immortals. He is depicted bare-chested and pot-bellied, usually jolly and drinking wine. His emblem is a magic fan which can revive the dead. His magic fan can revive the dead, but right now he’s using it to combat lethal squid ink!`,
          image: ImmortalsAdd3,
        },
        {
          text: `Cao Guo Jiu 曹國舅 (or Uncle Cao Guo) was previously the younger brother of Empress Dowager Cao of the Song Dynasty. He is often seen in a court official's outfit with a jade tablet which can purify the environment. Sometimes he holds castanets and is considered the patron deity of actors.`,
        },
        {
          text: `Below this pair we see Cao Guo Jiu 曹國舅 - patron of actors. His emblem is a jade tablet which can purify the environment. Not so useful here, so he’s just using a horseshoe crab to clobber someone!`,
          image: ImmortalsAdd4,
        },
        {
          text: `Han Xiang Zi 韓湘子 (sometimes called 清夫) was historically the grandnephew of Han Yu, a prominent statesman of Tang Court. His emblem is a flute, and he is the patron of musicians. His music is said to be able to make flowers grow and soothe beasts – here we see him trying it out on sea creatures but without much success. (Unless he is trying to make the missing basket of flowers grow…)`,
          image: ImmortalsAdd5,
        },
        {
          text: `Born 796 AD, Lu Dong Bin  呂洞賓 AKA Lu Yan 呂岩 was  a Tang Dynasty Chinese scholar and poet who was canonized as an immortal. He is the patron of barbers and scholars. He is dressed as a scholar, but is known to be a skilled swordsman. He wields a fly whisk and a sword that dispels evil spirits.`,
        },
        {
          text: `Here, he has impaled a toad-soldier with his flying sword.`,
          image: ImmortalsAdd6,
        },
        {
          text: `Tie Guai Li 李铁拐 (Iron Crutch Li) was known for his ability to astral project, sending his soul wandering while his body remained at home. He was also a handsome man initially, but after a tragic mishap in which his disciple cremated his corpse while Li’s soul was away, Li was forced to take up residence in the crippled body of a recently-dead beggar. Hence the crutch and the bad temper he is usually depicted with. However, he is benevolent to the sick - his magical gourd (hu lu 葫芦) pours out medicines for them and is often depicted in traditional Chinese medicine halls and herbal tea shops. He is the patron of the sick as well as herbalists and exorcists.`,
        },
        {
          text: `Right now, however, the gourd is set to damage rather than heal – it  pours forth  flames which he is aiming toward the Dragon King himself!`,
          image: ImmortalsAdd7,
        },
        {
          text: `He Xian Gu 何仙姑  is the only female among the Eight  and the patron of all the single ladies. She was born He Qiong 何瓊, the daughter of a Tang Dynasty shopkeeper. There are many versions of how she gained immortality, but one popular one tells how a divine being told her in a dream to eat powdered mica to make her body ethereal. Doing so, she vowed to remain a virgin and slowly stopped eating mortal food. As her Taoist skills increased, the Empress Wu summoned her to the palace, wanting to steal her secrets. Knowing the Empress’ intentions, she set off for the palace but never arrived – she had ascended to Heaven en route.`,
        },
        {
          text: `Her emblem is sometimes a peach, but often a lotus flower. Here she is using the lotus to fan away the Dragon Prince’s attack.`,
          image: ImmortalsAdd8,
        },
        {
          text: `Zhang Guo Lao (张果老) was a Taoist alchemist living as a hermit on Mount Zhongtiao in Heng Prefecture. He is known for having a white mule that he can fold up like a piece and paper and tuck into his purse. He is often depicted riding on this mule, sometimes facing backwards. Perhaps this reinforces his image as the patron of the elderly. His emblem is a tubed-shaped bamboo fish-drum - which he is now using as a weapon to fight off the Dragon Prince, who is riding a giant carp.`,
          image: ImmortalsAdd9,
        },
        {
          text: `The old translation of the title at the base of the tableau calls him Neptune, but other than ruling the sea, the Dragon King and the Roman god of the sea have little in common.`,
        },
        {
          text: `Ao Guang 敖廣 the Dragon King of the East is one of the four Dragon Kings – there is one for each cardinal direction. While a deity, he functions like a human monarch, since the Chinese spiritual realms, including heaven and hell, are structured like mortal hierarchies.  In his case, he was a feudal tyrant, but the people were too afraid of him and his sons to complain to the Jade Emperor.`,
        },
        {
          text: `He is famous for being the one whose palace pillar was taken by the Monkey God as a golden staff as his weapon of choice, and for his ongoing feud with Ne Zha.`,
          observation: [
            {
              text: `Look carefully at the dragon that the Dragon King is riding – see how all the scales seem individually-crafted? And the fins along its back? These are actually hundreds of pieces of cut glass inserted and painted to give the statue an intensely-vivid 3D detail. This is the Southern Chinese sculpting style known as 剪黏 jian nian (literally “cut–paste”), the hometown specialty of Aw’s craftsmen.`,
            },
          ],
          image: ImmortalsAdd10,
        },
        {
          text: `Arriving on her phoenix, flanked by celestial maidens, Xi Wang Mu 西王母 the Queen Mother of the West is here to mediate. Her official Taoist title is the Golden Mother of the Shining Lake  (Yao Chi Jin Mu 瑤池金母). She is one of the oldest deities – worshipped even before Taoism, possibly as far back as the 15th century BC. It is in her orchards at Mount Kunlun that the Peaches of Immortality grow. She was particularly revered by women who went against the submissive norm that society imposed upon them.  She was such a strong and independent female figure that the Jade Emperor deputized her to resolve this terrible conflict we see before us.`,
          observation: [
            {
              text: `The same method of 剪黏 jian nian is used here to give the phoenix its intricate tail feathers and even the feathers on the attendants’ fans! Some of the glass pieces here are left unpainted for that transparent look – quite celestial!
              Unpainted green glass is also used on the crab in the lower left, giving it a strikingly crustacean effect!`,
            },
          ],
          image: ImmortalsAdd11,
        },
        {
          text: `The little boy on the right riding on a manta ray and blowing on a trumpet might be Ne Zha. Though he is not part of this battle, Aw Boon Haw might have intended a crossover – after all, Ne Zha and the Dragon King had been feuding bitterly.`,
          observation: [
            {
              text: `Compare the different textures of the sea creatures – such as the glassy crab, the sandpapery horseshoe crab, the porcelain hermit crab, the spiky lobster and so on. This tableaux is probably the most complex one in the park, given its wide variety of techniques used!`,
            },
          ],
        },
        {
          text: `Also, see how small slits were cut into the back wall to provide subtle back-lighting for the figures on the upper levels!`,
        },
      ],
      zone: "c",
    },
    {
      images: [TigerRocks],
      title: "Cave with Tiger Rocks",
      sections: [
        {
          text: `This sculpted grotto was previously called Yuan Yang Dong 鸳鸯洞. Above the entrance, among the waves of the Eastern Sea, you can still see the flat oval surface on which the namme was once painted. The term ‘yuan yang’ 鸳鸯 refers to Mandarin ducks that mate for life, and is commonly used to refer to perfect couples.`,
        },
        {
          text: `The Hong Kong Tiger Balm Gardens were filled with caves tunneling into the hillside, offering shady nooks for rest, meditation or hanky-panky. The comparatively gentle slope of Singapore’s Gardens made the cave aesthetic somewhat less practical, but here the artisans managed to offer a taste of the Hong Kong experience.`,
        },
        {
          text: `Deep inside the cave is a pond with five tiger heads disguised in the rockery above it. This ferocious quintet is a reference to the popular Chinese idiom “五幅临门” - “May the five-fold happiness approach your door”.  The five aspects of happiness are good luck, wealth, longevity, prosperity and happiness itself. What’s the connection? The first two words of the idiom sound like “五虎” – “five tigers” – simple as that!`,
        },
      ],
      zone: "c",
    },
    {
      images: [DragonPair],
      title: "Pair of Dragons",
      sections: [
        {
          text: `The dragon is the most highly revered creature in the Chinese cosmos. It is believed to resemble  nine different creatures – boasting the antlers of a stag, the eyes of a rabbit, a camel’s head, a snake’s neck, a carp’s scales, an eagle’s claws, a frog’s belly, the ears of a cow and the soles of a tiger.`,
        },
        {
          text: `The dragon symbolizes vigilance and guardianship and has been the Chinese royal emblem since the Han dynasty. The five-clawed dragon represented Imperial authority and appeared on all royal paraphernalia, and no self-respecting triad boss would be caught dead without a dragon tattoo.`,
        },
        {
          text: `Being creatures of both the air and sea, dragons are most often depicted among swirling clouds that look like waves, or vice versa.`,
        },
      ],
      zone: "c",
    },
  ],
  c3: [
    {
      images: [Toads],
      title: "Toads",
      sections: [
        {
          text: `These two toads began life inside C11 - the pond that used to contain giant water creatures – along with the crocodiles at C1. They were removed when the pond became a terrapin pool in 1989.`,
        },
        {
          text: `When they were relocated here, they also received some surgery – gold coins were added to their mouths and ingots at their feet to turn them into an approximation of Jin Chan 金蟾, a popular charm for prosperity. However, the mythical Jin Chan is three-legged, so a little more surgery might have been needed.`,
        },
      ],
      observation: [
        {
          text: `In this spot, there once stood one of the biggest highlights of the Gardens  - a giant Trengganu turtle that was wide enough for a whole family to sit on its shell and take photos! And many families did!`,
        },
      ],
      zone: "c",
    },
  ],
  c4: [
    {
      images: [PavilionC4],
      title: "Pavilion",
      sections: [
        {
          text: `This pavilion is unique for being the only one in the Gardens that cannot actually be entered! It seems almost like a gag pavilion!`,
        },
        {
          text: `The pavilion’s columns are shaped like vases, perhaps because the Chinese character for ‘bottle’ ( 瓶 ) is a homonym for ‘peace’ (平).  All the peace supports the globe, and an eagle perches on top.  Pay attention to the various paintings on the ‘vases’, blending flat painting with gentle bas relief!`,
        },
        {
          text: `Also, note the border of balls that echoes the balustrade of balls that surrounds the whole park. Circles are the most prominent motif in the Gardens, and symbolize the unity of family.`,
        },
      ],
      zone: "c",
    },
  ],
  c5: [
    {
      images: [PavilionC5],
      title: "Pavilion",
      sections: [
        {
          text: `This pavilion features a traditional tiled Chinese roof, with dragon/fish roof ornaments. The false balcony has a mock bamboo balustrade. Yet despite these Chinese touches, the overall design is boldly un-Chinese and joyously mismatched – in particular the insanely curved columns! This pavilion truly captures the maverick inventiveness of the Aws, and their love of weird juxtapositions!`,
        },
      ],
      zone: "c",
    },
  ],
  c6: [
    {
      images: [SumoWarriors],
      title: "Two Sumo Warriors",
      sections: [
        {
          bold: `One of the most iconic and most photographed exhibits in the Gardens, these two Sumo wrestlers were commissioned in the 1960s by Aw Boon Par’s son Aw Cheng Chye. They were part of a Japanese cultural exhibit, and the low wall behind them was adorned with a painting of Mount Fuji and its reflection in a lake.`,
        },
        {
          bold: `Along with exhibits like the Thai dancer near the gate (A6), the nearby Indonesian garuda (C8), some Australian fauna (C10 & G13) and the Statue of Liberty (C9), these wrestlers gave Singaporean visitors a chance to see the cultures of the world for free!`,
        },
        {
          text: `On the tree stump between the wrestlers is the product placement – Tiger Balm and Wan Jin You 万金油.`,
        },
        {
          text: `In the Dragon World makeover, many un-Chinese statues were removed and dumped on a slope behind the park that some call the ‘statue graveyard’. When Dragon World the theme park closed, Haw Par was revived as a statue park again, and some of the statues were resurrected – including this warrior pair. Sadly, some were never reinstated – such as the giant Maori tiki, a pair of Spanish flamenco dancers and the Greek discobulus!`,
        },
      ],
      zone: "c",
    },
    {
      images: [Birds],
      title: "Birds",
      sections: [
        {
          text: `Installed in the early 1950s, this concrete tree once boasted over 30 birds perched upon it!`,
        },
        {
          text: `Some have flown away, but the remainder are sufficient to give the visitor a vivid Bird Park experience to go with the Haw Par zoo and Haw Par world tour! A visit to Haw Par was clearly intended to be many outings rolled into one! Look out for the mama bird feeding her young, and the startled owls!`,
        },
      ],
      zone: "c",
    },
  ],
  c7: [
    {
      images: [SeaLions],
      title: "Sea Lions",
      sections: [
        {
          text: `Initially, the Aw brothers kept live animals on the grounds of both the HK and Singapore homes. When maintenance became too difficult, they replaced the menagerie with concrete animals.`,
        },
        {
          text: `Along with a free world tour, the Aws clearly wanted to give the Singaporean visitors a free trip to the zoo! By the 1950s, this part of the Gardens featured spacious compounds exhibiting lifelike polar bears, rhinos and seals, each in their natural habitat.  In the pond nearby, there were even hippos and walruses.`,
        },
        {
          text: `All those majestic creatures are gone now – only these seals remain. Their very human expressions suggest that they might be aware that they are the last survivors!`,
        },
      ],
      zone: "c",
    },
    {
      images: [Cavern],
      title: "Cavern Landscapes",
      sections: [
        {
          text: `Often neglected by hurrying visitors, these cavern landscapes from the early 1950s represent an important part of the Chinese (and Haw Par) aesthetic.  Perhaps these miniature landscapes serve as 3D versions of Chinese landscape paintings. The Chinese have always seen mountains as a focus for meditation. To contemplate mountains - and how they connect water, earth and sky - is to contemplate deep truths.`,
        },
        {
          text: `The unpredictability of stairs weaving their way through the mountains like streams echoes the way the Garden’s paths also take the visitor on a journey of shifting orientation and ever-changing vistas.  Setting out on a path here is a leap of faith – you rarely can see where the path really leads. Spotting a monument up the slope is no guarantee that you can reach it easily – in Gardrens like these, you must wander.`,
        },
      ],
      zone: "c",
    },
  ],
  c8: [
    {
      images: [Garuda],
      title: "Garuda",
      sections: [
        {
          text: `Like the Sumo Wresters, this is another remnant of the international displays. This cover version of a  of a stone statue of an Indonesian Garuda was once displayed in front of an elaborate bas relief (now atop the hill at F12), forming a vivid Indonesian Corner.`,
        },
        {
          text: `Of Hindu origin, the garuda is a humanoid with a bird’s head and wings, and serves as the steed of the Lord Vishnu. It is often depicted standing on serpents, who are its sworn enemy.`,
        },
      ],
      zone: "c",
    },
    {
      images: [Monastery],
      title: "Deluge of the Golden Mountain Monastery 金山寺 ",
      sections: [
        {
          text: `The Legend of the White Snake (白蛇传) is one of China’s Four Great Folktales, popular in oral tradition long before it was first published in 1624.`,
        },
        {
          text: `The back story in a nutshell :  A shop boy Xu Xian buys rice balls from a vendor near the West Lake in Hangzhou. Unbeknownst to him, the vendor is actually Lu Dongbin, one of the Eight Immortals, and the rice balls are pills of immortality. Curious why he doesn’t feel hungry for days after eating the riceballs, Xu questions the vendor. The mischievous Immortal Lu flips him over and shakes him till he vomits the pills into the Lake.`,
        },
        {
          text: `In the lake live a white snake and a turtle, both of whom have been practising Taoist magical arts in order to become immortal. The snake swallows the regurgitated pills, gaining 500 years of magic powers and becoming indebted to Xu.  The turtle doesn’t get any pills and will become the envious villain of the saga.`,
        },
        {
          text: `One day, the white snake sees a green snake about to have its gall extracted by a beggar who has just caught it. She transforms into a woman and buys the green snake, creating a bond of sisterhood between the two snakes.`,
        },
        {
          text: `18 years later, the two snakes transform themselves into two young women - Bai Suzhen 白素贞 the white snake and Xiaoqing 小青 the green snake. Xu meets them and falls in love with Bai, leaving Xiaoqing to become a third wheel.  Bai and Xu’s union is threatened by the spiteful turtle, who by now has enough power to transform himself into Fa Hai, a Buddhist monk. He tricks Xu into serving his wife a wine that makes her reveal her true serpent form, and Xu dies of shock. The heartbroken Bai and her faithful green snake companion travel to Mount Emei to steal a magical herb which will revive Xu.`,
        },
        {
          text: `Revived, Xu accepts his wife for what she is, which infuriates Fa Hai. He lures Xu to the Golden Mountain Monastery (金山寺) and traps him there.`,
        },
        {
          text: `This tableau depicts the battle that ensues when White and Green Snake attack said monastery to save Xu, aided by many sea creatures on loan from the 4 Dragon Kings. Bai has transformed one of her shoes into a boat for transport, and her golden hairpin has transformed into a flag which Xiaoqing used to fan up a flood that sweeps over the area. Fa Hai turns his Buddhist robes into a barrier to block the water from entering the monastery proper. Eventually, Bai will lose the battle because she is weakened by her advanced state of pregnancy. (Though it isn’t showing at all in this tableau.)`,
        },
        {
          text: `Unfortunately, many innocent villagers are drowned in the deluge and the celestial gods (here seen watching the battle from on high) hold Bai to account for the death toll. They imprison her in Lei Feng Pagoda as punishment. The rest of the story is down the hill at A10.`,
          image: MonasteryAdd,
        },
      ],
      observation: [
        {
          text: `Go up the steps that cut through the middle of this tableau to experience the way Chinese garden paths are designed to disorientate and delight. Each turn offers a new vantage point and reveals unexpected details. From the top of the steps, the rocky roof of the temple reveals itself to be a huge boulder, precariously balanced.  Come down the steps on the side of the tableau to see the battle from high up and discover other characters fleeing from the flood!`,
        },
      ],
      zone: "c",
    },
  ],
  c9: [
    {
      images: [Liberty],
      title: "Statue of Liberty",
      sections: [
        {
          text: `This miniature Statue of Liberty was added to the Gardens in the 1960s by Aw Cheng Chye, son of Aw Boon Par, as part of a series of international exhibits intended to broaden the horizons of the visitors. It used to stand in front of a mural of American landmarks, and the artisans crafted them by referring to postcards that Cheng Chye brought back from his business trips.`,
        },
        {
          text: `Some of these international treats are still around, like the nearby Sumo wrestlers and the Indonesian garuda, but others have vanished – such as a replica of the Greek discobulos, a Maori tiki and a bas relief of Spanish flamenco dancers.`,
        },
      ],
      zone: "c",
    },
  ],
  c10: [
    {
      images: [BlackSwans],
      title: "Black Swans",
      sections: [
        {
          text: `The black swan is indigenous to Australia and appears on the state flag of Western Australia. This regal pair with their cygnets were once part of the Australian Corner, which boasted a range of Australian fauna, most of which now reside atop the hill at G13.`,
        },
      ],
      zone: "c",
    },
    {
      images: [FlowerMaiden],
      title: "Flower Maiden",
      sections: [
        {
          text: `The unidentified flower maiden is a much later addition to the Gardens. Compare the facial features and fabric details with those of the flower maiden at D2 and you’ll see the vast difference!`,
        },
      ],
      zone: "c",
    },
  ],
  c11: [
    {
      images: [Pond],
      title: "Pond",
      sections: [
        {
          text: `Initially, this pond was filled with live fish, but by the end of WWII, all the fish were dead and Aw Boon Haw decided to drain the pond and replace it with a permanent aquatic menagerie.`,
        },
        {
          text: `In Buddhist belief, all creatures are equal in Buddha’s regard.  In keeping with this, the pond was filled with 60 creatures ranging from swordfish and hippopotami to toads and crabs. There were also ducks and other water birds, walruses, snakes, a pair of fighting turtles and even rats, rabbits and guinea pigs – plus a small boy catching fish.`,
        },
        {
          text: `Representing a higher state of reincarnation were the half-human half-creature specimens – a conch woman and a clamshell woman. (Some of these statues can still be seen at F8 and F9, including the hybrid women.)`,
          image: PondAdd1,
        },
        {
          image: PondAdd2,
          text: `In 1989, the statues were removed and the pond filled up to become a terrapin pond. The result is a contemplative spot with lots of adorable terrapins to feed, but it seems a far cry from the riot of life that was once celebrated here.`,
        },
      ],
      zone: "c",
    },
    {
      images: [PavilionC11],
      title: "Pavilion",
      sections: [
        {
          text: `Built in the late 1940s, this six-sided three-tiered pagoda-styled pavilion provides shelter for Sakyamuni Buddha (Ru Lai Fo Zu 如来佛祖).  The pavilion stood on dry ground next to the pond, facing the rock structure that contained the Ten Courts of Hell. In 1989, Hell was moved downhill and the pond was expanded to surround this pavilion too.`,
          image: PavilionC11Add,
        },
        {
          text: `Take a closer look at the six tableaux forming the middle tier - the landscapes are stunningly detailed, and have been occupied by families of birds!  Also note the rows of Chinese coins framing each tableau?`,
        },
      ],
      zone: "c",
    },
  ],
  c12: [
    {
      images: [Buddha],
      title: "Buddha Pagoda",
      sections: [
        {
          text: `The magnificent pagoda above the pond was built at the very beginning of Haw Par’s history and once stood 15 storeys high! In 1954, Aw Boon Haw requested for the top 11 storeys to be demolished and in their place, he commissioned craftsman Tan Seng Hua to build this giant Sakyamuni Buddha (Ru Lai Fo Zu 如来佛祖). This was to become Aw Boon Haw’s final contribution to the Gardens. He passed away on 4 September that same year.`,
        },
        {
          text: `The Buddha displays the ‘calling the earth to witness’ mudra -  with the left hand in meditation while the right hand touches the earth. The mudra’s origin? When Buddha-to-be Siddharta Gautama sat under the Bodhi tree meditating, the demon Mara tried to dislodge him by scaring him with armies of monsters. Failing to make him budge, Mara declared that the seat of enlightenment belonged to him because his spiritual accomplishments were superior.  All of the monstrous soldiers cried out to bear witness for their leader. “And who will speak for you?”, Mara asked Siddartha. Siddhartha touched the ground with his right hand and the earth itself roared “I bear you witness”. Mara vanished, defeated.`,
        },
      ],
      observation: [
        {
          text: `Take a close look at the elaborate ornamental brackets under each roof – they are different on every level, ranging from dragons, bats, fish and then vines!`,
        },
      ],
      zone: "c",
    },
    {
      images: [Mercy],
      title: "Goddess of Mercy",
      sections: [
        {
          text: `Atop the steps leading down to the garage stands a porcelain statue of Guan Yin 观音, the Goddess of Mercy. This statue was commercially bought and erected here in later years – the original Gardens that Aw Boon Haw envisaged contained no images of Guan Yin.`,
        },
      ],
      zone: "c",
    },
  ],
  c13: [
    {
      images: [TigerPair, LeopardPair],
      title: "Tiger and Leopard ",
      sections: [
        {
          text: `Behind the pond, a tiger and a leopard are crouching on the rocks. Dating from the early 1950s, this pair was originally part of the Su Wu Tending Sheep tableau (A10) – perhaps intended to show that even the ferocious beasts honored the patriotic Su Wu, or that the Aw brothers were paying homage to him in their animal avatars.`,
        },
        {
          text: `Painted on the rocks are some meaningful sayings. On the right is a Chinese seal stating “Haw Par Villa”. On the left, the three inscriptions are (right to left) :`,
        },
        {
          text: `“Bright moon shines through the pines; clear water flows on the rocks.”`,
        },
        {
          text: `“Singapore’s virtue should strive to be as fragrant as the fragrant thoroughwort; and the Haw Par business as bountiful as the pine.” (This inscription takes a traditional Chinese saying and personalises it.)`,
        },
        {
          text: `“Birds sing and flowers are fragrant; butterflies dance and all colours are in harmony” and in larger letters “the sound of the spring”, referring to the tumbling waterfalls that used to lead to this pond.`,
        },
      ],
      zone: "c",
    },
  ],
  c14: [
    {
      images: [Archway],
      title: "Archway",
      sections: [
        {
          bold: `This archway was part of the earliest Gardens, and styled to resemble granite columns, with couplets carved into them.`,
        },
        {
          bold: `The longer couplet on the inner columns refers to the scenery, evoking quiet hills, lazy white clouds and sunlight on a vista of flowers; and gazing upon misty green waves in the morning.`,
        },
        {
          bold: `The shorter couplet on the outer columns tells us that “(if) your family is knowledgeable, from your ancestors onwards; even living in the mountains among the trees and rocks, it will be as if you live in a legendary mansion.”`,
          text: `These couplets were written for Haw Par by celebrated Chinese writer and poet Yu Dafu 郁达夫 (1896-1945). Yu was living in Singapore as the editor of the Sin Chew Jit Poh from 1938-1942. It is during that time that he was commissioned to write several poems to adorn the archways of Haw Par.`,
        },
        {
          text: `He had lived and studied in Japan as a young man, but at the start of the Second Sino-Japanese War, he returned to China and actively wrote anti-Japanese propaganda. So when the Japanese occupied Singapore, he wisely fled to Sumatra and lived under a different name. Unfortunately, he was eventually identified by the Japanese authorities and, it is rumored, executed by them after the Japanese surrender.`,
        },
        {
          text: `Another poem by him adorns the archway at F1.`,
        },
        {
          text: `These columns support horizontal transom beams, which in turn support elaborate sets of dou gong 斗拱 – interlocking wooden brackets  devised by the ancient Chinese as a weight-bearing system atop pillars. These brackets support the tiled hipped roof.`,
        },
        {
          text: `The central panel reads “Haw Par Villa” and on the left and right we read “heavenly winds” and “sea waves”, reminding us that this sloping garden is where the sky and the sea meet.`,
        },
        {
          text: `If you approach this archway going downhill, you will read different inscriptions on the other side. The left and right transom panels refer to the moon and clouds, and the pair of couplets draw evocative links between wind on the waves and mist in the mountains.  These literary touches, found throughout Haw Par, lend this tropical park the feel of a Chinese landscape painting.`,
        },
      ],
      zone: "c",
    },
    {
      images: [Storks],
      title: "Storks",
      sections: [
        {
          text: `A path takes visitors underneath this rocky overhang, supported on one side by realistic concrete bamboo. Atop the rock perch six elegant storks – a bird that represents longevity for the Chinese.`,
        },
      ],
      zone: "c",
    },
  ],
  c15: [
    {
      images: [FuLuShou],
      title: "Fu Lu Shou - Gods of Happiness, Prosperity and Longevity 福禄寿 ",
      sections: [
        {
          bold: `To the Chinese, the three attributes of happiness, prosperity and longevity characterise the good life. This trio of deities are subsequently found in homes and businesses throughout the Chinese world.`,
        },
        {
          bold: `These three deities were built in the 1960s as a classic stroke of Aw marketing genius. These three popular Chinese deities were depicted holding, not their celestial emblems, but Tiger Balm products!  Fu 幅 the God of Happiness held sachets of Tiger Balm products in both hands, Lu 禄 the God of Prosperity held boxed bottles of Tiger Oil, and Shou 寿 the God of Longevity held the iconic jar of Tiger Balm.`,
        },
        {
          bold: `After the family handed the Gardens over to the state, attempts were made to reduce the level of product placement, so the deities were given ingots and other more conventional objects to hold instead. The only original object left is Shou’s tiger staff.`,
          image: FuLuShouAdd,
        },
        {
          text: `Many Taoist deities are considered avatars of stars, and Fu Lu and Shou are, respectively,  the planet Jupiter and the stars Ursa Majorus and Canopus. They are often depicted as a scholar, an official and a sage.`,
        },
        {
          text: `Fu is often identified as Yang Cheng the governor of Hunan in the Tang dynasty, who petitioned the Emperor on behalf of midgets and saved them from being seized as imperial slaves.  He is usually seen holding a scroll.`,
        },
        {
          text: `The word lu refers to the salary of a government official - an index of the wealthy life that the Chinese average Joe aspired to. Hence, candidates often prayed to Lu for success in the examinations. Sometimes, Lu is also seen carrying a baby boy – another sign of prosperity for the Chinese – and he is sometimes prayed to by childless couples. He has dragons and clouds embroidered on his robes to denote his royal blood.`,
        },
        {
          text: `Shou was believed to have been carried in his mother’s womb for ten years and was born as an old man. He is usually depicted carrying the Peach of Immortality and a wooden staff.`,
        },
        {
          text: `The red tablets between the three gods bear a couplet :  “Search for the past and seek advice with the Gods of Prosperity, Wealth and Longevity “ and “Banish worries and enjoy a carefree life with a tour of Haw Par Villa”.`,
        },
      ],
      zone: "c",
    },
    {
      images: [StoneLions],
      title: "Stone Lions",
      sections: [
        {
          text: `Lions are the traditional guardians of Chinese premises. The female lion is usually depicted with a cub, the male with his right paw on a ball. This particular pair is a fanciful deviation from traditional stone lion designs, probably at Aw Boon Haw’s request. They evoke the exuberance of the Chinese lion dance instead of their traditional grandeur.`,
        },
        {
          text: `This pair are much later re-creations of the pair crafted in the late 1940s.`,
          image: StoneLionsAdd,
        },
      ],
      observation: [
        {
          text: `Take note of the unusual futuristic low wall that stretches along the path. As much as the Gardens celebrated Chinese aesthetics, Aw Boon Haw also wanted to expose the visitor to bold new styles, from the art deco to the futuristic. Look out for these modern styles on various pavilions and walls throughout the Gardens!`,
        },
      ],
      zone: "c",
    },
    {
      images: [ToadFrog],
      title: "Toads and Frogs",
      sections: [
        {
          text: `Down the slope from Fu Lu Shou is an assortment of frogs and toads. These 5 specimens  came from the pond (see C11), though one of them – the one perched upon the higher rock – has had a gold coin added to turn it into an approximation of the Jin Chan 金蟾, a popular charm for prosperity. However, the Jin Chan is three-legged, so this one is a little bit off.`,
        },
      ],
      zone: "c",
    },
  ],
};

export default ZoneCMapping;
