import FloatingActionButton from "../components/FloatingActionButton";

import ZoneFMapWithIcons from "../assets/maps/ZoneFMapWithIcons.jpg";

import HotSpot from "../components/HotSpot";

export default function ZoneF() {
  return (
    <div style={{ position: "relative" }}>
      <img src={ZoneFMapWithIcons} width="100%" />
      <HotSpot left={5} top={83} path="/entry/f1" backgroundColor="green" />
      <HotSpot left={14} top={75} path="/entry/f2" backgroundColor="green" />
      <HotSpot left={31} top={81} path="/entry/f3" backgroundColor="green" />
      <HotSpot left={52} top={76.8} path="/entry/f4" backgroundColor="green" />
      <HotSpot left={63} top={78} path="/entry/f5" backgroundColor="green" />
      <HotSpot left={62.5} top={70} path="/entry/f6" backgroundColor="green" />
      <HotSpot left={24} top={68.2} path="/entry/f6" backgroundColor="green" />
      <HotSpot left={27} top={57} path="/entry/f7" backgroundColor="green" />
      <HotSpot left={54} top={62} path="/entry/f8" backgroundColor="green" />
      <HotSpot left={62} top={46} path="/entry/f9" backgroundColor="green" />
      <HotSpot left={72.5} top={41} path="/entry/f9" backgroundColor="green" />
      <HotSpot left={64} top={24} path="/entry/f10" backgroundColor="green" />
      <HotSpot left={71} top={14.7} path="/entry/f11" backgroundColor="green" />
      <HotSpot left={85} top={11.5} path="/entry/f12" backgroundColor="green" />
      <FloatingActionButton path="/map" />
    </div>
  );
}
