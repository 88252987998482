import { Link } from "react-router-dom";

import "./hotSpotStyles.css";

interface HotSpotProps {
  left: number;
  top: number;
  path: string;
  size?: number;
  backgroundColor: string;
}

export default function HotSpot(props: HotSpotProps) {
  const { left, top, path, backgroundColor } = props;

  return (
    <Link to={path}>
      <div
        id="outerContainer"
        style={{ position: "absolute", left: `${left}%`, top: `${top}%` }}
      >
        <div id="container">
          <div className="circleSolid" style={{ animationDelay: "-0s" }}></div>
          <div className="circle" style={{ animationDelay: "-1s" }}></div>
          <div className="circle" style={{ animationDelay: "-2s" }}></div>
          <div className="circle" style={{ animationDelay: "-3s" }}></div>
        </div>
      </div>
    </Link>
  );
}
