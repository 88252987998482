export interface Observation {
  text: string;
  ctext?: string;
  image?: string;
}
export type Observe = Observation[];

export interface StatueContent {
  images: string[];
  title: string;
  sections: {
    bold?: string;
    cbold?: string;
    text?: string;
    ctext?: string;
    observation?: Observe;
    image?: string;
  }[];
  observation?: Observe;
  zone: string;
  commonInfoText?: string[];
}

export type Entry = StatueContent[];

export interface EntryMap {
  [key: string]: Entry;
}

export const entryMap: EntryMap = {};
