import Archway from "../assets/photos/zone_d/compressed/d1_main1a.jpg";

import HeavenMaiden from "../assets/photos/zone_d/compressed/d2_main1a.jpg";

import MedPeddlers from "../assets/photos/zone_d/compressed/d3_main1a.jpg";

import WuSong from "../assets/photos/zone_d/compressed/d4_main1a.jpg";

import BuDai from "../assets/photos/zone_d/compressed/d5_main1a.jpg";

import MonkeyGod from "../assets/photos/zone_d/compressed/d6_main1a.jpg";
import MonkeyGodAdd from "../assets/photos/zone_d/compressed/d6_extra1a.jpg";

import Confucius from "../assets/photos/zone_d/compressed/d7_main1a.jpg";
import BuddhistArchway from "../assets/photos/zone_d/compressed/d7_main2a.jpg";

import Giraffe from "../assets/photos/zone_d/compressed/d8_main1a.jpg";
import GiraffeAdd from "../assets/photos/zone_d/compressed/d8_extra1a.jpg";

import AwBoonPar from "../assets/photos/zone_d/compressed/d9_main1a.jpg";

import AwBoonHaw from "../assets/photos/zone_d/compressed/d10_main1a.jpg";

import { EntryMap } from "../content/entryMap";

const ZoneDMapping: EntryMap = {
  // Zone D Entries
  d1: [
    {
      images: [Archway],
      title: "Archway",
      sections: [
        {
          text: `This archway, built in the late 1930s, is a traditional paifang 牌坊 or pailou  牌楼,  an ornamental archway built over a street to mark the border of a new precinct or neighborhood. This particular archway frames the path leading to the Aw brothers’ memorials and one of the most celebrated tableaux in the Gardens – the Journey to the West.`,
        },
        {
          text: `The two characters on the central transom pane evoke the restful clouds of sunset. The outer couplet speaks of how “the winding mountain mist is best seen at evening, and the misty rain in the bays adds to the sound of the waves”; while the inner couplet deepens the experience further by telling us that “the green sea flows east - a thousand years of rise and fall can be seen at a glance; the white clouds look to the north- from ten thousand directions, sorrow and joy arrive in your heart.”`,
        },
      ],
      zone: "d",
    },
  ],
  d2: [
    {
      images: [HeavenMaiden],
      title: "Heavenly Maiden Scattering flowers 天女散花",
      sections: [
        {
          text: `Built in the early 1950s, the sheer size of this figure highlights the importance of its message. The four characters on the vase give the title of her tale. Not just any celestial maiden, she is a deeply-learned devakanya (celestial maiden) who appears in chapter 7 of the Vimalakirti Nirdesa Sutra.`,
        },
        {
          text: `One day, some Bodhisattvas were discussing the Dharma with chief disciples of the Buddha in the elder Vimalakirti’s rooms. A celestial maiden was moved to scatter flowers over the assembly as a gesture of honor. The flowers fell off the Bodhisattvas at once, but stuck to the bodies of the disciples. One particularly chauvinistic disciple Sariputra questioned the goddess about this inconvenience. She told him that the flowers did not cling to a Bodhisattva because he no longer differentiated, and such physical things did not affect him. By letting the flowers bother them, the disciples proved that they still clung to earthly matters, the same way these earthly flowers clung to them.`,
        },
        {
          text: `The goddess went on to enlighten Sariputra about the lack of difference between male and female, and put an end to his sexist views.`,
        },
        {
          text: `Aw Boon Haw celebrated this wise and enlightened goddess with this striking statue, complete with an elegant lotus leaf canopy.`,
        },
      ],
      zone: "d",
    },
  ],
  d3: [
    {
      images: [MedPeddlers],
      title: "Medicine Peddlers",
      sections: [
        {
          text: `This vividly detailed tableau from the late 1940s depicts a family of travelling medicine peddlers.`,
        },
        {
          text: `During the Qing dynasty, herbalists would often market their products with martial arts performances. Here, an entire family helps to sell their homemade medicine.  In the foreground, a man is taking a pipe-break beside a banners that reads “Out-of-towners selling martial arts” while the women do all the work of putting on the show.`,
        },
        {
          text: `Two women dressed as warriors are fighting with their own grandmother, who fights almost calmly despite her advanced age. A third woman attends to the potential customer who is eagerly asking about their wares - possibly seeking something to sedate her restless child. In the background, two little girls provide kung-fu percussion with a gong and a drum. The sign between them is their business warranty, telling us that :`,
        },
        {
          text: `⦁	Guaranteed to heal 100 diseases`,
        },
        {
          text: `⦁	Sichuan province, Nankang prefecture, Guang Ji Hall`,
        },
        {
          text: `⦁	Good remedy handed down from ancestors`,
        },
        {
          text: ``,
          ctext: `No one sees us climb mountains to collect medicinal herbs,
          Only heaven knows how we earnestly help the world;
          We’ve skillfully rejuvenated thousands, tens of thousands,
          If you beg but still are not healed, it is Heaven’s will; 
          We have spent three generations on the road,
          Everywhere we go, the masses spread our name
          If I prescribe and it doesn’t work on you
          I’ll give you a double refund – I will not eat my words!
          `,
        },
      ],
      observation: [
        {
          text: `Was this tableau a cheeky throwback to Aw’s own family trade? His grandfather had been a herbalist, and his father started the Eng Aun Tong business that eventually gave the world Tiger Balm. Perhaps he felt an affinity with these performing peddlers, willing to do whatever it took to promote their medicines. After all, Aw himself was notorious for his provocative marketing gimmicks – from giant billboards painted on trucks to the giant tiger’s head mounted on his striped car, and even a live brass band dressed in tiger costumes!`,
        },
        {
          text: `Was this also a wry commennt about how women do all the work while the man smokes idly by the side?`,
        },
      ],
      zone: "d",
    },
  ],
  d4: [
    {
      images: [WuSong],
      title: "Wu Song Fights the Tiger",
      sections: [
        {
          text: `Wu Song is one of the most popular heroes of the 水浒传 ‘Water Margin’, one of China’s Four Great Classical Novels. His rise to fame began with his heroic slaughter of a ferocious tiger at Jingyang Ridge.  In the original story, Wu Song ignores many warnings about a ferocious tiger that has been preying on villagers, and takes a nap out in the open to sleep off a drinking binge. When the beast leaps out at him, he beats the tiger to death with his bare fists. This version, built in the late 1940s, gives him a blade and even adds a modern touch with the addition of a rifle in the helpless hunter’s hands. Wu Song’s story continues one level up at E5.`,
        },
        {
          text: `Quite surprisingly, this monstrous tiger is painted green – perhaps to distinguish it from the valiant tiger of Tiger Balm!`,
        },
      ],
      zone: "d",
    },
  ],
  d5: [
    {
      images: [BuDai],
      title: "Bu Dai, the Laughing Buddha",
      sections: [
        {
          text: `The Laughing Buddha is traditionally known as Bu Dai 布袋, referring to the cloth sack he carries his meagre belongings in. Always poor but always content, he is a popular Chinese figure in businesses and restaurants because he represents contentment (and an obvious enjoyment of food).`,
        },
        {
          text: `His other popular pose – seated with one leg up – can be seen at A11.`,
        },
        {
          text: `Bu Dai is popularly regarded as an incarnation of Maitreya, the future Buddha. Maitreya is described as a Messianic bodhisattva who has yet to appear on earth. He will come someday, when the teachings of Gautama Buddha have been neglected. He will become the fifth Buddha of this current kalpa (Buddhist aeon) and restore the Dharma to future generations.`,
        },
      ],
      observation: [
        {
          text: `The Chinese custom of rubbing the protruding belly of the Laughing Buddha is believed to bring you happiness and abundance. This particular statue has been rubbed by generations of Chinese visitors since it was installed in the 1940s. 
          The incense box in front of the statue also attests to the custom of offering joss sticks to Bu Dai. On the box is an inscription stating “If you plead, there will be a response.”`,
        },
      ],
      zone: "d",
    },
  ],
  d6: [
    {
      images: [MonkeyGod],
      title: "The Monkey God of Hua Guo Shan",
      sections: [
        {
          text: `This is the largest tableau in the Gardens and was crafted in the late 1940s. It depicts Flower-Fruit Mountain (Hua Guo Shan 花果山). This earthly paradise was the home of a multitude of monkeys. To the Chinese, the monkey is a symbol of agility, shrewdness and intelligence.`,
        },
        {
          text: `One particularly agile, shrewd and intelligent monkey shows off his prowess by dashing through a waterfall and making the hitherto unexplored Water Curtain Cave his home. Inspired, the monkeys make him  their king.`,
        },
        {
          text: `Still ambitious, he leaves the mountain to train further with a Taoist immortal, eventually acquiring from his master the skill of the 72 Transformations, a new surname Sun, and the Buddhist name Wu Kong - “aware of vacuity’.  Later, he also acquires his weapon of choice - an incredible shrinking magic staff that was once a pillar in the palace of the Dragon King of the East Sea. (You can see him in battle with the Eight Immortals at C2).`,
        },
        {
          text: `Returning to the Mountain, he finds that the Demon King of Havoc has been stealing the riches and the children of his monkey subjects.  He invades the Demon’s dwelling and defeats him, restoring peace to his kingdom. Here, you see a lavish banquet being held in his honor. Flanked by two female monkeys, Wu Kong is receiving adulation and offerings from soldiers, officials, the media and the general public! The fan he is holding displays an idiom that basically says “I am the only one.”`,
        },
        {
          text: `(The same fan is being held by the monkey at the topmost right of the Mountain – it might be another Sun Wu Kong.  In some of Haw Par’s tableaus, characters are repeated to capture different events or incidents. You’ll see the same multiverse effect used in the Journey to the West tableau!)`,
          image: MonkeyGodAdd,
        },
        {
          text: `Mischievous and chronically averse to authority, later adventures would see Sun Wu Kong stealing Peaches of Immortality, disrupting a Celestial feast and offending Buddha himself.  Since he cannot  be sentenced to death, Buddha would sentence him to imprisonment under Five Element Mountain. Sealing him there with 6 golden words, Buddha would predict that a great Buddhist monk would free him some day.`,
        },
        {
          text: `Five hundred years later, Tripitaka would do just that - and the Journey to the West will begin!`,
        },
      ],
      observation: [
        {
          text: `Wander up the steps on either side of this sprawling tableau to get close to the action and see it from different perspectives – there are monkeys everywhere and charming details aplenty! 
          As in any good Chinese garden, the paths are designed to weave unpredictably through the landscape, offering the visitor constantly-changing vistas and surprising discoveries.`,
        },
      ],
      zone: "d",
    },
  ],
  d7: [
    {
      images: [Confucius],
      title: "Confucius",
      sections: [
        {
          text: `This statue was erected in the 1960s. The great sage Confucius (551-479 BC) was born Kong Qiu 孔丘 but is most commonly referred to as Kong Zi 孔子 (Master Kong). The romanized name Confucius was given to him by 16th-century Jesuit missionaries to China.`,
        },
        {
          text: `The son of a military officer, Confucius was born into the shi 士 class - between the aristocracy and the commoners. However, his father died when he was just three years old and he was raised in poverty by his widowed mother. Growing up, Confucius worked as a clerk and gradually became an expert in ancient rituals. When his mother died, the 23-year-old Confucius mourned for three years, in strict accordance to tradition.`,
        },
        {
          text: `In his lifetime, Confucius saw how rivalry between the various Chinese states had led to a   deterioration of traditional Chinese principles and a decline in moral values. Confucius felt committed to reinforcing the values of compassion and tradition. His social philosophy was based primarily on the principle of “loving others” (仁 ren – the word written on his podium) while exercising self-discipline. This thinking extended to his political beliefs. A good leader, according to Confucius, must exercise self-discipline in order to remain humble and compassionate.`,
        },
        {
          text: `Out of his teachings emerged the Five Constant Virtues (五常) - Benevolence (rén 仁), righteousness (yì 义), propriety (lǐ 礼), wisdom (zhì 智) and fidelity (xìn 信).  In his eyes, “the measure of man is man.”`,
        },
        {
          text: `He was eventually appointed as the Secretary of Justice, one of the top 6 highest ranking official positions. He retired at the age of 67 and devoted his time to editing the classics.`,
        },
        {
          text: `Till his death, he never thought his teachings were greatly significant; yet by the Han Dynasty in the second century BCE, Confucianism had become the foundation of state ideology. To this day, his influence is unparalleled in Chinese history.`,
        },
      ],
      zone: "d",
    },
    {
      images: [BuddhistArchway],
      title: "Buddhist Archway",
      sections: [
        {
          text: `This late 1940s archway is described as a Buddhist archway. Stylized cloud-like brackets support the cantilevers, and on top of them stand the iconic red balls of Haw Par Villa. The central transom  boasts swastika designs flanking a circular disc.`,
        },
        {
          text: `The circular disc might represent the mandala – the universe, or might suggest the prabha (halo) of a Buddha.  In Buddhism, the swastika signifies auspiciousness and good fortune.  The swastika is said to contain the whole mind of the Buddha and is often found imprinted on the chest, feet or palms of Buddha images. To some Buddhists, the swastika’s four arms represent the four places a soul can be reborn – heaven, hell, humanity and the world of flora and fauna.`,
        },
      ],
      zone: "d",
    },
  ],
  d8: [
    {
      images: [Giraffe],
      title: "Giraffe",
      sections: [
        {
          text: `One of the few collections of animal sculptures that have never been relocated, this motley group does not seem to have a story of their own, but might exist only to give meaning to the haughty eagle perched high on the upper right.`,
        },
        {
          text: `A giraffe and an ox appear to be having a heated discussion, with a giant black ant listening in. Also eavesdropping are two pheasants and a rabbit hiding in a hole. Beyond them, an eagle perches over a sign that reads 英雄独立.  This is a perfect example of the witty wordplay that the Chinese language offers.  雄鹰 (‘eagle’) in reverse is 英雄 (‘hero’), and 独立 can mean ‘independent’, or more literally, ‘standing on one leg’. So this eagle perched on one leg is also a hero that depends on no one but himself. Perhaps this is why he has turned his back to the other squabbling animals and keeps his eye on the horizon.`,
          image: GiraffeAdd,
        },
      ],
      zone: "d",
    },
  ],
  d9: [
    {
      images: [AwBoonPar],
      title: "Aw Boon Par memorial",
      sections: [
        {
          text: `On 20 July 1945, Aw Boon Haw erected this memorial for his beloved younger brother Aw Boon Par (1884-1944).`,
        },
        {
          text: `During their phenomenal rise to commercial success, Boon Par had handled the pharmaceutical aspects of the Tiger Balm brand while big brother handled the business and PR side of things. It was a harmonious partnership that only strengthened the ties between the two already-close siblings. This villa was Boon Haw’s gift to Boon Par and his family.`,
        },
        {
          text: `When WWII began, Boon Par and his family fled to Burma, having only lived in this villa for three and a half years.  Sadly, Boon Par died in Burma in 1944 and never saw this place again. Devastated, Boon Haw decided this place would no longer be a residence, only a public garden. He built his brother this memorial and dedicated it in 1945. That same day, he re-dedicated the one behind this as his own memorial.`,
        },
        {
          text: `Amidst the fantastical imagery and diverse themes of the Gardens, these stark and simple memorials are a vivid reminder that the heart of this place (and of all Chinese thinking) is Family.`,
        },
      ],
      zone: "d",
    },
  ],
  d10: [
    {
      images: [AwBoonHaw],
      title: " Aw Boon Haw memorial",
      sections: [
        {
          text: `This monument, the second highest one in Haw Par Villa, was originally built by Aw Boon Haw and dedicated to his parents in 1941. Following WWII, he built his parents a much taller and grander monument on higher ground and in a more central location (E3).`,
        },
        {
          text: `In 1945 he built a memorial nearby for his beloved brother Boon Par and on the day it was dedicated, he had this one re-dedicated to himself. In this way, filial order was observed in the monuments’ varying sizes and locations. Six years later, he built a smaller one on lower ground for his son Aw Hoe (A8), bringing the total number of monuments to four.`,
        },
        {
          text: `Aw Boon Haw died in 1954, leaving behind four wives and nine children (adopted or otherwise). Although he would no longer be seen in the mornings walking around the Gardens inspecting the sculptures’ progress or describing new ideas to the craftsmen, Aw Boon Haw had left behind a  legacy that his nephew Aw Cheng Chye would keep adding to, and that continues to inspire and instruct Singaporeans to this day.`,
        },
        {
          text: `This memorial was freshly dedicated by the Aw family in 1955.`,
        },
      ],
      zone: "d",
    },
  ],
};

export default ZoneDMapping;
