import FloatingActionButton from "../components/FloatingActionButton";

import ZoneBWithIcons from "../assets/maps/ZoneBWithIcons.jpg";

import HotSpot from "../components/HotSpot";

export default function ZoneB() {
  return (
    <div style={{ position: "relative" }}>
      <img src={ZoneBWithIcons} width="100%" />
      <HotSpot left={27} top={13.5} path="/entry/b1" backgroundColor="green" />
      <HotSpot left={19} top={25.5} path="/entry/b2" backgroundColor="green" />
      <HotSpot left={26} top={46} path="/entry/b3" backgroundColor="green" />
      <HotSpot left={31.5} top={64} path="/entry/b4" backgroundColor="green" />
      <HotSpot left={40} top={68} path="/entry/b5" backgroundColor="green" />
      <HotSpot left={48} top={75} path="/entry/b6" backgroundColor="green" />
      <HotSpot left={60} top={80} path="/entry/b7" backgroundColor="green" />
      <HotSpot left={77.5} top={77} path="/entry/b8" backgroundColor="green" />
      <HotSpot left={50} top={24.6} path="/entry/b9" backgroundColor="green" />
      <FloatingActionButton path="/map" />
    </div>
  );
}
