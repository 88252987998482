import React from "react";
import FloatingActionButton from "../components/FloatingActionButton";

import HotSpot from "../components/HotSpot";

export default function AreaMapBlue() {
  return (
    <div>
      <HotSpot
        left={40}
        top={20}
        path="/entry/2"
        size={30}
        backgroundColor="blue"
      />
      <HotSpot
        left={80}
        top={68}
        path="/entry/2"
        size={30}
        backgroundColor="blue"
      />
      <HotSpot
        left={20}
        top={50}
        path="/entry/2"
        size={30}
        backgroundColor="blue"
      />
      <FloatingActionButton path="/map" />
    </div>
  );
}
