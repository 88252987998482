import Archway from "../assets/photos/zone_f/compressed/f1_main1a.jpg";

import Temptation from "../assets/photos/zone_f/compressed/f2_main1a.jpg";
import Marriage from "../assets/photos/zone_f/compressed/f2_main3a.jpg";
import DeerFam from "../assets/photos/zone_f/compressed/f2_main4a.jpg";
import FoxesRats from "../assets/photos/zone_f/compressed/f2_main5a.jpg";

import PagodaF3 from "../assets/photos/zone_f/compressed/f3_main1a.jpg";

import LionWolf from "../assets/photos/zone_f/compressed/f4_main1a.jpg";

import Animals from "../assets/photos/zone_f/compressed/f5_main1a.jpg";

import PavilionF6 from "../assets/photos/zone_f/compressed/f6_main1a.jpg";
import PagodaF6 from "../assets/photos/zone_f/compressed/f6_main2a.jpg";

import FuLuShou from "../assets/photos/zone_f/compressed/f7_main1a.jpg";
import KangXi from "../assets/photos/zone_f/compressed/f7_main2a.jpg";
import SuQin from "../assets/photos/zone_f/compressed/f7_main3a.jpg";

import Pond from "../assets/photos/zone_f/compressed/f8_main1a.jpg";
import GrandDuke from "../assets/photos/zone_f/compressed/f8_main2a.jpg";
import Fountain from "../assets/photos/zone_f/compressed/f8_main3a.jpg";
import Mahagiri from "../assets/photos/zone_f/compressed/f8_main4a.jpg";

import PavilionF9 from "../assets/photos/zone_f/compressed/f9_main1a.jpg";
import Mermaids from "../assets/photos/zone_f/compressed/f9_main2a.jpg";
import CrabLady from "../assets/photos/zone_f/compressed/f9_main2b.jpg";
import Seashell from "../assets/photos/zone_f/compressed/f9_main3a.jpg";
import Lobsters from "../assets/photos/zone_f/compressed/f9_main3b.jpg";
import MermaidsAdd from "../assets/photos/zone_f/compressed/f9_extra2a.jpg";
import SeashellAdd from "../assets/photos/zone_f/compressed/f9_extra3a.jpg";

import PagodaF10 from "../assets/photos/zone_f/compressed/f10_main1a.jpg";
import PavilionF10 from "../assets/photos/zone_f/compressed/f10_main2a.jpg";

import ThreeKingdoms from "../assets/photos/zone_f/compressed/f11_main1a.jpg";

import Indonesian from "../assets/photos/zone_f/compressed/f12_main1a.jpg";

import { EntryMap } from "../content/entryMap";

const ZoneFMapping: EntryMap = {
  // Zone F Entries
  f1: [
    {
      images: [Archway],
      title: "Archway",
      sections: [
        {
          bold: `Like all the large Haw Par archways, this was probably here from the opening of the Villa and Gardens in 1937. Crafted in the traditional paifang 牌坊 or pailou  牌楼 style, it marks the path leading to the grand Signature Pond and the southern entrance of the Villa.`,
        },
        {
          bold: `Look out for the multitude of auspicious animals that adorn this archway.  There are kirins flanking the central transom, lions underneath the words and dragons serving as brackets. Roaring over them all is the Aw family emblem – the tiger.`,
        },
        {
          bold: `Unlike the other archways, this one has no roofs, only a series of elaborate transoms. The text on the central transom reads 挹翠 , which evokes  the action of ‘scooping up’  the green color of emeralds.`,
        },
        {
          bold: `The couplet on the inner columns reads (right, then left) :`,
        },
        {
          cbold: `“Cooling air comes from the West; looking around, 
          you will see the misty scenery of the thirteen bays. 
          Look north to see the mainland; 
          tracing back ninety thousand miles to the homeland.”`,
        },
        {
          bold: `The outer couplet reads :`,
        },
        {
          cbold: `“Red cloud in the air, white crane in the clouds;
          Bright moon in the mountains, fresh breeze over the sea”`,
        },
        {
          text: `These couplets were written for Haw Par by celebrated Chinese writer and poet Yu Dafu郁达夫 (1896-1945). Yu was living in Singapore as the editor of the Sin Chew Jit Poh from 1938-1942. It is during that time that he was commissioned to write several poems to adorn the archways of Haw Par.`,
        },
        {
          text: `He had lived and studied in Japan as a young man, but at the start of the Second Sino-Japanese War, he returned to China and actively wrote anti-Japanese propaganda. So when the Japanese occupied Singapore, he wisely fled to Sumatra and lived under a different name. Unfortunately, he was eventually identified by the Japanese authorities and, it is rumored, executed by them after the Japanese surrender.`,
        },
        {
          text: `Another poem by him adorns the archway at C8.`,
        },
      ],
      observation: [
        {
          text: `Take note of where the beams ‘extend’ beyond the outermost pillars and end in a decorative dou gong design. Look at the archway from the side – you can see stylized versions of the characters for happiness 幅  and prosperity 禄 on the cross-sections, similar to the way they might be carved on a traditional Chinese name-seal.`,
        },
      ],
      zone: "f",
    },
  ],
  f2: [
    {
      images: [Temptation],
      title: "Temptation",
      sections: [
        {
          text: `This bizarre cave tableau was built in the early 1950s and depicts a respectably dressed rooster being enticed into the seductively-dressed hen’s den, while another hen is calling out either enticements or warnings. Some believe the second hen is the wife, pleading with the husband to stay faithful. This is a popular interpretation offered by mothers of visiting families, loudly enough for the father to hear.`,
        },
      ],
      zone: "f",
    },
    {
      images: [],
      title: "Elephant",
      sections: [
        {
          text: `This elephant statue was crafted in the late 1930s, but its relationship to these tableaux is unclear. It may have previously adorned the lawn around the Villa, which was known to have been decorated with animals for the children to play with. The elephant is a much respected animal in Burma, where the Aw brothers were born and where they lived till their twenties.`,
        },
      ],
      zone: "f",
    },
    {
      images: [Marriage],
      title: "Union in Marriage",
      sections: [
        {
          text: `Built to match the Temptation tableau in the neighboring cave, this early 1950s tableau depicts a doting mousewife offering mandarin oranges to her pig of a husband, while his attention seems to be on other things. While appearing to be a lecherous scene, the writing on the wall tells us otherwise. The banner over the doorway reads百年好合 , a common wedding wish for ‘a good union lasting a hundred years’. The wall hanging that somewhat overwhelms the living room states 海外良缘 – urging for ‘good unions overseas’. Given the inter-species pairing, perhaps this tableau is encouraging foreign marriages.  After all, younger brother Boon Par’s second wife was Burmese.`,
        },
      ],
      zone: "f",
    },
    {
      images: [DeerFam],
      title: "Deer family",
      sections: [
        {
          text: `This placid arrangement depicts a family of deer. The proud stag father looks into the distance while a calm mother feeds the babies.  Nearby, the young son eats whatever he finds and the young daughter won’t stop scratching. Further uphill stand a flirtatious pair – possibly the teenage daughter with her boyfriend.`,
        },
      ],
      zone: "f",
    },
    {
      images: [FoxesRats],
      title: "Foxes and Rats",
      sections: [
        {
          text: `Perhaps intended to match the deer family on the corner, this family of foxes was crafted in the early 1950s as well. Father fox has caught dinner and the youngsters cannot wait.  Nearby is a family of rats . One of them appears to have taken a tumble.`,
        },
      ],
      zone: "f",
    },
    {
      images: [],
      title: "Phoenix - 凤凰 (Feng Huang)",
      sections: [
        {
          text: `Above the foxes stands a phoenix dating from the late 1940s. A popular figure in the Gardens, the phoenix is the counterpart to the dragon, representing the feminine Yin energy as well as the authority of the Empress. The phoenix has been appearing in Chinese art for 8000 years and, like the dragon, it is believed to be a composite creature - combining the beak of a rooster, the face of a swallow, the forehead of a fowl, the neck of a snake, the breast of a goose, the back of a tortoise, the hindquarters of a stag and the tail of a fish. The phoenix depicted here is more conventional-looking, but is certainly grand enough to be the ruler of all the birds.`,
        },
      ],
      zone: "f",
    },
  ],
  f3: [
    {
      images: [PagodaF3],
      title: "Seven-tier pagoda",
      sections: [
        {
          text: `Dating from the beginning of the Gardens, this six-sided, seven-tiered pagoda is topped with a magical gourd (hu lu葫芦) on a bed of leaves.  It is unusual for its alternating sets of doorways, creating a cheeky effect.`,
        },
        {
          text: `It sits inside a rockery garden that features a Moon Gate (yue men月门). Such circular gateways once adorned the gardens of upper class Chinese families but have since become a popular feature in Chinese gardens worldwide. The circle represents perfection and it frames a perfect view, while stepping through a moon gate should surprise the visitor with a whole new vista on the other side. Try it and see!`,
        },
      ],
      zone: "f",
    },
  ],
  f4: [
    {
      images: [LionWolf],
      title: "Lion & Wolf family",
      sections: [
        {
          text: `A solitary lion confronts a family of wolves at their den. While the lion seems ferocious, the wolves appear rather calm. While reminding us of the importance of family (like many of the animal families along this path), this tableau might also suggest that the valiant must sometimes act alone (like the eagle at D8).`,
        },
      ],
      zone: "f",
    },
  ],
  f5: [
    {
      images: [Animals],
      title: "Animals illustrating human foibles",
      sections: [
        {
          text: `Dating from the early 1950s, this grotto depicts a nuptial day – the rat groom is escorting his rabbit wife towards their bedchamber, while their fathers are being served tea by a servant and possibly spreading the word on the telephone.`,
        },
        {
          text: `The red scroll above the chest of drawers wishes them a “blissful and good marriage”, while the  painting behind the fathers depicts 铁拐李 Tie Guai Li with his magical gourd (one of the Eight Immortals and patron of herbalists). The accompanying couplet wishes that “on this heaven-granted day, the yellow sparrow will spit flowers;  with this celebrated, auspicious event, the osmanthus will open fragrantly next year.” This is almost definitely a wish that the couple will be fertile and conceive soon.`,
        },
      ],
      observation: [
        {
          text: `Look out for the remarkable details in this tableau! The electrical wiring and light switches on the wall, the scalloped drawer handles, the lock on the low cupboard door, and even the Chinese numerals on the phone! One can imagine the mischievous pride that went into the crafting of these tableaux, and the delight it brings to the observant visitor!`,
        },
      ],
      zone: "f",
    },
  ],
  f6: [
    {
      images: [PavilionF6],
      title: "Pavilion",
      sections: [
        {
          text: `This unusual pavilion was built in the early 1950s by craftsman Wu Ben Xing. Departing from the familiar details of Chinese architecture, it has a space age feel, evoking the futuristic ambitions of the 1950s and anticipating the many similarly futuristic pavilions around Singapore in the decades to come.`,
        },
      ],
      zone: "f",
    },
    {
      images: [PagodaF6],
      title: "Five-tiered pagoda",
      sections: [
        {
          text: `This pagoda dates from the beginning of the Gardens. It is five-tiered with an elaborate roof that might be a sixth tier or a square stupa. It has elaborate dou gong brackets, giving it the appearance of a timber pagoda even though it is made entirely of cement.`,
        },
      ],
      zone: "f",
    },
  ],
  f7: [
    {
      commonInfoText: [
        "These three large panels were erected in the late 1940s and tell three different stories.",
      ],
      images: [FuLuShou],
      title: "Fu Lu Shou",
      sections: [
        {
          text: `This panel shows Fu Lu Shou, the Gods of Happiness, Prosperity and Longevity, surrounded by children, celestial maidens and heavenly officials. (You will find life-size sculptures of them overlooking the pond at C15.) In the Chinese cosmology, many deities are identified with celestial bodies, and this trio are associated with Jupiter (Fu) and the stars Ursa Majorus (Lu) and Canopus (Shou). The couplet framing the panel says :`,
        },
        {
          ctext: `“Happiness, Prosperity and Happiness – three stars celebrate together;
          Heaven, Earth and people celebrate as one body.”`,
        },
      ],
      zone: "f",
    },
    {
      images: [KangXi],
      title: "The Legend of Kang Xi 康熙传奇",
      sections: [
        {
          text: `In the middle panel, the legend of Kang Xi warns visitors to not come between siblings because blood is thicker than water.`,
        },
        {
          text: `Emperor Kang Xi had a dream about a beggar who he believed to be his twin brother. Bent on a reunion, he ordered his officials to search for this man. Eventually, the beggar Lin Di Yong was located and brought back to the palace, where he was showered with riches. The emperor’s ministers grew jealous of Lin and plotted to kill him. When Kang Xi heard of this, he was outraged and ordered that Lin be weighed daily. Should Lin lose any weight, the ministers would be punished severely. Here, in Beijing’s Tai He Court, a struggling and overweight Lin is being placed with great difficulty on the weighing scale while Kang Xi oversees the process.`,
        },
        {
          text: `The couplet framing the panel wishes everyone world peace and a calm climate, with the country at peace and the citizens safe.`,
        },
      ],
      zone: "f",
    },
    {
      images: [SuQin],
      title: "The Legend of Su Qin  苏秦传奇",
      sections: [
        {
          bold: `Nearest the summit, this tableau is titled “Six States Appoint a Leader” and shows Su Qin 苏秦  (in the black hat) being honored by the 6 feudal princes.`,
        },
        {
          bold: `Su Qin was an influential political strategist and diplomat during the Warring States period (c. 476-220 BC), when China was divided into seven states. The state of Qin was the mightiest and was constantly at war with the other six states.`,
        },
        {
          bold: `Selling part of his family property to travel, Su Qin offered his services to various states but was rejected. He approached the King of Qin and offered his strategy for conquering the other six states – but the king sent him home. Despite facing anger from his family for wasting their resources, Su Qin continued working hard to develop his ideas. A year later, he brought his ideas to the state of Yan, who referred him to the state of Zhao, and soon, through a series of skilled negotiations, he persuaded the 6 less powerful states to join forces. The six feudal princes became sworn brothers and appointed Su Qin as Prime Minister.`,
        },
        {
          bold: `Su Qin’s legend demonstrates the value of not giving up hope, for success comes to the one who tries the hardest.`,
          text: `This panel is actually a double feature! Rather confusingly, the figures in the sky above Su Qin and the princes are telling a separate story - the tale of Tian Xian Song Zi 天仙送子.`,
        },
        {
          text: `Tian Xian 天仙 was the 7th daughter of the Jade Emperor – the supreme deity of the Taoists. She fell in love with Dong Yong 董永, a young man who sold himself into slavery in order to buy a plot of land to bury his father. Moved by his piety, she descended to earth as a mortal and they married. She bore him a son, but when the Jade Emperor found out about her transgression, he dragged her back to the Heavenly Paradise. When the baby was born, she was made to hand it over to Dong Yong. This tableau shows Dong Yong emerging from the house to await the arrival of his wife and newborn son.`,
        },
      ],
      zone: "f",
    },
  ],
  f8: [
    {
      images: [Pond],
      title: "Signature Pond",
      sections: [
        {
          bold: `One of the most popular spots in the Gardens since 1937, this Signature Pond is a masterpiece of family pride. Sitting just to the west of the Villa itself, the oddly-curved pond creates the shape of the family name Aw (胡 hu) written with a Chinese calligrapher’s brush. It also plays off how the family name in Mandarin sounds like 湖 hu,which means ‘lake’.`,
        },
      ],
      observation: [
        {
          text: `Inside the lake, we find a miniature city that, upon closer observation, would have been a mad architect’s playground.  What appears to be a cozy little town is actually full of oddities that trick the eye. There are pavilions forced into parallelogram perspectives, temples with no windows, oddly-European looking buildings and even a lotus pavilion with a lotus-leaf roof that has a gap, the way lotus leaves actually do! The most unusual feature is a half bridge that suddenly ends in a pavilion with a big hole to view the water from; while another bridge leads right under it at a right angle!`,
        },
      ],
      zone: "f",
    },
    {
      images: [GrandDuke],
      title: "Grand Duke Jiang Fishing 姜太公钓鱼",
      sections: [
        {
          text: `Built in the early 1950s by craftsman Tan Seng Hua, this giant statue depicts Jiang Zi Ya姜子牙 , one of the main characters in the epic novel封神榜  Feng Sheng Bang (“The Canonization of the Gods”), written more than 2,500 years after his death.`,
        },
        {
          text: `Here we seen Jiang as he is described in Chapter 24 of the novel, sitting by the water with his fishing rod three feet above the water, with neither bait nor hook. When asked why he fished that way, he replied that he was not aiming to catch fish, but to catch a good ruler whom he could serve righteously. The words written on his broad hat are a common idiom inspired by his story. Literally, it reads “Jiang Tai Gong fishing with a wooden stick – only the willing will be hooked”, meaning that if you are worthy like Jiang, the right people will willingly follow you.`,
        },
        {
          text: `Eventually, the king of Wen found him and enlisted his help to overthrow the forces of the tyrannical King of Zhou. You can see him at his most glorious in the epic battle tableau at G4. After defeating the Zhou, Jiang continued to serve the Wen for three generations. He maintained that a state could become powerful only when the people prospered: if the people were poor, the ruler will not last long. By heeding his advice, the new dynasty prospered for years. Jiang died at the grand old age of 139.`,
        },
        {
          text: `Today, his military strategy treatise is considered one of the Seven Military Classics of Ancient China, and he even appears in various video games, including Final Fantasy XI.`,
        },
      ],
      zone: "f",
    },
    {
      images: [Fountain],
      title: "European fountain",
      sections: [
        {
          text: `This unexpected feature appeared in the early 1950s. Much of the fountain is marble, from the rams’ heads upwards. It is quite likely that it was an ornamental garden fountain that was imported and mounted atop a concrete podium.`,
        },
      ],
      zone: "f",
    },
    {
      images: [Mahagiri],
      title: "Min Mahagiri",
      sections: [
        {
          text: `This white marble statue was bought in the early 1950s by Aw Boon Haw as a memento of his birth country Burma and its rich folk tradition. It was subsequently painted in rich detail by artisan Mr Koh.`,
        },
        {
          text: `Min Mahagiri is a Burmese spirit that protects homes. Many Burmese households hang a coconut from the southeast pillar to represent his presence.  He is the best known of the Thirty Seven Nats (spirits) that the Burmese revere.`,
        },
        {
          text: `He began life as Maung Tint De, a blacksmith’s son gifted with great physical strength. The King of Tagaung was afraid that Tint De would threaten his power, and Tint De was forced to hide in a village. The wily King married Tint De’s sister and used her to flush him out, claiming to want to give him an official position. Instead, the King had both brother and sister tied to a champak tree and killed.`,
        },
        {
          text: `The siblings became spirits, called Nats, who angrily feasted on people who came near the tree. The king had the tree cut down and flung into the river, which carried it to the neighboring kingdom of Bagan.  The Nats told the neighboring king of their plight and asked for a place to dwell, and in return they would protect the kingdom. The king had the tree cut in two, carved into human likenesses and placed on Mount Popa.  Tint De thus became Min Mahagiri - Lord of the Great Mountain.`,
        },
      ],
      zone: "f",
    },
  ],
  f9: [
    {
      images: [PavilionF9],
      title: "Pavilion",
      sections: [
        {
          text: `Present from the beginning of the Gardens, this pavilion might be the simplest one in the Gardens, design-wise. It shelters a marble Buddha in the Burmese style, commonly called a Mandalay Buddha and distinguished by its standing pose with outer robe (saṃghāti) spread open like a cape and held in both down-stretched hands.`,
        },
      ],
      zone: "f",
    },
    {
      images: [Mermaids, CrabLady],
      title: "Crab-women and Mermaids",
      sections: [
        {
          text: `These half-human creatures scattered throughout the Gardens embody the Chinese belief that through dedicated meditation, all creatures can gain human form. These half-human creatures are probably caught midway through their journey towards their human goal.`,
        },
        {
          text: `Few are aware that mermaids exist in Chinese mythology too. As far back as the 4th century BCE, there are references of 人鱼 – human-fish. The ancient god of the Yellow River was half man, half fish. In the 14th century, scholars wrote of 鲛人  - beings similar to Western mermaids, whose tears turned to pearls; and who wove the legendary Dragon Yarn - a frost-white fabric that was magically water-repellent.`,
        },
        {
          text: `These statues began life in the Mermaid pool that was once the Villa swimming pool. When the pool was filled up for Dragon World, these statues were moved up here to the Signature Pond.`,
          image: MermaidsAdd,
        },
      ],
      zone: "f",
    },
    {
      images: [Seashell, Lobsters],
      title: "Seashell-women and Lobsters",
      sections: [
        {
          text: `The cheery seashell ladies and the pair of majestic lobsters once frolicked among sculpted waves with walruses, turtles and other water-lovers inside the dry pond at C11. When the pond was filled up for Dragon World, the creatures were evicted and wound up here.`,
          image: SeashellAdd,
        },
      ],
      zone: "f",
    },
  ],
  f10: [
    {
      images: [PagodaF10],
      title: "Nine-tiered pagoda",
      sections: [
        {
          text: `This elaborate miniature pagoda is octagonal with nine tiers. Built together with the Villa, it is topped with a finial shaped like a hu lu l葫芦.  Each level is surrounded by a mini viewing gallery with a low balustrade with, oddly enough, doors that lead the careless viewer into a floorless interior!`,
        },
        {
          text: `To the left of this is a 4-faced Buddha shrine in the Thai style – a much later addition but an important complement to the variety of Buddhas in the Gardens.`,
        },
      ],
      observation: [
        {
          text: `Near the 9-tiered pagoda is a structure that used to be a rabbit hutch, with  portholes through which kids would be able to watch the bunnies.`,
        },
      ],
      zone: "f",
    },
    {
      images: [PavilionF10],
      title: "Pavilion",
      sections: [
        {
          text: `This unusual pavilion from the late 1930s is built in forced perspective, flattening it into a parallelogram to make it appear somewhat larger. The columns are plain, but topped with quasi-Doric capitals and elaborate brackets, leading to a very richly-detailed two-tiered roof with flying eaves. Note the unusual finial – a lotus bud mounted on a melon.`,
        },
        {
          text: `Inside the pavilion, a marble Buddha stands on a lotus. This is a popular Mandalay Buddha from Burma. Note the standing pose with the outer robe (saṃghāti) spread open like a cape and held in both down-stretched hands. Another one stands nearby at the pavilion at F9.`,
        },
      ],
      zone: "f",
    },
  ],
  f11: [
    {
      images: [ThreeKingdoms],
      title: "Romance of the Three Kingdoms  三国演义Peach Garden Ceremony",
      sections: [
        {
          text: `In the late 1940s, this tableau was built depicting a pivotal scene from the Romance of the Three Kingdoms 三国演义, one of China’s Four Great Literary Classics. Written in the 14th century by Luo Guanzhong, this part-mythical part-historical work covers the tumultuous 3rd century period of the three kingdoms of Cao Wei, Shu Han and Eastern Wu. This moment comes in the very first chapter of the 120 chapter novel.  At the end of Han dynasty, the unhappy people rose up against their corrupt rulers. Three brave men stepped up to raise an army to fight the rebels - Liu Bei, Guan Yu and Zhang Fei.`,
        },
        {
          text: `Meeting in a peach garden in Zhang Fei’s farm, they swore the following oath : “When saying the names Liu Bei, Guan Yu and Zhang Fei, although the surnames are different, yet we have come together as brothers. From this day forward, we shall join forces for a common purpose: to save the troubled and to aid the endangered. We shall avenge the nation above, and pacify the citizenry below. We seek not to be born on the same day, in the same month and in the same year. We merely hope to die on the same day, in the same month and in the same year. May the Gods of Heaven and Earth attest to what is in our hearts. If we should ever do anything to betray our friendship, may heaven and the people of the earth both strike us dead.”`,
        },
        {
          text: `Despite different social and cultural backgrounds, they became sworn brothers for a common cause. Brotherhood is a strong theme in the Aw family story and it is reflected many times in these Gardens.`,
        },
        {
          text: `This tableau, once sheltered under a grove of sculpted ‘trees’, shows us Zhang Fei on the left in the broad-brimmed hat, scholarly Liu Bei in the middle and Guan Yu with the red face given to him by Beijing Opera renditions of the story.  Before them stand the peaches and wine with which they toasted their oath.`,
        },
      ],
      zone: "f",
    },
  ],
  f12: [
    {
      images: [Indonesian],
      title: "Indonesian bas relief",
      sections: [
        {
          text: `Once paired with the Garuda (C8) in the Indonesian corner, this elaborate bas relief evokes the unique look of batik printing - but in 3D! It depicts the diversity of Indonesian folk dances, from the warlike Baris dance (3rd from left) to the famous dance featuring the mythical protector spirit Barong (extreme right).`,
        },
      ],
      zone: "f",
    },
  ],
};

export default ZoneFMapping;
